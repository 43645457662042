<div class="inputMasks d-flex flex-row justify-content-start gap-1 align-items-stretch">
    <div class="col d-flex flex-column justify-content-between align-items-stretch">
        <h3>Abfahrt <br><mat-checkbox matTooltip="As soon as possible (So schnell wie möglich)"
                          *ngIf="asapAllowed"
                          [disabled]="asapDisabled"
                          [(ngModel)]="asap"
                          (change)="asapClicked()">Schnellstmöglich
            </mat-checkbox>
        </h3>
        <mat-form-field appearance="fill">
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="dateStart" (dateChange)="startDateChange()" [min]="min"
                   [disabled]="asap">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" [matTooltip]="startTooltip">
            <mat-label>Uhrzeit</mat-label>
                <input matInput placeholder="Uhrzeit"
                       [ngxTimepicker]="pickerStart"
                       [format]="24"
                       [value]="timeStart"
                       [disabled]="asap"
                       (input)="startTimeInputChange($event)"
                >
                <ngx-material-timepicker #pickerStart [preventOverlayClick]="true" (timeChanged)="startTimeChange($event)"></ngx-material-timepicker>
                <mat-icon style="cursor: pointer" aria-hidden="false" aria-label="Example home icon" fontIcon="language" matSuffix (click)="modifyTimeZone()" [matTooltip]="timeZone"></mat-icon>
        </mat-form-field>
    </div>
    <div class="col d-flex flex-column justify-content-between align-items-stretch" *ngIf="!useDuration">
        <h3>
            Rückgabe<br>
            <mat-checkbox [ngModel]="useDuration" (change)="toggleUseDuration()">Dauer</mat-checkbox>
        </h3>
        <mat-checkbox *ngIf="!otherDayReturn" [(ngModel)]="otherDayReturn">Abweichender Tag</mat-checkbox>
        <mat-form-field *ngIf="otherDayReturn" appearance="fill">
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="dp2" [formControl]="dateEnd" [min]="dateStart.value" (dateChange)="endDateChange()">
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" [matTooltip]="endTooltip">
            <mat-label>Uhrzeit</mat-label>
            <input matInput placeholder="Uhrzeit"
                   [ngxTimepicker]="pickerEnd"
                   [format]="24"
                   [value]="timeEnd"
                   (input)="endTimeInputChange($event)"
            >
            <ngx-material-timepicker #pickerEnd [preventOverlayClick]="true" (timeChanged)="endTimeChange($event)"></ngx-material-timepicker>
        </mat-form-field>
    </div>
    <div class="col d-flex flex-column justify-content-between align-items-stretch" *ngIf="useDuration">
        <div>
            <h3>
                Dauer<br>
                <mat-checkbox [ngModel]="useDuration" (change)="toggleUseDuration()">Dauer</mat-checkbox>
            </h3>
        </div>
        <div class="d-flex flex-column justify-content-end gap-1">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Dauer (Stunden)</mat-label>
                    <input matInput
                           [ngModel]="getDurationHumanReadable()"
                           (ngModelChange)="onDurationChange($event)"
                           [matAutocomplete]="durationAutoComplete"
                           type='number' [min]="minDuration" [max]="maxDuration"
                    >
                    <mat-autocomplete #durationAutoComplete="matAutocomplete">
                        <mat-option value="0.5">30 Minuten</mat-option>
                        <mat-option value="1">1 Stunde</mat-option>
                        <mat-option value="1.5">1,5 Stunden</mat-option>
                        <mat-option value="2">2 Stunden</mat-option>
                        <mat-option value="3">3 Stunden</mat-option>
                        <mat-option value="4">4 Stunden</mat-option>
                        <mat-option value="5">5 Stunden</mat-option>
                        <mat-option value="6">6 Stunden</mat-option>
                        <mat-option value="7">7 Stunden</mat-option>
                        <mat-option value="8">8 Stunden</mat-option>
                        <mat-option value="24">1 Tag</mat-option>
                        <mat-option value="48">2 Tage</mat-option>
                        <mat-option value="72">3 Tage</mat-option>
                        <mat-option value="96">4 Tage</mat-option>
                        <mat-option value="120">5 Tage</mat-option>
                        <mat-option value="144">6 Tage</mat-option>
                        <mat-option value="168">1 Woche</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
