import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ExecuteUserAddOrUpdate, PermissionGroup} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {UserService} from '../../service/user.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {PermissionGroupService} from '../../../permissions/service/permission-group.service';
import {
    AbstractControl, FormArray, FormBuilder, FormControl, FormGroup,
    UntypedFormArray,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';

export interface UserUploadStatus {
    user: ExecuteUserAddOrUpdate,
    errors: any,
    success: boolean
}


type RowFormGroup = FormGroup<{
    name: FormControl<string | null>
    password: FormControl<string | null>
    firstname: FormControl<string | null>
    lastname: FormControl<string | null>
    email: FormControl<string | null>
    emailAlertLevel: FormControl<number | null>
    sendReservation: FormControl<boolean | null>
    sendWelcome: FormControl<boolean | null>
    permissionGroups: FormControl<number[] | null>
}>;

@Component({
    selector: 'app-new-user-table',
    templateUrl: './new-user-table.component.html',
    styleUrls: ['./new-user-table.component.scss']
})
export class NewUserTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() users!: ExecuteUserAddOrUpdate[];

    userStatuses!: UserUploadStatus[];
    displayedColumns: string[] = ['username', 'password', 'firstname', 'lastname', 'email' , 'notification-level', 'reservation', 'roles', 'info'];
    formGroup!: FormGroup<{
        rows: FormArray<RowFormGroup>
    }>;

    public permissionGroups!: Promise<PermissionGroup[] | undefined>;
    dataSource = new MatTableDataSource<any>([]);
    selection = new SelectionModel<UserUploadStatus>(true, []);

    constructor(
        private readonly userService: UserService,
        private readonly permissionGroupSerive: PermissionGroupService,
        private readonly fb: FormBuilder
    ) {
        super();
    }


    passwordWelcomeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const password = control.get('password');
        const sendWelcome = control.get('sendWelcome');

        const pwSet = password !== null && password.value !== undefined && password.value.length !== 0;
        const swSet = sendWelcome !== null && sendWelcome.value !== undefined && sendWelcome.value;

        if (!pwSet && !swSet) return {NeitherPwNorSendWelcomeSet: true};

        return null;
    };

    welcomeRequiresEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const email = control.get('email');
        const sendWelcome = control.get('sendWelcome');

        const emailSet = email !== null && email.value !== undefined && email.value.length !== 0;
        const swSet = sendWelcome !== null && sendWelcome.value !== undefined && sendWelcome.value;

        if (swSet && !emailSet) return {SendWelcomeWithoutEmail: true};

        return null;
    };
    ngOnInit(): void {
        this.userStatuses = this.users.map(u=> ({user: u, errors: null, success: false}));
        this.permissionGroups = this.permissionGroupSerive.getAllPromise().then(list => list.list);
        this.formGroup = this.fb.group({
            rows: this.fb.array(this.userStatuses.map(s=> this.fb.group({
                    name: new FormControl(s.user.name),
                    password: new FormControl(s.user.password ?? null),
                    firstname: new FormControl(s.user.firstname),
                    lastname: new FormControl(s.user.lastname),
                    email: new FormControl(s.user.email ?? null),
                    emailAlertLevel: new FormControl(s.user.emailAlertLevel ?? null),
                    sendReservation: new FormControl(s.user.sendReservation),
                    sendWelcome: new FormControl(s.user.sendWelcome ?? null),
                    permissionGroups: new FormControl(s.user.permissionGroups),
                }, {validators: [this.passwordWelcomeValidator, this.welcomeRequiresEmailValidator]})
            ))
        }); // end of form group cretation
        this.dataSource = new MatTableDataSource((this.formGroup.get('rows') as FormArray).controls);
    }

    hasErrors(index: number): boolean {
        return this.userStatuses[index].errors!==null;
    }



    getError(index: number) {
       if (!this.hasErrors(index)) return "";
       const errors = this.userStatuses[index].errors;
       if (typeof errors === "string" || errors instanceof String) return errors;
       return errors.msg;
    }


    hasValidationError(index: number, type?: string): boolean {

        const rows = this.formGroup.get("rows") as FormArray<RowFormGroup>;
        const form = rows.at(index) as RowFormGroup;
        if (type === undefined) {
             if (Object.values(form.controls).some(cForm=>cForm.errors!=null)) {
                return true;
            }
        }


        switch (type) {
            case "": return false;
            case "name":
                const name = (form.get("name") as FormControl<string | null>).value;
                return !name || name !== name.trim();
            case "firstname": return form.get("firstname")?.errors !== null;
            case "lastname": return form.get("lastname")?.errors !== null;
            case "email": return form.errors?.SendWelcomeWithoutEmail;
            case "password": return form.errors?.NeitherPwNorSendWelcomeSet;
            default: return false;
        }
    }


    getValidationErrors(index: number) {
        const ret: string[] = [];
        if(this.hasValidationError(index, "email")){
            ret.push("Zum senden der Wilkommens E-Mail, muss eine E-Mail gespeichert werden")
        }
        if (this.hasValidationError(index, "password")) {
            ret.push("Es muss entweder ein Passwort gesetzt oder eine Wilkommens E-Mail verschickt werden");
        }
        if (this.hasValidationError(index, "name")) {
            ret.push("Ungültiger Benutzername")
        }
        if (this.hasValidationError(index, "firstname")) {
            ret.push("Vorname nicht ausgefüllt")
        }
        if (this.hasValidationError(index, "lastname")) {
            ret.push("Nachname nicht ausgefüllt")
        }
        return ret.join(" - ");
    }

    success(index: number): boolean {
        return this.userStatuses[index].success;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.users;
    }

    async uploadNewUsers() {
        const rows: UntypedFormArray = this.formGroup.get("rows") as UntypedFormArray;
        for (let i = 0; i < rows.length; i++) {
            if (this.success(i)) continue;
            if (this.hasValidationError(i)) {
                this.userStatuses[i].errors = this.getValidationErrors(i);
                continue;
            }
            const userRow = rows.at(i);
            const user = this.userStatuses[i].user;

            user.name = (userRow.get("name") as FormControl<string | null>).value!;
            user.password = (userRow.get("password") as FormControl<string | null>).value!;
            user.firstname = (userRow.get("firstname") as FormControl<string | null>).value!;
            user.lastname = (userRow.get("lastname") as FormControl<string | null>).value!;
            user.email = (userRow.get("email") as FormControl<string | null>).value!;
            user.sendWelcome = (userRow.get("sendWelcome") as FormControl<boolean | null>).value!;
            user.emailAlertLevel = (userRow.get("emailAlertLevel") as FormControl<number | null>).value!;
            user.sendReservation = (userRow.get("sendReservation") as FormControl<boolean | null>).value!;
            user.permissionGroups = (userRow.get("permissionGroups") as FormControl<number[] | null>).value!;

            let error = null;
            await this.userService.create(user).catch(reason => {
                error = reason.error;
            });
            this.userStatuses[i].success = error === null;
            this.userStatuses[i].errors = error;
        }
    }
}

