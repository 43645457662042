import { Injectable } from '@angular/core';
import {ExecuteOpenAdrVenEntityAddOrUpdate, OpenAdrVenEntity, OpenAdrVenEntityList} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {EditOpenAdrVenDialogComponent} from '../dialogs/edit-open-adr-ven-dialog/edit-open-adr-ven-dialog.component';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class OpenAdrVenEntityService extends DialogHandler<OpenAdrVenEntity, ExecuteOpenAdrVenEntityAddOrUpdate, ExecuteOpenAdrVenEntityAddOrUpdate, {}, OpenAdrVenEntityList, void, void> {

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'OpenAdrVenEntity', toastr, dialog, dialogService, POLL_INTERVALS.openAdr);
    }

    protected getEditConfig(openAdrVenEntity: OpenAdrVenEntity): TDialogOptions<ExecuteOpenAdrVenEntityAddOrUpdate, EditOpenAdrVenDialogComponent> {
        return {
            headline: "OpenAdrVenEntity bearbeiten",
            component: EditOpenAdrVenDialogComponent,
            executeCallback: elem => this.update(openAdrVenEntity.id, elem),
            editElement: {
                venId: openAdrVenEntity.venId,
                baseUrl: openAdrVenEntity.baseUrl,
                cert: openAdrVenEntity.cert
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteOpenAdrVenEntityAddOrUpdate, EditOpenAdrVenDialogComponent> {
        return {
            headline: "OpenAdrVenEntity anlegen",
            component: EditOpenAdrVenDialogComponent,
            executeCallback: elem => this.create(elem),
            editElement: {
                venId: "",
                baseUrl: "",
                cert: ""
            }
        };
    }
}
