import {Component, OnInit} from '@angular/core';
import {OcppUplinkList} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {OcppUplinkService} from '../../service/ocpp-uplink.service';


@Component({
    selector: 'app-ocpp-uplinks-list-view',
    templateUrl: './ocpp-uplinks-list-view.component.html',
    styleUrls: ['./ocpp-uplinks-list-view.component.scss']
})
export class OcppUplinksListViewComponent implements OnInit {

    public ocppUplinks: BehaviorSubject<OcppUplinkList | undefined>

    constructor(
        private readonly ocppUplinkService: OcppUplinkService
    ) {
        this.ocppUplinks = this.ocppUplinkService.getAll();
    }

    ngOnInit(): void {
    }


    public async handleNewOcppUplink(): Promise<void> {
        await this.ocppUplinkService.showNewDialog(undefined);
    }

}
