import {Injectable} from '@angular/core';
import {DialogHandler} from "../../../services/api-handlers/dialog-handler";
import {UnknownOcppClient, UnknownOcppClientList} from "@io-elon-common/frontend-api";
import {ApiService} from "../../../services/api-handlers/api.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog.service";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class UnknownCpiService extends DialogHandler<UnknownOcppClient, never, never, {}, UnknownOcppClientList, void, void> {

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "UnknownOcppClient", toastr, dialog, dialogService, POLL_INTERVALS.unknown_ocpp);
    }

    protected getEditConfig(element: UnknownOcppClient, editDialogConfigArgs: void): never {
        throw new Error("Unable to edit UnknownOcppClient")
    }

    protected getNewConfig(newDialogConfigArgs: void): never {
        throw new Error("Unable to create UnknownOcppClient")
    }
}
