<div class="table-frame p-2">
    <div class="d-flex flex-row justify-content-between">
        <h2>Gateways</h2>
        <app-help-box-icon #gatewayHelp></app-help-box-icon>
    </div>
    <app-help-box [helpIcon]="gatewayHelp" [closedByDefault]="true">
        <p>
            Gateways werden in Ihrem Netzwerk installiert und erlauben den Zugriff auf IO-ELON via Modbus TCP von Ihrem Netzwerk/Standort aus
        </p>
        <p>
            Wenn Sie Modbus-Zugriff auf die IO-ELON Daten benötigen, melden Sie sich bitte bei unserem Support.
            Das Gateway können Sie dann frei Konfigurieren.
        </p>
    </app-help-box>
    <div *ngIf="(gateways | async) as gateways; else loading">
        <app-gateway-table [gateways]="gateways.list"></app-gateway-table>
    </div>
</div>
<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button *ngIf="isDev" mat-raised-button color="primary" (click)="handleNewGateway()">Gateway hinzufügen</button>
</div>
<ng-template #loading>Loading...</ng-template>
