<h1>Assignment Stats</h1>
<small>Diese Liste ist nach Kunden gefiltert. Es sind nur Informationen enthalten, die zum aktuell ausgewählten Kunden oder einem seiner Sub-<i>(Sub-...)</i> Kunden gehören</small>

@if (stats) {
    <table class="table table-striped">
        <thead>
           <tr>
               <th>Name</th>
               <th>Count</th>
               <th>Details</th>
           </tr>
        </thead>
        <tbody>
        <tr>
            <th colspan="3">Assignments</th>
        </tr>
        @for (stat of assignmentStats; track stat.name) {
            <ng-container *ngTemplateOutlet="row; context: {stat: stat}"></ng-container>
        }

        <tr>
            <th colspan="3">Un-Assignments</th>
        </tr>
        @for (stat of unassignmentStats; track stat.name) {
            <ng-container *ngTemplateOutlet="row; context: {stat: stat}"></ng-container>
        }
        </tbody>
    </table>
} @else {
    Loading...
}

<ng-template #row let-stat="stat">
    <tr>
        <td>{{stat.name.substring(2)}}</td>
        <td>{{stat.data.count}}</td>
        <td>
            <ul>
                @for (detail of stat.data.byDatasource | keyvalue; track detail.key) {
                    <li>{{detail.key}}: {{$any(detail.value).count}} ({{$any(detail.value).last | date:'short'}})</li>
                }
            </ul>
        </td>
    </tr>
</ng-template>
