import {Component, Input} from '@angular/core';
import {ExecuteVehicleKeyAddOrUpdate, UserList} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {UserService} from '../../../users/service/user.service';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';

const HOUR = 1000 * 60 * 60;

@Component({
  selector: 'app-give-out-vehicle-key-dialog',
  templateUrl: './give-out-vehicle-key-dialog.component.html',
  styleUrls: ['./give-out-vehicle-key-dialog.component.scss']
})
export class GiveOutVehicleKeyDialogComponent implements IEditForm<ExecuteVehicleKeyAddOrUpdate> {

    @Input()
    public data!: ExecuteVehicleKeyAddOrUpdate;
    public users: BehaviorSubject<UserList | undefined>;

    constructor(
        private readonly vehicleService: VehicleService,
        private readonly userService: UserService
    ) {
        this.users = this.userService.getAll();
    }

    validate(): string[] {
        const err: string[] = [];
        if(!this.data.currentLocation) {
            err.push("Es muss ein aktueller Ort eingegeben werden!");
        }
        return err;
    }

    public get hasReturnTime(): boolean {
        return this.data.expectedReturnTime !== null
    }

    public set hasReturnTime(val: boolean) {
        if(val) {
            this.data.expectedReturnTime = Date.now() + 3 * HOUR;
        } else {
            this.data.expectedReturnTime = undefined;
        }
    }
}
