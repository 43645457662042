import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LoadList, PowerSupply} from '@io-elon-common/frontend-api';
import {LoadService} from '../../service/load.service';
import {PowerSupplyService} from '../../../basis/service/power-supply.service';
import {FleetService} from '../../../vehicle/service/fleet.service';

@Component({
    selector: 'app-load-tiles-view',
    templateUrl: './load-tiles-view.component.html',
    styleUrls: ['./load-tiles-view.component.scss']
})
export class LoadTilesViewComponent implements OnInit, OnDestroy {
    public loads!: BehaviorSubject<LoadList | undefined>;
    private selectedFleetSubsciption!: Subscription;
    private fleetId!: number;
    private baseId?: number;

    public constructor(
        private readonly loadService: LoadService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly fleetService: FleetService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.loads = this.loadService.getAll();
        this.selectedFleetSubsciption = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetId = id;
                this.fleetService.getPromise(id).then(f => this.baseId = f.base.id);
            }
        });
    }

    public async newLoad() {
        const p: PowerSupply[] = (await this.powerSupplyService.getAllPromise()).list.filter(ps => ps.baseId === this.baseId);
        // @ts-ignore
        const pSorted = p.sort((a, b) => a.name.localeCompare(b.name));
        await this.loadService.showNewDialog(pSorted);
    }

    ngOnDestroy(): void {
        this.selectedFleetSubsciption.unsubscribe();
    }
}
