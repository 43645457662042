import {VehicleTeaser} from '@io-elon-common/frontend-api';

type StringBool = "true" | "false"

export type LocalStorageField = {
    "VEHICLE_DEFAULT_SORT_ORDER": keyof VehicleTeaser
    "POLL_ENABLED": StringBool
    "TARGET_VISIBLE": StringBool
    "SHOW_DEV_ITEMS": StringBool
    "SHOW_DEV_WEB_REQUESTS": StringBool
    CHARGE_PLAN_DIFFERENT_SCALES: StringBool
    CHARGE_PLAN_LOADS_IN_X1: StringBool
    "CHARGE_PLAN_TIME_VISIBLE": StringBool
    "CHARGE_PLAN_MESSAGES_VISIBLE": StringBool
    "CHARGING_TABLE_REORDER_CONFIG": string // JSON Object
    "MUTE_DEV_HINT": StringBool
    "DEFAULT_LIST_ENTRIES_COUNT": "10" | "20" | "25" | "30" | "40" | "50"
    "CHARGE_PLAN_ANIMATION_VEHICLE_LIMIT": "off" | "5" | "10" | "20"| "30"| "on"
    EVENT_LOG_LEVEL: "500" | "400" | "300" | "200" | "100"
    OCPP_DEBUG_URL: string
    "EVSE_SELECTED_TAB": "0" | "1"
    "EVSE_CHARGING_TABLE_REORDER_CONFIG": string // JSON Object
    "EVSE_TABLE_REORDER_CONFIG": string // JSON Object
    "METER_TABLE_REORDER_CONFIG": string // JSON Object
    "VEHICLE_SELECTED_TAB": "0" | "1" | "2" | "3"
    "VEHICLE_CHARGING_TABLE_REORDER_CONFIG": string // JSON Object
    "VEHICLE_TABLE_REORDER_CONFIG": string // JSON Object
    "OCPP_UPLINKS_TABLE_REORDER_CONFIG": string // JSON Object
    "LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_1": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_1": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_1": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_1": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_2": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_2": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_2": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_2": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_3": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_3": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_3": string // JSON Array
    "LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_3": string // JSON Array
    "FLEET_SORT_COLUMN": "name" | "plugIcon" | "evse" | "sourceDesc" | "stateOfCharge" | "range" | "soh"
    "FLEET_SORT_ORDER" : "asc" | "desc"
    "CLOSED_HELP_TEXTS" : string // JSON Array
    "DB_PERFORMANCE_FILTER": string // JSON Array
    "SERVICE_FILTER": string // JSON Array
    "SERVER_DELTA_TIME_VISIBLE": StringBool
    "SHOW_TABLE_FILTER_BOX": StringBool
    "SIDEBAR_CLOSED": StringBool
}


export function localStorageDelete<T extends keyof LocalStorageField>(key: T): void {
    localStorage.removeItem(key);
}

export function localStorageSave<T extends keyof LocalStorageField>(key: T, val: LocalStorageField[T]): void {
    localStorage.setItem(key, val);
}

export function localStorageGet<T extends keyof LocalStorageField, U>(key: T, defaultIfMissing?: U): LocalStorageField[T] | U{
    const item = localStorage.getItem(key);
    if(item !== undefined && item !== null) {
        // @ts-ignore
        return item;
    }
    // @ts-ignore
    return defaultIfMissing;
}
