import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AbstractTableComponent} from '../../../../../shared/components/tables/AbstractTableComponent';
import {GatewayRegisterService} from '../../service/gateway-register.service';
import {
    BasisList,
    EvseList,
    GatewayDeviceType,
    GatewayRegister,
    MeterList,
    PowerSupplyList,
    VehicleList
} from '@io-elon-common/frontend-api';
import {SystemService} from '../../../../../services/api-handlers/system.service';
import {SystemInfoModbusReadOption} from '@io-elon-common/frontend-api/lib/model/systemInfoModbusReadOption';
import {MeterService} from '../../../../meter/service/meter.service';
import {PowerSupplyService} from '../../../../basis/service/power-supply.service';
import {VehicleService} from '../../../../vehicle/service/vehicle.service';
import {EvseService} from '../../../../evse/service/evse.service';
import {BasisService} from '../../../../basis/service/basis.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-gateway-register-table',
  templateUrl: './gateway-register-table.component.html',
  styleUrl: './gateway-register-table.component.scss'
})
export class GatewayRegisterTableComponent extends AbstractTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @Input()
    public gatewayRegisters!: GatewayRegister[];
    public displayedColumns: string[] = ['device', 'field', 'modbusId', 'register', 'dataType', 'access', 'liveValue', 'actions'];
    public dataSource = new MatTableDataSource<GatewayRegister>([]);
    public readonly GatewayDeviceType = GatewayDeviceType;
    private modbusReadOption!: SystemInfoModbusReadOption
    private meters!: BehaviorSubject<MeterList | undefined>
    private vehicles!: BehaviorSubject<VehicleList | undefined>
    private evses!: BehaviorSubject<EvseList | undefined>
    private powerSupplies!: BehaviorSubject<PowerSupplyList | undefined>
    private basis!: BehaviorSubject<BasisList | undefined>

    constructor(
        private readonly gatewayRegisterService: GatewayRegisterService,
        private readonly systemInfoService: SystemService,
        private readonly meterService: MeterService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly vehicleService: VehicleService,
        private readonly evseService: EvseService,
        private readonly basisService: BasisService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.dataSource = new MatTableDataSource(this.gatewayRegisters);
        this.dataSource.sort = this.sort;
        this.modbusReadOption = (this.systemInfoService.getSystemInfoSync() ?? await this.systemInfoService.getSystemInfo()).modbusReadOption;

        this.meters = this.meterService.getAll();
        this.evses = this.evseService.getAll();
        this.vehicles = this.vehicleService.getAll();
        this.powerSupplies = this.powerSupplyService.getAll();
        this.basis = this.basisService.getAll();
    }

    public getBasis(id: number, deviceType: GatewayDeviceType): string {
        switch (deviceType) {
            case GatewayDeviceType.Meter: return this.getBasisFromMeter(id) ?? "N/A";
            case GatewayDeviceType.Evse: return this.getBasisFromEvse(id) ?? "N/A";
            case GatewayDeviceType.Vehicle: return this.getBasisFromVehicle(id) ?? "N/A";
            case GatewayDeviceType.PowerSupply: return this.getBasisFromBasisID(this.getBasisIdFromPowerSupply(id)) ?? "N/A";
            default: return "N/A";
        }
    }

    public ngAfterViewInit() {
        // tslint:disable-next-line:no-non-null-assertion
        this.dataSource.paginator = this.paginator!;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.gatewayRegisters;
    }

    public trackBy(idx: number, item: GatewayRegister) {
        return item.id;
    }

    public async edit(gatewayRegister: GatewayRegister): Promise<void> {
        await this.gatewayRegisterService.showEditDialog(gatewayRegister, {});
    }

    public async delete(gatewayRegister: GatewayRegister): Promise<void> {
        await this.gatewayRegisterService.showDeleteDialog(gatewayRegister, {});
    }

    public getDataType(gatewayRegister: GatewayRegister) : string {
       return (
           `${gatewayRegister.registerType} ${gatewayRegister.encoding} ${gatewayRegister.order} ${gatewayRegister.length}bit (${(this.getNumberOfWords(gatewayRegister.length) > 1 ? this.getNumberOfWords(gatewayRegister.length)+" Wörter": this.getNumberOfWords(gatewayRegister.length)+" Wort")})`
       )
    }

    public getAccess(value: string): string {
        switch (value) {
            case "RO": return "Lesen";
            case "WO": return "Schreiben"
            case "RW": return "Lesen/Schreiben"
            default: return "N/A"
        }
    }

    public getFieldName(gatewayRegister: GatewayRegister): string {
        switch (gatewayRegister.deviceType) {
            case 'Meter': return this.modbusReadOption.meter.fieldsSpec[gatewayRegister.field].displayName;
            case 'EVSE': return this.modbusReadOption.evse.fieldsSpec[gatewayRegister.field].displayName;
            case 'PowerSupply': return this.modbusReadOption.powerSupply.fieldsSpec[gatewayRegister.field].displayName;
            case 'Vehicle': return this.modbusReadOption.vehicle.fieldsSpec[gatewayRegister.field].displayName;
        }
    }

    private getNumberOfWords(length: number): number {
        return length / 16;
    }

    private getBasisFromMeter(id: number): string | undefined {
        if(this.meters.value) {
            return this.meters.value.list.find(meter => meter.id === id)?.basis?.name;
        }
    }

    private getBasisFromEvse(id: number): string | undefined {
        if (this.evses.value) {
            return this.evses.value.list.find(meter => meter.id === id)?.basis?.name;
        }
    }

    private getBasisFromVehicle(id: number): string | undefined {
        if (this.vehicles.value) {
            return this.vehicles.value.list.find(meter => meter.id === id)?.fleet.base.name;
        }
    }

    private getBasisIdFromPowerSupply(id: number): number | undefined {
        if (this.powerSupplies.value) {
            return this.powerSupplies.value.list.find(meter => meter.id === id)?.baseId
        }
    }

    private getBasisFromBasisID(id: number | undefined): string | undefined {
        if(id) {
            if(this.basis.value) {
                return  this.basis.value.list.find(value => value.id === value.id)?.name
            }
        }
    }
}
