<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Angeschlossen an:</mat-label>
            <mat-select [(value)]="data.powerSupplyId">
                <mat-option *ngFor="let p of possiblePowerSupplies" [value]="p.id">{{p.name}} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Messgerät:</mat-label>
            <mat-select [(value)]="data.meterId">
                <mat-option [value]=undefined>-- Kein Messgerät --</mat-option>
                <mat-option *ngFor="let m of possibleMeters" [value]="m.id">{{m.name}} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Messgerät-Glättung (Minuten):</mat-label>
            <input matInput type="number" min="0" max="900" step="1" [(ngModel)]="data.meterFlattenWindowMinutes">
        </mat-form-field>
    </div>
    <div>
        <h4>Angeschlossen an:</h4>
        <mat-checkbox [(ngModel)]="data.l1">L1</mat-checkbox>
        <mat-checkbox [(ngModel)]="data.l2">L2</mat-checkbox>
        <mat-checkbox [(ngModel)]="data.l3">L3</mat-checkbox>
    </div>
    <div class="d-flex flex-column align-content-start gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Solar-Daten-Provider</mat-label>
            <mat-select [(value)]="data.solarPredictionProvider">
                <mat-option value="NAECO_BLUE">Naeco Blue</mat-option>
                <mat-option *ngIf="data.solarPredictionProvider === 'ENERCAST' || isDev" value="ENERCAST">Enercast</mat-option>
                <mat-option value="FORECAST_SOLAR">Forecast.Solar</mat-option>
                <mat-option value="SOLAR_POSITION">Sonnenstand</mat-option>
            </mat-select>
        </mat-form-field>
        @switch (data.solarPredictionProvider) {
            @case ("NAECO_BLUE") {
                <p>
                    Die NAECO Blue GmbH stellt erstellt mithilfe von KI-Modellen und
                    Wetterprognosen die genauesten Solarprognosen für IO-ELON bereit.
                    Für die Integration von Solaranlagen ist es jedoch erforderlich,
                    vor der Einrichtung das Modell mit den realen Daten der Anlage
                    anzulernen. Für ein Angebot und eine Anbindung wenden Sie sich
                    bitte an den Support.
                </p>
                <mat-form-field appearance="fill">
                    <mat-label>Naeco Blue Dateiname</mat-label>
                    <input matInput type="text" [(ngModel)]="data.naecoBlueFileName">
                    <mat-hint>Es sollte ungefähr so aussehen: "NB003_SLR"</mat-hint>
                </mat-form-field>
            }
            @case ("SOLAR_POSITION") {
                <p>
                    Die Verwendung des Sonnenstandes erlaubt es, ohne externe
                    Dienstleister eine einfache Prognose für die Solarleistung zu
                    erhalten. Hierbei werden aus der Position und Ausrichtung der
                    Panels sowie dem Stand der Sonne relativ zur Anlage die erwarteten
                    Leistungen errechnet. Diese Berechnung berücksichtigt keine
                    Wetterdaten. Es eignet sich besonders für Anlagen, bei denen keine
                    sehr genauen Prognosen erforderlich sind, oder wenn ein Smartmeter
                    an der Solaranlage verbaut ist, durch welches die Fehler korrigiert
                    werden.
                </p>
            }
            @case ("ENERCAST") {
                <p>
                    Die Enercast GmbH stellt Prognosen auf Basis von Wetterdaten und
                    mit KI-Unterstützung bereit.
                </p>
            }
            @case ("FORECAST_SOLAR") {
                <p>
                    Forecast.Solar stellt Solarprognosen auf Basis der Wetterdaten
                    bereit. Diese Prognosen haben eine Genauigkeit, die gut geeignet
                    ist, um damit die Ladepläne für IO-ELON zu erstellen.
                    <br><br>
                    Da für diese Prognosen neben der in IO-ELON sowieso notwendigen
                    Konfiguration keine weite Einrichtung notwendig ist, und die
                    Qualität der Daten gut ist, ist dies die empfohlene Einstellung.
                    <br><br>
                    Falls Sie Forecast.Solar neben IO-ELON auch für andere Systeme
                    nutzen und einen API-Key haben, können Sie diesen hier eingeben.
                </p>
                <mat-form-field appearance="fill">
                    <mat-label>Forecast.Solar API key</mat-label>
                    <input matInput type="text" [(ngModel)]="data.forecastSolarKey">
                    <mat-hint></mat-hint>
                </mat-form-field>
            }
        }
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
