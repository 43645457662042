<div class="table-frame">
    <h2>S-Tec Accounts</h2>
    <ng-container *ngIf="(accounts | async) as accounts; else loading">
        <app-s-tec-account-table [accounts]="accounts.list"></app-s-tec-account-table>
    </ng-container>
</div>
<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button mat-raised-button color="primary" (click)="create()">Account hinzufügen</button>
</div>

<ng-template #loading>Loading...</ng-template>
