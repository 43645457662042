<table mat-table [dataSource]="dataSource" class="full-width-table" matSort [trackBy]="trackBy" (matSortChange)="setSort($event)" [matSortActive]="sort.active" [matSortDirection]="sort.direction">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="evses">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Ladepunkte</th>
        <td mat-cell *matCellDef="let element" class="col-id" [htmlTooltip]="evsesTemplate" [tooltipContext]="{selectedEvses: element.evses}">
            {{formatEvses(element.evses)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="rfids">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>RFID-Karten</th>
        <td mat-cell *matCellDef="let element" class="col-id" [htmlTooltip]="rfidHelp" [tooltipContext]="{selectedRfids: element.rfids}">
            {{formatRfids(element.rfids)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="guests">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Gäste</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.includeGuests ? "Ja" : "Nein"}}
        </td>
    </ng-container>

    <ng-container matColumnDef="pricePerEnergy">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Energiepreis</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{formatPrice(element.pricePerEnergy, "€/kWh")}}
        </td>
    </ng-container>

    <ng-container matColumnDef="pricePerSession">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Grundkosten</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{formatPrice(element.pricePerSession, "€")}}
        </td>
    </ng-container>

    <ng-container matColumnDef="pricePerHour">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Preis pro Stunde</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{formatPrice(element.pricePerHour, "€/Stunde")}}
        </td>
    </ng-container>

    <ng-container matColumnDef="initialFreeMinutes">
        <th mat-header-cell *matHeaderCellDef class="col-id" mat-sort-header>Freiminuten</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            @if(element.initialFreeMinutes == 0) {
                Keine
            } @else {
                <span [matTooltip]="element.initialFreeMinutes + ' Minuten'">
                    {{element.initialFreeMinutes * 60 * 1000 | duration}}
                </span>
            }
        </td>
    </ng-container>


    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons="true">
</mat-paginator>


<ng-template #rfidHelp let-selectedRfids="selectedRfids">
    @if(selectedRfids?.length > 0) {
        <div class="custom-tooltip d-flex flex-column flex-wrap" style="max-height: 80vh">
            @for (rfid of rfids; track rfid.id) {
                @if(selectedRfids.includes(rfid.id)) {
                    <div class="d-flex flex-row">
                        <div class="rfid-name">{{rfid.name}}</div>
                        <div class="rfid-id">(ID: {{rfid.id}})</div>
                    </div>
                }
            }
        </div>
    }
</ng-template>


<ng-template #evsesTemplate let-selectedEvses="selectedEvses">
    @if(selectedEvses?.length > 0) {
        <div class="custom-tooltip d-flex flex-column flex-wrap" style="max-height: 80vh">
            @for (evse of evses; track evse.id) {
                @if(selectedEvses.includes(evse.id)) {
                    <div class="d-flex flex-row">
                        <div class="evse-name">{{ evse.name }}</div>
                        <div class="evse-id">(ID: {{ evse.id }})</div>
                    </div>
                }
            }
        </div>
    }
</ng-template>

