import { Injectable } from '@angular/core';
import {
    Basis,
    ExecutePowerSupplyAddOrUpdate,
    PeakHistory,
    PowerSupply,
    PowerSupplyList
} from '@io-elon-common/frontend-api';
import { ApiService } from '../../../services/api-handlers/api.service';
import { DialogHandler } from '../../../services/api-handlers/dialog-handler';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TDialogOptions } from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import { EditPowerSupplyDialogComponent } from '../dialogs/edit-power-supply-dialog/edit-power-supply-dialog.component';
import { DialogService } from '../../../services/dialog.service';
import { ApiHandler } from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
    providedIn: 'root'
})
export class PowerSupplyService extends DialogHandler<PowerSupply, ExecutePowerSupplyAddOrUpdate, ExecutePowerSupplyAddOrUpdate, {}, PowerSupplyList, Basis, Basis> {
    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "PowerSupply", toastr, dialog, dialogService, POLL_INTERVALS.powerSupplies);
    }

    public async getPeaksHistory(powerSupplyId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<PeakHistory> {
        start = typeof start === 'number' ? Math.ceil(start) : start.getTime();
        end = typeof end === 'number' ? Math.floor(end) : end.getTime();

        return this.apiService.getPeaksByPowerSupplyAndTime(showAlerts, powerSupplyId, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    // tslint:disable-next-line:max-line-length
    protected getEditConfig(powerSupply: PowerSupply, basis: Basis): TDialogOptions<ExecutePowerSupplyAddOrUpdate, EditPowerSupplyDialogComponent> {
        return {
            headline: "Anschluss bearbeiten",
            component: EditPowerSupplyDialogComponent,
            executeCallback: editResult => this.update(powerSupply.id, editResult),
            editElement: {
                name: powerSupply.name || "",
                basisId: basis.id,
                oadrGridConnectionId: powerSupply.oadrGridConnectionId || "",
                peakCalculationIntervall: powerSupply.peakCalculationIntervall || 'YEARS',
                parentPowerSupplyId: powerSupply.parentPowerSupplyId,
                maxAmps: powerSupply.maxAmps,
                userDefinedPeak: powerSupply.userDefinedPeak,
                visible: powerSupply.visible
            }
        }
    }

    protected getNewConfig(basis: Basis): TDialogOptions<ExecutePowerSupplyAddOrUpdate, EditPowerSupplyDialogComponent> {
        return {
            headline: "Anschluss anlegen",
            component: EditPowerSupplyDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "",
                basisId: basis.id,
                oadrGridConnectionId: "",
                peakCalculationIntervall: 'YEARS',
                parentPowerSupplyId: basis.powerSupplies[0]?.id || undefined,
                maxAmps: 32,
                visible: true
            }
        }
    }

    public async showDeleteDialog(element: PowerSupply, data: {}): Promise<void> {
        const confirmed = await this.dialogService.showConfirmDialog(
            "Eintrag wirklich löschen?",
            "Diese Aktion kann nicht Rückgängig gemacht werden. Beim Löschen werden auch die Aufzeichnungen über den Peak zu dieser Power-Supply gelöscht! Dies hat keine Auswirkung auf die Aufzeichnungen von übergeordneten Peaks.",
            "Löschen",
            "Abbrechen",
            'warn',
            'accent')

        if (confirmed) {
            await this.delete(element.id, data);
            this.toastr.success("Eintrag gelöscht");
            return;
        }
        throw new Error("Benutzer hat die Aktion abgebrochen");
    }

    public async resetPeaks(powerSupplyId: number, showAlerts = true): Promise<void> {
        try {
            await this.apiService.resetPeaksByPowerSupply(showAlerts, powerSupplyId, undefined, undefined, ApiHandler.customerId).toPromise();
            this.toastr.success("Höchster Peak wurde zurückgesetzt", "Wird zurückgesetzt");
        } catch (e) {
            this.toastr.warning("Höchster Peak konnte nicht zurückgesetzt werden");
        }

    }
}
