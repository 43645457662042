import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiErrors, BehaviorSubjectWithErrorChannel} from '../../../../services/api-handlers/cacheManager';
import {Gateway} from '@io-elon-common/frontend-api';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GatewayService} from '../../service/gateway.service';
import {AuthService} from "../../../../shared/guards/auth.service";

@Component({
  selector: 'app-gateway-details',
  templateUrl: './gateway-details.component.html',
  styleUrl: './gateway-details.component.scss'
})
export class GatewayDetailsComponent implements OnInit, OnDestroy {
    public gateway!: BehaviorSubjectWithErrorChannel<Gateway | undefined, ApiErrors>
    private routerEventSubscription!: Subscription;
    private errorSubscription?: Subscription;
    public show404 = false;
    public isDev = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly gatewayService: GatewayService,
        private readonly router: Router,
        private readonly authService: AuthService
    ) {
        this.isDev = this.authService.isDeveloper();
    }

    public ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.afterNavigation();
            }
        });
        this.afterNavigation();
    }

    public ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
        this.errorSubscription?.unsubscribe();
    }

    private afterNavigation() {
        this.show404 = false;
        const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        if (id) {
            this.gateway = this.gatewayService.get(id, false);
            this.errorSubscription?.unsubscribe();
            this.errorSubscription = this.gateway.errorChannel.subscribe(err => {
                this.show404 = err?.type === "NOT_FOUND";
            });
        }
    }
}
