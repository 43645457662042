<div class="d-flex flex-column gap-3">
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput type="text" [formControl]="ctrlName">
        <mat-error *ngIf="ctrlName.errors?.required as error">{{error}}</mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill">
        <mat-label>Address</mat-label>
        <input matInput type="text" [formControl]="ctrlAddress" matTooltip="Bitte geben Sie die Gateway Addresse im IPv4 format an (z. B. 192.168.1.0)">
        <mat-error *ngIf="ctrlAddress.errors?.required as error">{{error}}</mat-error>
        <mat-error *ngIf="ctrlAddress.errors?.invalidAddress as error">{{error}}</mat-error>

    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Netzmaske</mat-label>
        <input matInput type="number" min="0" max="32" [formControl]="ctrlMask" matTooltip="Bitte geben Sie die IPv4 Netzmaske als Zahl zwischen 0 und 32 an">
        <mat-error *ngIf="ctrlMask.errors?.required as error">{{error}}</mat-error>
        <mat-error *ngIf="ctrlMask.errors?.min as error">Der Wert für die Netzmaske darf nicht kleiner sein als {{ctrlMask.errors?.min.min}}</mat-error>
        <mat-error *ngIf="ctrlMask.errors?.max as error">Der Wert für die Netzmaske darf nicht größer sein als {{ctrlMask.errors?.max.max}}</mat-error>
    </mat-form-field>
</div>
<ng-template #loading>Loading...</ng-template>
