<div class="table-frame">
    <h2>Free To Move (<mat-icon style="transform: translate(0, 5px)" svgIcon="free2moveColor"></mat-icon> Connected Fleet <a target="_blank" href="https://www.free2move.com/de-DE/business-solutions/connect-fleet"><mat-icon style="transform: translate(0, 5px)">open_in_new</mat-icon></a>) Verlinkungen</h2>
    <div *ngIf="(freeToMoves | async) as freeToMoves; else loading">
        <app-free-to-move-table [freeToMoves]="freeToMoves.list"></app-free-to-move-table>
    </div>
    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <button mat-raised-button color="primary" (click)="handleNewFreeToMove()">Neue Verlinkung</button>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>
