<div class="table-frame p-2">
    <h2>Gateway Register</h2>
    <div *ngIf="(gatewayRegisters | async) as gatewayRegisters; else loading">
        <app-gateway-register-table [gatewayRegisters]="gatewayRegisters"></app-gateway-register-table>
    </div>
</div>
<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button mat-raised-button color="primary" (click)="handleNewRegister()" [disabled]="!canCreate">Register hinzufügen</button>
</div>
<ng-template #loading>Loading...</ng-template>
