import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DateTimeDialogData {
    tst: number;
}

@Component({
  selector: 'app-date-time-component',
  templateUrl: './live-data-date-time-dialog.component.html',
  styleUrl: './live-data-date-time-dialog.component.scss'
})
export class LiveDataDateTimeDialog implements OnInit {
    public dateTime!: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: DateTimeDialogData,
        public dialogRef: MatDialogRef<LiveDataDateTimeDialog>
    ) {
    }

    ngOnInit(): void {
        this.dateTime = this.dialogData.tst;
    }

    accept(): void {
        this.dialogRef.close(this.dateTime);
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
