<div class="position-relative">
    <h1>Nicht konfigurierte Ladestationen</h1>
    <app-help-box-icon class="help-icon" [iconType]="DialogType.HELP" #helpIcon></app-help-box-icon>

    <app-help-box [helpIcon]="helpIcon" key="UNKNOWN_CPI" [dialogType]="DialogType.HELP">
        Hier können OCPP Ladestation unterstützt eingerichtet werden.<br>
        Für die unterstütze Einrichtung muss zunächst die Station nach Herstelleranleitung eingerichtet werden.
        Als Option OCPP Prefix muss gesetzt werden: <b>ws{{secure ? 's' : ''}}://{{host}}/ocppj/{{fleetId | async}}/</b><br>
        Für die CPI (Charge Point Identity) kann ein beliebiger alphanumerischer Name (Zahlen und Buchstaben)
        gesetzt werden. Die CPI muss innerhalb der Flotte einzigartig sein.<br>
        Anschließend werden die Station automatisch in der unten stehenden Liste aufgelistet und
        können geführt in das IO-ELON System integriert werden.
    </app-help-box>

    <div *ngIf="(unknownCpis | async) as unknownCpis">
        <app-unknown-cpi-table [unknownCpis]="unknownCpis.list"></app-unknown-cpi-table>
    </div>
</div>
