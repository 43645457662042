import {Component, inject, model} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    AbstractReorderTableComponent,
    ReorderingDialogAction,
    ReorderingDialogConfig
} from '../../../../shared/components/tables/AbstractReorderTableComponent';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-table-reorder-dialog',
    templateUrl: './table-reorder-dialog.component.html',
    styleUrl: './table-reorder-dialog.component.scss'
})
export class TableReorderDialogComponent {

    readonly dialogRef = inject(MatDialogRef<AbstractReorderTableComponent>);
    readonly data = inject<ReorderingDialogConfig>(MAT_DIALOG_DATA);
    readonly filters = model(this.data);

    onNoClick(): void {
        this.data.action = ReorderingDialogAction.NONE;
        this.dialogRef.close();
    }

    drop(event: CdkDragDrop<string[]>) {
        this.data.action = ReorderingDialogAction.STORE;
        moveItemInArray(this.data.columns, event.previousIndex, event.currentIndex);
    }

    toggleVisibility(col: string) {
        this.data.action = ReorderingDialogAction.STORE;
        this.data.invisible.set(col, !this.data.invisible.get(col))
    }

    reset() {
        this.data.action = ReorderingDialogAction.RESET;
        this.data.columns = this.data.defaultColumns.map(col => col);
        this.data.invisible.clear();
        this.data.invisible.clear();

    }

}
