<div *ngIf="(allPermissions | async) as allPermissions; else loading">
    <mat-card appearance="outlined" class="p-0">
        <app-permission-group-table [permissionGroups]="allPermissions.list"></app-permission-group-table>
    </mat-card>

</div>
<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button [disabled]="!canCreate" mat-raised-button color="primary" (click)="newPermission()">Neue Berechtigungsgruppe</button>
</div>
<ng-template #loading>
    Loading...
</ng-template>
