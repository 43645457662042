import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IEditForm} from '../../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {
    BasisList,
    Evse,
    ExecuteGatewayRegisterAddOrUpdate,
    GatewayDeviceType,
    Meter,
    PowerSupply,
    SystemInfoModbusFieldSpec,
    SystemInfoModbusReadOptionsMap,
    Vehicle
} from '@io-elon-common/frontend-api';
import {SystemService} from '../../../../../services/api-handlers/system.service';
import {MatSelectChange} from '@angular/material/select';
import {MeterService} from '../../../../meter/service/meter.service';
import {PowerSupplyService} from '../../../../basis/service/power-supply.service';
import {VehicleService} from '../../../../vehicle/service/vehicle.service';
import {EvseService} from '../../../../evse/service/evse.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
    SystemInfoModbusSupportedEncoding
} from '@io-elon-common/frontend-api/lib/model/systemInfoModbusSupportedEncoding';
import {FormControl, Validators} from '@angular/forms';
import {required} from '../../../../../shared/components/form-control-validator/validator';
import {BasisService} from '../../../../basis/service/basis.service';

export class ModbusDataType {
    public constructor(
        readonly register: string,
        readonly encoding: SystemInfoModbusSupportedEncoding
    ) {
    }
    get display(): string {return `${this.register} ${this.encoding.dataType} ${this.encoding.byteOrder} ${this.encoding.length}bit (${(this.numberOfWords > 1 ? this.numberOfWords+" Wörter" : this.numberOfWords+ " Wort")})`}
    get numberOfWords(): number {return (this.encoding.length ?? 0)/ 16}
}

@Component({
    selector: 'app-edit-gateway-register',
    templateUrl: './edit-gateway-register.component.html',
    styleUrl: './edit-gateway-register.component.scss'
})


export class EditGatewayRegisterComponent implements IEditForm<ExecuteGatewayRegisterAddOrUpdate>, OnInit, OnDestroy {
    @Input()
    public data!: ExecuteGatewayRegisterAddOrUpdate;
    public deviceTypes = Object.values(GatewayDeviceType);
    public availableMeters: Meter[] = [];
    public availableEvses: Evse[] = [];
    public availableVehicles: Vehicle[] = [];
    public availablePowerSupplies: PowerSupply[] = [];
    public availableMetersSubscription!: Subscription
    public availableEvsesSubscription!: Subscription
    public availableVehiclesSubscription!: Subscription
    public availablePowerSuppliesSubscription!: Subscription
    public compatibleSpec!: SystemInfoModbusReadOptionsMap;
    public compatibleModbusDataTypes: ModbusDataType[] = []
    public selectedModbusDataType: ModbusDataType | undefined;
    public filter: any[] = []
    public readonly GatewayDeviceType = GatewayDeviceType;
    public ctrlModbusDeviceId!: FormControl<number | undefined | null>;
    public ctrlModbusStartRegister!: FormControl<number | undefined | null>
    public ctrlModbusDeviceIdSubscription!: Subscription;
    public ctrlModbusStartRegisterSubscription!: Subscription;
    public basisList!: BehaviorSubject<BasisList | undefined>

    constructor(
        private readonly systemInfoService: SystemService,
        private readonly meterService: MeterService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly vehicleService: VehicleService,
        private readonly evseService: EvseService,
        private readonly basisService: BasisService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.ctrlModbusStartRegister = new FormControl(this.data.startAddress <1 ? undefined : this.data.startAddress, [
            required("Modbus Start Register muss ausgefüllt sein"),
            Validators.min(1)
            ]
        );
        this.ctrlModbusDeviceId = new FormControl(this.data.modbusId <1 ? undefined : this.data.modbusId, [
            required("Modbus Device ID muss ausgefüllt sein"),
            Validators.max(500),
            Validators.min(1)
        ]);
        this.ctrlModbusDeviceIdSubscription = this.ctrlModbusStartRegister.valueChanges.subscribe(value => {
            if(value) {
                this.data.startAddress = value;
            }
        });
        this.ctrlModbusStartRegisterSubscription = this.ctrlModbusDeviceId.valueChanges.subscribe(value => {
            if(value) {
                this.data.modbusId = value;
            }
        })
        this.basisList = this.basisService.getAll();
        this.availableMetersSubscription = this.meterService.getAll().subscribe(value => {
            if (value) {
                this.availableMeters = value.list.sort((a, b) => {
                    const basisAId = a.basis?.id ?? -1;
                    const basisBId = b.basis?.id ?? -1;
                    if (basisAId !== basisBId) {
                        return basisBId - basisBId; // Ascending order
                    }
                    return (a.name ?? '').localeCompare(b.name ?? '');
                });
            }
        });
        this.availableVehiclesSubscription = this.vehicleService.getAll().subscribe(value => {
            if(value) {
                this.availableVehicles = value.list.sort((a, b) => {
                    const basisAId = a.fleet.base?.id ?? -1;
                    const basisBId = b.fleet.base?.id ?? -1;
                    if (basisAId !== basisBId) {
                        return basisBId - basisBId; // Ascending order
                    }
                    return (a.name ?? "").localeCompare(b.name ?? "");
                });
            }
        });
        this.availableEvsesSubscription = this.evseService.getAll().subscribe(value => {
            if(value) {
                this.availableEvses = value.list.sort((a, b) => {
                    const basisAId = a.basis?.id ?? -1;
                    const basisBId = b.basis?.id ?? -1;
                    if (basisAId !== basisBId) {
                        return basisBId - basisBId; // Ascending order
                    }
                    return (a.name ?? "").localeCompare(b.name ?? "");
                });
            }
        });
        this.availablePowerSuppliesSubscription = this.powerSupplyService.getAll().subscribe(value => {
            if(value) {
                this.availablePowerSupplies = value.list.filter(value1 => value1.parentPowerSupplyId === undefined).sort((a, b) => {
                    const basisAId = a.baseId;
                    const basisBId = b.baseId;
                    if (basisAId !== basisBId) {
                        return basisBId - basisBId; // Ascending order
                    }
                    return (a.name ?? "").localeCompare(b.name ?? "");
                });
            }
        });
        await this.getModbusReadOptions(this.data.deviceType);
        this.getCompatibleModbusDataTypes(this.compatibleSpec.fieldsSpec[this.data.field]);
        this.filter = [...this.getPossibleRegisterTypes(), ...this.getPossibleDataTypes(), ...this.getPossibleByteOrder(), ...this.getPossibleWordLength()];
        this.setSelectedModbusDataType();
    }


    public validate(): string[] | Promise<string[]> {
        const ret: string[] = [];
        this.ctrlModbusStartRegister.markAsTouched()
        this.ctrlModbusDeviceId.markAsTouched()
        if (this.ctrlModbusDeviceId.errors?.min) {
            ret.push(`Der Wert Modbus Device ID darf nicht kleiner sein als ${this.ctrlModbusDeviceId.errors?.min.min}`);
        }
        if (this.ctrlModbusDeviceId.errors?.max) {
            ret.push(`Der Wert Modbus Device ID darf nicht größer sein als ${this.ctrlModbusDeviceId.errors?.min.max}`);
        }
        if (this.ctrlModbusDeviceId.errors?.required) {
            ret.push(this.ctrlModbusDeviceId.errors?.required);
        }
        if (this.ctrlModbusStartRegister.errors?.min) {
            ret.push(`Der Wert Modbus Start Register darf nicht kleiner sein als ${this.ctrlModbusDeviceId.errors?.min.min}`);
        }
        if (this.ctrlModbusStartRegister.errors?.required) {
            ret.push(this.ctrlModbusStartRegister.errors?.required);
        }
        if(!this.data.field) {
            ret.push("Es wurde kein Feld gewählt welche Daten bereitstellt werden sollen")
        }
        if(!this.data.access) {
            ret.push("Keine gültige Zugriffsberechtigung gewählt.")
        }
        if(!this.data.registerType || this.data.registerType.length < 1 || !this.data.order || !this.data.encoding) {
            ret.push("Kein Modbus Daten Typ gewählt.")
        }
        return ret
    }


    public async changeDeviceType(event: MatSelectChange) {
       this.removeDataType();
       this.removeFieldSpec();
       await this.getModbusReadOptions(event.value);
    }

    public onFieldChange(event: MatSelectChange) {
        this.removeDataType();
        this.getCompatibleModbusDataTypes(this.compatibleSpec.fieldsSpec[event.value]);
        this.filter = [...this.getPossibleRegisterTypes(), ...this.getPossibleDataTypes(), ...this.getPossibleByteOrder(), ...this.getPossibleWordLength()];
    }

    public onDataTypeChange(event: MatSelectChange) {
        this.data.registerType = event.value.register;
        this.data.length = event.value.encoding.length;
        this.data.order = event.value.encoding.byteOrder;
        this.data.encoding = event.value.encoding.dataType;
    }

    public filterModbusDataTypes(): ModbusDataType[] {
       return this.compatibleModbusDataTypes.filter(value =>
           this.filter.includes(value.register) &&
           this.filter.includes(value.encoding.dataType ?? "") &&
           this.filter.includes(value.encoding.byteOrder ?? "") &&
           this.filter.includes(value.numberOfWords)
       )
    }

    getBasisName(id: number): string {
        if(this.basisList.value) {
            const result = this.basisList.value.list.find(value => value.id === id);
            return result?.name ?? "N/A";
        }
        return 'N/A';
    }

    private async getModbusReadOptions(value: GatewayDeviceType) {
        switch (value) {
            case GatewayDeviceType.Evse:
                this.compatibleSpec = (this.systemInfoService.getSystemInfoSync() ?? await this.systemInfoService.getSystemInfo()).modbusReadOption.evse;
                break;
            case GatewayDeviceType.Vehicle:
                this.compatibleSpec = (this.systemInfoService.getSystemInfoSync() ?? await this.systemInfoService.getSystemInfo()).modbusReadOption.vehicle;
                break;
            case GatewayDeviceType.Meter:
                this.compatibleSpec = (this.systemInfoService.getSystemInfoSync() ?? await this.systemInfoService.getSystemInfo()).modbusReadOption.meter;
                break;
            case GatewayDeviceType.PowerSupply:
                this.compatibleSpec = (this.systemInfoService.getSystemInfoSync() ?? await this.systemInfoService.getSystemInfo()).modbusReadOption.powerSupply;
                break;
        }
    }

    public getPossibleRegisterTypes(): string[] {
        return this.compatibleModbusDataTypes.map(value => value.register).reduce<string[]>((acc, value) => {
            if (!acc.includes(value)) {
                acc.push(value);
            }
            return acc;
        }, []);
    }

    public getPossibleDataTypes(): string[] {
        return this.compatibleModbusDataTypes.map(value => value.encoding.dataType).reduce<string[]>((acc, value) => {
            if (value && !acc.includes(value)) {
                acc.push(value);
            }
            return acc;
        }, []);
    }

    public getPossibleByteOrder(): string[] {
        return this.compatibleModbusDataTypes.map(value => value.encoding.byteOrder).reduce<string[]>((acc, value) => {
            if (value && !acc.includes(value)) {
                acc.push(value);
            }
            return acc;
        }, []);
    }

    public getPossibleWordLength(): number[] {
        return this.compatibleModbusDataTypes.map(value => ((value.encoding.length ?? 0) / 16)).reduce<number[]>((acc, value) => {
            if (value && !acc.includes(value)) {
                acc.push(value);
            }
            return acc;
        }, []);
    }

    public ngOnDestroy(): void {
        this.ctrlModbusStartRegisterSubscription.unsubscribe();
        this.ctrlModbusDeviceIdSubscription.unsubscribe();
        this.availableVehiclesSubscription.unsubscribe();
        this.availableMetersSubscription.unsubscribe();
        this.availablePowerSuppliesSubscription.unsubscribe();
        this.availableEvsesSubscription.unsubscribe();
    }

    private getCompatibleModbusDataTypes(systemInfoModbusFieldSpec: SystemInfoModbusFieldSpec): void {
        this.compatibleModbusDataTypes = [];
        for (const register of systemInfoModbusFieldSpec.supportedRegisters) {
            for (const encoding of systemInfoModbusFieldSpec.supportedEncoding) {
                this.compatibleModbusDataTypes.push(
                    new ModbusDataType(Object.values(register)[0], encoding)
                );
            }
        }
        this.compatibleModbusDataTypes.sort((a, b) => a.display.localeCompare(b.display))
    }

    private removeDataType() {
        this.selectedModbusDataType = undefined;
        this.data.registerType = "";
        this.data.encoding = "";
        this.data.order = "";
        this.compatibleModbusDataTypes = [];
    }

    private removeFieldSpec() {
        this.data.field = "";
    }

    private setSelectedModbusDataType() {
        const result = this.compatibleModbusDataTypes.filter(value =>
            value.register === this.data.registerType &&
            value.encoding.length === this.data.length &&
            value.encoding.byteOrder === this.data.order &&
            value.encoding.dataType === this.data.encoding
        );
        if(result.length === 1) {
            this.selectedModbusDataType = result[0];
        }
    }
}
