<div class="dialog-container">
    <h2>Live Daten Datum Uhrzeit</h2>
    <app-date-time-form [(dateTime)]="dateTime"></app-date-time-form>
</div>
<mat-dialog-actions class="d-flex flex-row justify-content-end">
    <button mat-raised-button color="accent" type="submit" (click)="cancel()" style="margin-right: 15px">
        Abbrechen
    </button>
    <button mat-raised-button color="primary" type="submit" (click)="accept()" style="margin-right: 15px">
        Bestätigen
    </button>
</mat-dialog-actions>
