import {Injectable} from '@angular/core';

import {ApiService} from '../../../services/api-handlers/api.service';
import {ApiHandler} from "../../../services/api-handlers/api-handler";

import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {
    EditConfigurationCommentDialogComponent
} from '../dialogs/edit-configuration-comment-dialog/edit-configuration-comment-dialog.component';
import {ConfigDataComment} from '@io-elon-common/frontend-api/lib/model/configDataComment';
import {ConfigurationDataList, ExecuteConfigCommentAddOrUpdate} from '@io-elon-common/frontend-api';
import {POLL_INTERVALS} from "../../../app.module";



@Injectable({
    providedIn: 'root'
})
export class ConfigurationDataService extends DialogHandler<ConfigDataComment, ExecuteConfigCommentAddOrUpdate, ExecuteConfigCommentAddOrUpdate, {}, never, string, void> {

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'ConfigurationComment', toastr, dialog, dialogService, POLL_INTERVALS.configurationData);
    }

    public getConfigurationDataList(showAlerts = true): Promise<ConfigurationDataList> {
        return this.apiService.getConfigurationDataList(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getEditConfig(configDataComment: ConfigDataComment): TDialogOptions<ExecuteConfigCommentAddOrUpdate, EditConfigurationCommentDialogComponent> {
        return {
            headline: "Kommentar bearbeiten",
            component: EditConfigurationCommentDialogComponent,
            executeCallback: elem => this.update(configDataComment.id, elem),
            editElement: {
                name: configDataComment.name,
                comment: configDataComment.comment
            }
        };
    }

    public getNewConfig(name: string): TDialogOptions<ExecuteConfigCommentAddOrUpdate, EditConfigurationCommentDialogComponent> {
        return {
            headline: "Kommentar anlegen",
            component: EditConfigurationCommentDialogComponent,
            executeCallback: elem => this.create(elem),
            editElement: {
                name: name,
                comment: ""
            }
        };
    }
}
