import {Component, Input} from '@angular/core';
import {ExecuteDongleAddOrUpdate, VehicleList} from '@io-elon-common/frontend-api';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {BehaviorSubject} from 'rxjs';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {isNumeric} from 'rxjs/internal-compatibility';

@Component({
    selector: 'app-edit-dongle-dialog',
    templateUrl: './edit-dongle-dialog.component.html',
    styleUrls: ['./edit-dongle-dialog.component.scss']
})
export class EditDongleDialogComponent implements IEditForm<ExecuteDongleAddOrUpdate>{

    @Input()
    public data!: ExecuteDongleAddOrUpdate;
    public vehicles: BehaviorSubject<VehicleList | undefined>;

    constructor(
        private readonly vehicleService: VehicleService
    ) {
        this.vehicles = this.vehicleService.getAll();
    }

    validate(): string[] {
        if (this.data.dongleId === null || (isNumeric(this.data.dongleId) && this.data.dongleId < 0)) {
            return ["ungültige Dongle ID"];
        }
        return [];
    }
}
