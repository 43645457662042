import {Component, OnDestroy, OnInit} from '@angular/core';
import {SystemService} from "../../../services/api-handlers/system.service";
import {Subscription} from "rxjs";
import {DatePipe, KeyValuePipe, NgTemplateOutlet} from "@angular/common";
import {AssignmentStatisticReason} from "@io-elon-common/frontend-api";

@Component({
  selector: 'app-assignment-stats',
  standalone: true,
    imports: [
        KeyValuePipe,
        DatePipe,
        NgTemplateOutlet
    ],
  templateUrl: './assignment-stats.component.html',
  styleUrl: './assignment-stats.component.scss'
})
export class AssignmentStatsComponent implements OnInit, OnDestroy {
    public stats?: Array<{name: string, data: AssignmentStatisticReason}>;
    public assignmentStats?: Array<{name: string, data: AssignmentStatisticReason}>;
    public unassignmentStats?: Array<{name: string, data: AssignmentStatisticReason}>;
    private subscription?: Subscription;

    public constructor(
        private readonly systemService: SystemService
    ) {
    }

    ngOnInit(): void {
        this.subscription = this.systemService.assignmentStats().subscribe(next => {
            if(next) {
                this.stats = Object.keys(next).map(k => {
                    return {
                        name: k,
                        data: next[k]
                    }
                });
                this.stats.sort((a, b) => {
                    return b.data.count - a.data.count;
                });
                this.assignmentStats = this.stats.filter(a => a.data.assign);
                this.unassignmentStats = this.stats.filter(a => !a.data.assign);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

}
