import {Injectable} from '@angular/core';
import {DialogHandler} from "../../../services/api-handlers/dialog-handler";
import {ExecuteWhitelistAddOrUpdate, WhitelistEntry, WhitelistEntryList} from "@io-elon-common/frontend-api";
import {ApiService} from "../../../services/api-handlers/api.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog.service";
import {TDialogOptions} from "../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {EditWhitelistEntryDialogComponent} from "../dialogs/edit-whitelist-entry-dialog/edit-whitelist-entry-dialog.component";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class WhitelistService extends DialogHandler<WhitelistEntry, ExecuteWhitelistAddOrUpdate, ExecuteWhitelistAddOrUpdate, {}, WhitelistEntryList, {evse: number}, void>{

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "EvseConfigWhitelist", toastr, dialog, dialogService, POLL_INTERVALS.whiteliste);
    }

    protected getEditConfig(whitelistEntry: WhitelistEntry): TDialogOptions<ExecuteWhitelistAddOrUpdate, EditWhitelistEntryDialogComponent> {
        return {
            headline: "Whitelist Eintrag bearbeiten",
            component: EditWhitelistEntryDialogComponent,
            executeCallback: editResult => this.update(whitelistEntry.id, editResult),
            editElement: {
                evse: whitelistEntry.evse,
                key: whitelistEntry.key
            }
        };
    }

    protected getNewConfig(newDialogConfigArgs: {evse: number}): TDialogOptions<ExecuteWhitelistAddOrUpdate, EditWhitelistEntryDialogComponent> {
        return {
            headline: "Whitelist Eintrag hinzufügen",
            component: EditWhitelistEntryDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                evse: newDialogConfigArgs.evse,
                key: ""
            }
        };
    }
}
