import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DurationPipe} from './duration.pipe';

@Pipe({
    name: 'age',
    pure: false,
    standalone: true,
})
export class AgePipe implements PipeTransform {

    constructor(
        private readonly datePipe: DatePipe,
        private readonly durationPipe: DurationPipe) {
    }


    transform(value: Date | number, switchToDateOffset = 1000 * 60 * 60 * 24 * 3 /*3 Tage*/, withPrefix = true, upperCase = true): string {
        value = typeof value === 'number' ? value : value.getTime();
        if (value === 0) {
            return upperCase ? 'Nie' : 'nie';
        }
        const age = Date.now() - value;
        if(age < 0) {
            if(-age > switchToDateOffset) { // 3 Tage
                return (withPrefix ? upperCase ? 'Am ' : 'am ' : '') + this.datePipe.transform(value) as string;
            }
            const duration = this.durationPipe.transform(-age);
            let txt = (withPrefix ? upperCase ? 'In ': 'in ' : '') + duration;
            if (txt.endsWith('Tage')) {
                txt += 'n';
            }
            return txt;
        } else {
            if (age > switchToDateOffset) { // 3 Tage
                return (withPrefix ? upperCase ? 'Am ' : 'am ' : '') + this.datePipe.transform(value) as string;
            }
            const duration = this.durationPipe.transform(age);
            let txt = (withPrefix ? upperCase ? 'Vor ' : 'vor ' : '') + duration;
            if (txt.endsWith('Tage')) {
                txt += 'n';
            }
            return txt;
        }
    }
}
