import {Component, Input} from '@angular/core';
import {
    ExecuteTeslaApiDatasourceAddOrUpdate,
    TeslaAccountList,
    TeslaAccountVehicle,
    VehicleList
} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {TeslaAccountService} from '../../service/tesla-account.service';

@Component({
    selector: 'app-edit-tesla-api-dialog',
    templateUrl: './edit-tesla-api-datasource-dialog.component.html',
    styleUrls: ['./edit-tesla-api-datasource-dialog.component.scss']
})
export class EditTeslaApiDatasourceDialogComponent implements IEditForm<ExecuteTeslaApiDatasourceAddOrUpdate>{

    @Input()
    public data!: ExecuteTeslaApiDatasourceAddOrUpdate;

    public vehicles: BehaviorSubject<VehicleList | undefined>;
    public accounts: BehaviorSubject<TeslaAccountList | undefined>;
    public vehiclesInAccount?: BehaviorSubject<TeslaAccountVehicle[] | undefined>;

    public constructor(
        private readonly vehicleService: VehicleService,
        private readonly teslaAccountService: TeslaAccountService
    ) {
        this.vehicles = this.vehicleService.getAll();
        this.accounts = this.teslaAccountService.getAll();
    }

    public updateVehiclesInAccount() {
        this.vehiclesInAccount = this.teslaAccountService.getVehicleList(this.data.teslaAccountId);
    }

    validate(): string[] {
        return [];
    }
}
