<div style="white-space: nowrap" class="w-100 position-relative">
    <div class="chargeplan-header">
        <p class="init-time" (click)="toggleTime()" [style]="timeVisible?'opacity:100':''">
            <span class="created">{{plan ? (plan.initTime | age) : ''}}</span><span class="created-label"> erzeugt</span>
            <span class="updated">, {{plan ? (plan.calculationEndTime - plan.calculationStartTime) : ''}}ms gerechnet</span>
            <span class="updated" *ngIf="plan?.updateTime">, {{plan?.updateTime ? ((plan?.updateTime || 0) | age) : ''}} geupdated</span>
            <span class="version">, Version: {{plan ? (plan.version) : ''}}</span>
        </p>
        <div class="info-wrapper">
            <h2>Ladeplan</h2>
            <span class="info" [class.blink]="helpBlink">{{info}}</span>
        </div>
        <div class="ctrl-elements">

            <div class="group">
                <mat-form-field appearance="fill" class="selection-field transparent">
                    <mat-label>Vergangenheit anzeigen</mat-label>
                    <mat-select [(ngModel)]="selectedDateRange">
                        <mat-option *ngFor="let category of dateRange" [value]="category.value"
                                    (click)="changeDateRangeSelection()">{{category.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-radio-group
                [(ngModel)]="combined"
                (change)="updateCombined()">
                <mat-radio-button [value]="false">Einzeln</mat-radio-button>
                <mat-radio-button [disabled]="unitSelector==='soc'" [value]="true">Zusammenfassen</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group
                [(ngModel)]="unitSelector"
                (change)="updateUnit()">
                <mat-radio-button [value]="'iMax'">Strom (Max) (A)</mat-radio-button>
                <mat-radio-button [value]="'pwr'">Leistung (kW)</mat-radio-button>
                <mat-radio-button [disabled]="combined" [value]="'soc'">Ladestand (%)</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group
                [(ngModel)]="unitSelector"
                (change)="updateUnit()">
                <mat-radio-button [value]="'i1'">Strom I1 (A)</mat-radio-button>
                <mat-radio-button [value]="'i2'">Strom I2 (A)</mat-radio-button>
                <mat-radio-button [value]="'i3'">Strom I3 (A)</mat-radio-button>
            </mat-radio-group>
            <div class="group">
                <mat-checkbox [disabled]="!combined" [(ngModel)]="showPeak" (change)="draw('checkbox')">Höchster Peak anzeigen</mat-checkbox>
                <mat-checkbox [disabled]="!combined" [(ngModel)]="showLimit" (change)="draw('checkbox')">Anschlusslimit anzeigen</mat-checkbox>
                <mat-checkbox [disabled]="combined" [(ngModel)]="showEvses" (change)="draw('checkbox')">Ladepunkte anzeigen</mat-checkbox>
            </div>
            <div class="group">
                <mat-checkbox [disabled]="!combined" [(ngModel)]="showPv" (change)="draw('checkbox')">Solar anzeigen</mat-checkbox>
                <mat-checkbox [disabled]="!combined" [(ngModel)]="pvInBackground" (change)="updateSolar()">Solar im Hintergrund</mat-checkbox>
                <mat-checkbox [disabled]="combined" [(ngModel)]="showDetails" (change)="draw('checkbox')">Begründung anzeigen</mat-checkbox>
            </div>
            <div class="group">
                <mat-checkbox [disabled]="!combined" [(ngModel)]="showLoads" (change)="draw('checkbox')">Gebäudelast anzeigen</mat-checkbox>
                <mat-checkbox [disabled]="!combined" [(ngModel)]="loadInBackground" (change)="updateLoad()">Gebäudelast im Hintergrund</mat-checkbox>
                <mat-checkbox [(ngModel)]="showEnergyPrice" (change)="draw('checkbox')">Energiepreis anzeigen</mat-checkbox>
            </div>
            <div class="d-inline-flex flex-row justify-content-end align-items-center me-2">
                <button *ngIf="authService.isDeveloper" class="messages-icon" mat-icon-button (click)="toggleMessages()" matTooltip="Entwicklerdetails anzeigen">
                    <mat-icon>bug_report</mat-icon>
                </button>
                <app-graph-help-icon class="m-0" [options]="['controlZoom', 'leftClickMove', 'doubleLeftClickReset', 'mouseLinkOptions', 'rightClickZoom']" [vAlign]="'middle'"></app-graph-help-icon>
            </div>
        </div>
        <div *ngIf="chargePlanReasonBoxShown" class="chargeplan-reason" [class.chargeplan-reason-left]="chargePlanReasonBoxLeft" [class.chargeplan-reason-right]="!chargePlanReasonBoxLeft">
            <ng-container *ngFor="let text of chargePlanReasonBoxText">
                {{text}}<br>
            </ng-container>
        </div>
    </div>
    <div class="chargeplan-body">
        <canvas [style.display]="showEnergyPrice ? 'block' : 'none'" [height]="100" [width]="canvasWidth" #energyPriceCanvasRef></canvas>
        <canvas (contextmenu)="$event.preventDefault()" (mouseleave)="mouseMove($event)" (mousemove)="mouseMove($event); mouseDrag($event)" (click)="highlightHelp(); $event.preventDefault();" (mouseup)="mouseUp($event)" (wheel)="wheelEvent($event)" (dblclick)="resetZoom($event)" (mousedown)="mouseDown($event)" [width]="canvasWidth" [height]="canvasHeight" #canvasRef></canvas>
        <div class="messages" *ngIf="messagesVisible">
            <hr>
            <p *ngFor="let warn of plan?.warnings">{{warn}}</p>
            <p *ngFor="let msg of plan?.messages">{{msg}}</p>
        </div>
    </div>
</div>
