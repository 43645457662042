<div class="d-flex flex-column">
    <mat-dialog-content>
        <div class="dialog-head">
            <h2 class="mat-h2">Tabelle umstellen</h2>
            <mat-icon class="close-icon" (click)="onNoClick()">close</mat-icon>
        </div>
        <div
            cdkDropList
            [cdkDropListData]="data.columns"
            class="columns"
            (cdkDropListDropped)="drop($event)">
            @for (col of data.columns; track col) {
                <div [ngClass]="data.invisible.get(col)? 'invisible-box': 'visible-box'" class="column-box" cdkDrag [cdkDragDisabled]="data.columnNames.get(col)===undefined">
                    @if(data.columnNames.get(col) !== undefined) {
                        <mat-icon>drag_handle</mat-icon>
                    }
                    {{data.columnNames.get(col)}}

                    @if(data.columnNames.get(col)!==undefined) {
                        <button mat-icon-button [matTooltip]="'Spalte ausblenden'"
                                (click)="toggleVisibility(col)">
                            @if(data.invisible.get(col)) {
                                <i class="fa-regular fa-eye-slash"></i>
                            } @else {
                                <i class="fa-regular fa-eye"></i>
                            }
                        </button>
                    }
                </div>
            }
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons d-flex flex-row justify-content-end gap-3">
        <button
            mat-mini-fab color="white"
            matTooltip="Konfiguration zurücksetzen"
            (click)="reset()"
        >
            <mat-icon>refresh</mat-icon>
        </button>
        <button
            mat-raised-button color="primary"
            type="submit"
            [mat-dialog-close]="filters()"
            cdkFokusInitial
        >
            Speichern
        </button>
        <button
            mat-raised-button color="accent"
            type="submit"
            (click)="onNoClick()"
        >
            Verwerfen
        </button>
    </mat-dialog-actions>
</div>
