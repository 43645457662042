import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractTableComponent} from "../../../../../shared/components/tables/AbstractTableComponent";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {DatabaseCheck, DatabaseCheckList} from "@io-elon-common/frontend-api";
import {DatabaseCheckService} from "../../services/database-check.service";

@Component({
  selector: 'app-database-check-table',
  templateUrl: './database-check-table.component.html',
  styleUrl: './database-check-table.component.scss'
})
export class DatabaseCheckTableComponent extends AbstractTableComponent implements OnInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() public checks!: DatabaseCheckList;

    displayedColumns: string[] = ['id', "name",'sql', 'period', 'fails', 'actions'];
    checkMatTableDataSource = new MatTableDataSource<DatabaseCheck>([]);

    constructor(
        private readonly checkService: DatabaseCheckService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.checkMatTableDataSource = new MatTableDataSource(this.checks.list);
        this.checkMatTableDataSource.paginator = this.paginator;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.checkMatTableDataSource.data = this.checks.list;
    }

    public async edit(check: DatabaseCheck): Promise<void> {
        await this.checkService.showEditDialog(check);
    }

    public async delete(check: DatabaseCheck): Promise<void> {
        await this.checkService.showDeleteDialog(check, {});
    }
}
