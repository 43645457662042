import {Component, OnInit} from '@angular/core';
import {GatewayList} from '@io-elon-common/frontend-api';
import {GatewayService} from '../../service/gateway.service';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
  selector: 'app-gateway-list-view',
  templateUrl: './gateway-list-view.component.html',
  styleUrl: './gateway-list-view.component.scss'
})
export class GatewayListViewComponent implements OnInit {
    public gateways!: BehaviorSubject<GatewayList | undefined>;
    public isDev = false;


    constructor(private readonly gatewaysService: GatewayService, private readonly authService: AuthService)
    {
    }

    public ngOnInit(): void {
        this.gateways = this.gatewaysService.getAll();
        this.isDev = this.authService.isDeveloper();
    }

    public async handleNewGateway(): Promise<void> {
        await this.gatewaysService.showNewDialog(undefined);
    }

}
