import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {Gateway} from '@io-elon-common/frontend-api';
import {GatewayService} from '../../service/gateway.service';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
  selector: 'app-gateway-table',
  templateUrl: './gateway-table.component.html',
  styleUrl: './gateway-table.component.scss'
})
export class GatewayTableComponent extends AbstractTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @Input()
    public gateways!: Gateway[];
    public displayedColumns: string[] = [];
    public dataSource = new MatTableDataSource<Gateway>([]);
    public canViewRegisters = false;
    public isDev = false;

    constructor(private readonly gatewaysService: GatewayService, private readonly authService: AuthService) {
        super();
    }

    public ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.gateways);
        this.canViewRegisters = this.authService.hasGlobalPermission("VIEW_GATEWAY_REGISTERS");
        this.isDev = this.authService.isDeveloper();
        this.displayedColumns = this.isDev ? ["id", "gatewayAddress", "name", "lastContact", "actions"]: ["id", "name", "lastContact", "actions"]
    }

    public ngAfterViewInit() {
        // tslint:disable-next-line:no-non-null-assertion
        this.dataSource.paginator = this.paginator!;
    }

    public trackBy(idx: number, item: Gateway) {
        return item.id;
    }

    public async edit(gateway: Gateway): Promise<void> {
        await this.gatewaysService.showEditDialog(gateway, {});
    }

    public async delete(gateway: Gateway): Promise<void> {
        await this.gatewaysService.showDeleteDialog(gateway, {});
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.gateways;
    }

    public showLog(gateway: Gateway)  {
        if(gateway) {
            this.gatewaysService.showLog(gateway, this.gatewaysService.getLiveLogs(gateway.id))
        }
    }
}
