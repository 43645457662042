import {Component, Input, OnInit} from '@angular/core';
import {ExecuteBasisUpdate} from '@io-elon-common/frontend-api';
import {FormControl, FormGroup} from '@angular/forms';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';

@Component({
    selector: 'app-edit-basis-dialog',
    templateUrl: './edit-basis-dialog.component.html',
    styleUrls: ['./edit-basis-dialog.component.scss']
})
export class EditBasisDialogComponent implements OnInit, IEditForm<ExecuteBasisUpdate> {
    public formGroup!: FormGroup<{
        name: FormControl<string | null>
        lat: FormControl<number | null>
        lon: FormControl<number | null>
        icon: FormControl<string | null | any>
        energyPrice: FormControl<number | null>
        energyExportPrice: FormControl<number | null>
        geoFenceRadius: FormControl<number | null>
        dynamicElectricityCostsEnabled: FormControl<boolean | null>
        konzessionsabgabe: FormControl<number | null>
        netzentgelt: FormControl<number | null>
        allowContinue  : FormControl<boolean | null>
    }>;

    @Input()
    public data!: ExecuteBasisUpdate;

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            name: new FormControl(this.data.name),
            lat: new FormControl(this.data.lat),
            lon: new FormControl(this.data.lon),
            icon: new FormControl<string | null | any>(this.data.icon),
            energyPrice: new FormControl(this.data.energyPrice),
            energyExportPrice: new FormControl(this.data.energyExportPrice),
            geoFenceRadius: new FormControl(this.data.geofenceRadius),
            dynamicElectricityCostsEnabled: new FormControl(this.data.dynamicElectricityCostsEnabled),
            konzessionsabgabe: new FormControl(this.data.konzessionsabgabe),
            netzentgelt: new FormControl(this.data.netzentgelt),
            allowContinue: new FormControl(this.data.allowContinue),
        });
        this.formGroup.valueChanges.subscribe(change => {
            this.data.name = change.name!;
            this.data.lat = change.lat!;
            this.data.lon = change.lon!;
            this.data.energyPrice = change.energyPrice!;
            this.data.energyExportPrice = change.energyExportPrice!;
            this.data.geofenceRadius = change.geoFenceRadius!;
            this.data.dynamicElectricityCostsEnabled = change.dynamicElectricityCostsEnabled!;
            this.data.konzessionsabgabe = change.konzessionsabgabe!;
            this.data.netzentgelt = change.netzentgelt!;
            this.data.allowContinue = change.allowContinue!;
            const selectedFiles = change.icon?.files;
            if(selectedFiles && selectedFiles[0]){
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    this.data.icon = reader.result as string;
                }, false);
                reader.readAsDataURL(selectedFiles[0]);
            }

            this.data.icon = change.icon;
        })
    }

    validate(): string[] {
        const ret: string[] = [];
        if (this.data.name === "") {
            ret.push("Name ist leer")
        }
        return ret;
    }

    public isImageAvailable(): boolean {
        return this.data.icon !== undefined && this.data.icon !== "";
    }
}
