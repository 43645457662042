import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {MatChipListboxChange} from '@angular/material/chips';
import {FormControl} from '@angular/forms';

export interface DateRange {
    start: Moment,
    end: Moment
}
export enum TimeRangePreset {
    week = "week",
    month = "month",
    year = 'year',
}

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrl: './date-selection.component.scss'
})
export class DateSelectionComponent implements OnInit {
    @Output() updateRangeEvent = new EventEmitter();
    public selectedDate: Moment = moment();
    public selectedDateRange: TimeRangePreset = TimeRangePreset.week;
    TimeRangePreset = TimeRangePreset;

    public selectedDateRangeFormControl = new FormControl();

    ngOnInit(): void {
        this.updateRangeEvent.emit(this.getWeek(this.selectedDate));
    }

    public goForward() {
        let range;
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                this.selectedDate.add(7, 'd');
                range = this.getWeek(this.selectedDate);
                break;
            case TimeRangePreset.month:
                this.selectedDate.add(1, 'M');
                range = this.getMonth(this.selectedDate);
                break;
            case TimeRangePreset.year:
                this.selectedDate.add(1, 'y');
                range = this.getYear(this.selectedDate);
                break;
        }

        this.updateRangeEvent.emit(range);
    }

    public goBackward() {
        let range;
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                this.selectedDate.subtract(7, 'd');
                range = this.getWeek(this.selectedDate);
                break;
            case TimeRangePreset.month:
                this.selectedDate.subtract(1, 'M');
                range = this.getMonth(this.selectedDate);
                break;
            case TimeRangePreset.year:
                this.selectedDate.subtract(1, 'y');
                range = this.getYear(this.selectedDate);
                break;
        }

        this.updateRangeEvent.emit(range);
    }

    public goToday() {
        this.selectedDate = moment();
        this.updateRangeEvent.emit(this.getRange(this.selectedDate));
    }

    public selectionChanged(selection: MatChipListboxChange) {
        if (!selection.value) {
            this.selectedDateRangeFormControl.patchValue(this.selectedDateRange);
            return;
        }
        if (this.isTimeRangeIncreased(this.selectedDateRange, selection.value)) {
            this.selectedDateRange = selection.value;
            this.gotToMostCurrentInTimeRange();
        } else {
            this.selectedDateRange = selection.value;
            this.updateRangeEvent.emit(this.getRange(this.selectedDate));
        }
    }

    public getHumanReadable() :string {
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                const range = this.getWeek(this.selectedDate);
                let format = range.start.month() !== range.end.month()? "MMM" : '';
                format += range.start.year() !== range.end.year()? " YYYY" : '';
                return range.start.locale("de").format("K\\W WW, DD " + format) + " - " + range.end.locale("de").format("DD MMM YYYY");
            case TimeRangePreset.month:
                return this.selectedDate.locale("de").format("MMMM YYYY");
            case TimeRangePreset.year:
                return this.selectedDate.locale("de").format("YYYY");
        }
    }

    public shouldForwardAvailable(): boolean {
        let range = this.getRange(this.selectedDate);
        return range.end.valueOf() > moment().valueOf();
    }

    private getMonth(c: Moment): DateRange {
        return {
            start: c.clone().startOf('M'),
            end: c.clone().endOf('M')
        };
    }

    private getYear(c: Moment): DateRange {
        return {
            start: c.clone().startOf('y'),
            end: c.clone().endOf('y')
        };
    }

    private getWeek(c: Moment): DateRange {
        if (c.day() === 0) {
            c.subtract(1, 'd');
        }

        return {
            start: c.clone().startOf('isoWeek'),
            end: c.clone().endOf('isoWeek'),
        };
    }

    private isTimeRangeIncreased(currentValue: TimeRangePreset, previousValue: TimeRangePreset): boolean {
        if (currentValue === TimeRangePreset.year && (previousValue === TimeRangePreset.month || previousValue === TimeRangePreset.week)) {
            return true;
        } else return currentValue === TimeRangePreset.month && previousValue === TimeRangePreset.week;
    }

    private gotToMostCurrentInTimeRange() {
        const m = this.selectedDate.isAfter(moment()) ? moment() : this.selectedDate;
        this.selectedDate = m;
        this.updateRangeEvent.emit(this.getRange(m));
    }

    private getRange(c: Moment): DateRange {
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                return this.getWeek(c);
            case TimeRangePreset.month:
                return this.getMonth(c);
            case TimeRangePreset.year:
                return this.getYear(c);
        }
    }
}
