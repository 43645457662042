import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {BehaviorSubject} from "rxjs";
import {WebfleetAccountList} from "@io-elon-common/frontend-api";
import {AuthService} from "../../../shared/guards/auth.service";
import {WebfleetAccountService} from "../service/webfleet-account.service";
import {WebfleetAccountTableComponent} from "../webfleet-account-table/webfleet-account-table.component";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-webfleet-account-list',
  standalone: true,
    imports: [
        AsyncPipe,
        MatButton,
        NgIf,
        WebfleetAccountTableComponent,
        MatIcon
    ],
  templateUrl: './webfleet-account-list.component.html',
  styleUrl: './webfleet-account-list.component.scss'
})
export class WebfleetAccountListComponent implements OnInit {
    public accounts!: BehaviorSubject<WebfleetAccountList | undefined>

    public canAdd = false;
    public canSync = false;

    constructor(
        private readonly webfleetAccountService: WebfleetAccountService,
        private readonly authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.accounts = this.webfleetAccountService.getAll();
        console.log(this.accounts)
        this.canAdd = this.authService.hasGlobalPermission("ADD_WEBFLEET_ACCOUNTS");
        this.canSync = this.authService.isDeveloper();
    }

    public async createAccount(): Promise<void> {
        await this.webfleetAccountService.showNewDialog();
    }

    public async synchronizeAccounts(): Promise<void> {
        await this.webfleetAccountService.synchronizeAccounts();
    }
}
