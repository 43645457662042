import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {DatabaseCheckList} from "@io-elon-common/frontend-api";
import {DatabaseCheckService} from "../../services/database-check.service";

@Component({
    selector: 'app-database-check-view',
    templateUrl: './database-check-view.component.html',
    styleUrls: ['./database-check-view.component.scss']
})
export class DatabaseCheckViewComponent implements OnInit {

    public checks!: BehaviorSubject<DatabaseCheckList | undefined>;

    constructor(
        private readonly databaseCheckService: DatabaseCheckService
    ) {
    }

    ngOnInit(): void {
        this.checks = this.databaseCheckService.getAll();
    }

    public async handleNewCheck(): Promise<void> {
        await this.databaseCheckService.showNewDialog(undefined);
    }

    public async handleLog(): Promise<void> {
        this.databaseCheckService.showLog();
    }
}
