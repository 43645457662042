import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from "../../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {ExecuteDatabaseCheckAddOrUpdate} from "@io-elon-common/frontend-api";

@Component({
  selector: 'app-edit-database-check',
  templateUrl: './edit-database-check.component.html',
  styleUrl: './edit-database-check.component.scss'
})
export class EditDatabaseCheckComponent implements IEditForm<ExecuteDatabaseCheckAddOrUpdate>, OnInit {
    @Input()
    public data!: ExecuteDatabaseCheckAddOrUpdate;

    validate(): string[] | Promise<string[]> {
        return [];
    }

    ngOnInit(): void {
    }

}
