import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    ChargingSessionList,
    EvseList, Fleet,
    FleetOverview,
    ReservationInstance,
    VehicleTeaser
} from '@io-elon-common/frontend-api';
import {ActivatedRoute} from '@angular/router';
import {FleetService} from '../../service/fleet.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {VehicleService} from '../../service/vehicle.service';
import {map} from 'rxjs/operators';
import {CompareFn, createByFieldsComparator} from '../../../../shared/helper/comparatorsFactory';
import {localStorageGet} from '../../../../shared/helper/typed-local-storage';
import {EvseService} from '../../../evse/service/evse.service';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
    selector: 'app-vehicle-list-view',
    templateUrl: './vehicle-list-view.component.html',
    styleUrls: ['./vehicle-list-view.component.scss']
})
export class VehicleListViewComponent implements OnInit, OnDestroy {
    private selectedFleetSubscription!: Subscription;
    private fleetId!: number;
    public error = '';
    public fleetOverview!: Observable<FleetOverview | undefined>;
    public fleetReservations!: BehaviorSubject<ReservationInstance[] | undefined>;
    public evses!: BehaviorSubject<EvseList | undefined>;
    public activeChargingSessions!: BehaviorSubject<ChargingSessionList | undefined>;
    public isDev = false;
    public fleet: BehaviorSubject<Fleet | undefined> = new BehaviorSubject<Fleet | undefined>(undefined);

    constructor(
        private readonly vehicleService: VehicleService,
        private readonly fleetService: FleetService,
        private readonly route: ActivatedRoute,
        private readonly evseService: EvseService,
        private readonly authService: AuthService
    ) {
        this.isDev = this.authService.isDeveloper();
    }

    public async ngOnInit() {
        this.evses = this.evseService.getAll();
        const fleetId = parseInt(this.route.snapshot.paramMap.get('fleetId') as string, 10);
        if (!Number.isNaN(fleetId)) {
            this.fleetService.setSelectedFleet(fleetId);
        }

        this.selectedFleetSubscription = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetId = id;
                this.error = '';
                this.updateFleetId(id);
            } else {
                this.error = 'Es wurde keine Flotte ausgewählt.';
            }
        });
    }

    ngOnDestroy() {
        this.selectedFleetSubscription.unsubscribe();
    }

    private updateFleetId(id: number) {
        this.activeChargingSessions = this.fleetService.getActivChargingSessions(id);
        this.fleetReservations = this.fleetService.getReservationsUpcoming(id);
        this.fleetOverview = this.fleetService.getOverview(id).asObservable().pipe(
            map(data => {
                if(!data) {
                    return data;
                } else {
                    const compareFn: CompareFn<VehicleTeaser> = createByFieldsComparator([localStorageGet("VEHICLE_DEFAULT_SORT_ORDER", "name"), "id"]);
                    data.vehicles.sort(compareFn);
                    return data;
                }
            })
        );
        this.fleet = this.fleetService.get(id);
    }

    public async newVehicle(): Promise<void> {
        await this.vehicleService.showNewDialog(this.fleetId);
    }
}
