import {Component, OnInit} from '@angular/core';
import {TeslaAccountService} from '../../service/tesla-account.service';
import {BehaviorSubject} from 'rxjs';
import {TeslaAccountList, TeslaAccountVehicleList} from '@io-elon-common/frontend-api';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
    selector: 'app-tesla-accounts-list-view',
    templateUrl: './tesla-accounts-list-view.component.html',
    styleUrls: ['./tesla-accounts-list-view.component.scss']
})
export class TeslaAccountsListViewComponent implements OnInit {

    public accounts!: BehaviorSubject<TeslaAccountList | undefined>
    public vehicleLists!: Promise<{ [key: string]: TeslaAccountVehicleList }>
    public canAdd = false;

    constructor(
        private readonly teslaAccountService: TeslaAccountService,
        private readonly authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.accounts = this.teslaAccountService.getAll();
        this.vehicleLists = this.teslaAccountService.getVehicleLists();
        this.canAdd = this.authService.hasGlobalPermission("ADD_TESLA_DATASOURCES");
    }

    public async createAccount(): Promise<void> {
        await this.teslaAccountService.showNewDialog();
    }

}
