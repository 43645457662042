<div class="d-flex flex-column">
    <div class="d-flex flex-row gap-3">
        <mat-form-field class="form-field">
            <mat-label>Geräte Typ</mat-label>
            <mat-select [(ngModel)]="data.deviceType" (selectionChange)="changeDeviceType($event)" matTooltip="Bitte wählen Sie den gewünschten Gerätetyp aus, für den eine Modbus-Server-Verbindung erstellt werden soll.">
                @for (deviceType of deviceTypes; track deviceType) {
                    <mat-option [value]="deviceType">{{deviceType | formatGatewayDeviceType}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <ng-container [ngSwitch]="data.deviceType">
            <div *ngSwitchCase="GatewayDeviceType.Evse">
                <mat-form-field class="form-field">
                    <mat-label>Ladepunkt</mat-label>
                    <mat-select [(ngModel)]="data.deviceId" matTooltip="Wählen Sie den gewüschnten Ladepunkt aus.">
                        @for (evse of availableEvses; track evse) {
                            <mat-option [value]="evse.id">{{ evse.name }}<span class="base-info mat-caption">{{evse.basis.name}}</span></mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="GatewayDeviceType.Vehicle">
                <mat-form-field class="form-field">
                    <mat-label>Fahrzeug</mat-label>
                    <mat-select [(ngModel)]="data.deviceId" matTooltip="Wählen Sie das gewüschnten Fahrzeug aus.">
                        @for (vehicle of availableVehicles; track vehicle) {
                            <mat-option [value]="vehicle.id">{{ vehicle.name }}<span class="base-info mat-caption">{{vehicle.fleet.base.name}}</span></mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="GatewayDeviceType.Meter">
                <mat-form-field class="form-field">
                    <mat-label>Zähler</mat-label>
                    <mat-select [(ngModel)]="data.deviceId" matTooltip="Wählen Sie den Zähler aus.">
                        @for (meter of availableMeters; track meter) {
                            <mat-option [value]="meter.id">{{ meter.name }}<span class="base-info mat-caption">{{meter.basis?.name}}</span></mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="GatewayDeviceType.PowerSupply">
                <mat-form-field class="form-field">
                    <mat-label>Anschluss</mat-label>
                    <mat-select [(ngModel)]="data.deviceId" matTooltip="Wählen Sie den Anschluss aus.">
                        @for (powerSupply of availablePowerSupplies; track powerSupply) {
                            <mat-option [value]="powerSupply.id">{{ powerSupply.name }}<span class="base-info mat-caption">{{getBasisName(powerSupply.baseId)}}</span></mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
        <mat-form-field *ngIf="compatibleSpec != undefined" class="form-field">
            <mat-label>Feld</mat-label>
            <mat-select [(ngModel)]="data.field" (selectionChange)="onFieldChange($event)" matTooltip="Wählen Sie das gewünschte Feld des Geräts, auf das Sie über den Modbus-Server zugreifen möchten.">
                @for (spec of compatibleSpec.fieldsSpec | keyvalue; track spec) {
                    <mat-option [value]="spec.key">{{spec.value.displayName}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="form-field">
            <mat-label>Modbus Device ID</mat-label>
            <input matInput type="number" min="1" max="255" [formControl]="ctrlModbusDeviceId" matTooltip="Unter welcher Modbus-Device-ID (unitId) soll dieses Gerät erreichbar sein?">
            <mat-error *ngIf="ctrlModbusDeviceId.errors?.required as error">{{error}}</mat-error>
            <mat-error *ngIf="ctrlModbusDeviceId.errors?.min as error">Der Wert darf nicht kleiner sein als {{ctrlModbusDeviceId.errors?.min.min}}</mat-error>
            <mat-error *ngIf="ctrlModbusDeviceId.errors?.max as error">Der Wert darf nicht größer sein als {{ctrlModbusDeviceId.errors?.max.max}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="form-field" >
            <mat-label>Register Start Adresse</mat-label>
            <input matInput type="number" min="1" [formControl]="ctrlModbusStartRegister" matTooltip="Das Modbus-Register auf dem diser Wert gelesen/geschrieben werden soll. Bei Werten mit mehr als 16 Bit das erste Register angeben.">
            <mat-error *ngIf="ctrlModbusStartRegister.errors?.required as error">{{error}}</mat-error>
            <mat-error *ngIf="ctrlModbusStartRegister.errors?.min as error">Der Wert darf nicht kleiner sein als {{ctrlModbusStartRegister.errors?.min.min}}</mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
            <mat-label>Zugriffs Berechtigungen</mat-label>
            <mat-select [(ngModel)]="data.access" matTooltip="Welche Zugriffsberechtigungen möchten Sie für das Feld festlegen?">
                <mat-option value="RO">Lesen</mat-option>
                <mat-option value="WO">Schreiben</mat-option>
                <mat-option value="RW">Lesen/Schreiben</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field class="select-filter">
            <mat-label>Daten Typ Filter</mat-label>
            <mat-select multiple [(ngModel)]="filter" matTooltip="Welche Datentypen möchten Sie in der Auswahl angezeigt bekommen?">
                <mat-optgroup>Register Typen</mat-optgroup>
                @for (registerType of getPossibleRegisterTypes(); track registerType) {
                    <mat-option [value]="registerType">{{registerType}}</mat-option>
                }
                <mat-optgroup>Daten Typen</mat-optgroup>
                @for (datatype of getPossibleDataTypes(); track datatype) {
                    <mat-option [value]="datatype">{{datatype}}</mat-option>
                }
                <mat-optgroup>Byte Order</mat-optgroup>
                @for (byteOrder of getPossibleByteOrder(); track byteOrder) {
                    <mat-option [value]="byteOrder">{{byteOrder}}</mat-option>
                }
                <mat-optgroup>Anzahl der Worte</mat-optgroup>
                @for (wordLength of getPossibleWordLength(); track wordLength) {
                    <mat-option [value]="wordLength">{{wordLength}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="compatibleModbusDataTypes != undefined" class="data-type-select">
            <mat-label>Daten Typen</mat-label>
            <mat-select (selectionChange)="onDataTypeChange($event)" [(ngModel)]="selectedModbusDataType" matTooltip="Als welcher Datentyp sollen diese Informationen übertragen werden? (Registertyp, Datentyp, Byte-Reihenfolge, Anzahl der Wörter).">
                @for (dataType of filterModbusDataTypes(); track dataType) {
                    <mat-option [value]="dataType">{{dataType.display}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>
