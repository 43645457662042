import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Gateway, GatewayRegister} from '@io-elon-common/frontend-api';
import {GatewayRegisterService} from '../../service/gateway-register.service';
import {AuthService} from '../../../../../shared/guards/auth.service';

@Component({
  selector: 'app-gateway-register-list-view',
  templateUrl: './gateway-register-list-view.component.html',
  styleUrl: './gateway-register-list-view.component.scss'
})
export class GatewayRegisterListViewComponent implements OnInit {
    public gatewayRegisters!: BehaviorSubject<GatewayRegister[] | undefined>;
    @Input()
    public gateway!: Gateway
    public canCreate = false;

    constructor(private readonly gatewaysRegisterService: GatewayRegisterService, private readonly authService: AuthService)
    {
    }

    public ngOnInit(): void {
        this.gatewayRegisters = this.gatewaysRegisterService.getRegistersOfGateway(this.gateway.id);
        this.canCreate = this.authService.hasGlobalPermission("ADD_GATEWAY_REGISTERS")
    }

    public async handleNewRegister(): Promise<void> {
        await this.gatewaysRegisterService.showNewDialog({
            gatewayId: this.gateway.id
        });
    }

}
