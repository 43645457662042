import {Component, Input, OnInit} from '@angular/core';
import {ExecuteSolarSystemAddOrUpdate, Meter, PowerSupply} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {AuthService} from "../../../../shared/guards/auth.service";

@Component({
    selector: 'app-edit-solar-panels-dialog',
    templateUrl: './edit-solar-system-dialog.component.html',
    styleUrls: ['./edit-solar-system-dialog.component.scss']
})
export class EditSolarSystemDialogComponent implements IEditForm<ExecuteSolarSystemAddOrUpdate>, OnInit{

    @Input()
    public possiblePowerSupplies!: PowerSupply[];
    @Input()
    public possibleMeters!: Meter[];
    @Input()
    public data!: ExecuteSolarSystemAddOrUpdate;
    public isDev = false;

    public constructor(
        private readonly authService: AuthService
    ){
    }

    ngOnInit(): void {
        this.isDev = this.authService.isDeveloper();
    }
    validate(): string[] {
        const errors: string[] = [];
        if(!(this.data.l1||this.data.l2||this.data.l3)){
            errors.push("Bitte auswählen an welche Phase(n) die Solaranlage angeschlossen ist!")
        }
        return errors;
    }
}
