<div *ngIf="(users | async) as users; else loading" class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Ausgegeben an</mat-label>
            <mat-select [(value)]="data.currentLocationUserId">
                <mat-option [value]="null">Fahrer ohne Account</mat-option>
                <mat-option *ngFor="let u of users.list" [value]="u.id">{{u | fullName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Neuer Standort</mat-label>
            <input matInput [(ngModel)]="data.currentLocation">
        </mat-form-field>
    </div>
    <app-date-time-form [(dateTime)]="data.giveOutTime" label="Ausgabezeitpunkt"></app-date-time-form>
    <mat-checkbox [(ngModel)]="hasReturnTime">Rückgabezeit angeben</mat-checkbox>
    <app-date-time-form *ngIf="hasReturnTime" [(dateTime)]="data.expectedReturnTime" label="Erwartete Rückgabe"></app-date-time-form>
</div>
<ng-template #loading>Loading...</ng-template>
