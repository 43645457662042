<div class="d-flex flex-row justify-content-between gap-3 align-items-start">
    <mat-form-field appearance="fill" class="flex-grow-1">
        <mat-select (selectionChange)="onAccountChanged($event)" [(ngModel)]="connection.accountId">

            <mat-option *ngFor="let account of (webfleetAccounts | async)?.list" [value]="account.id">
                {{ account.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="flex-grow-1">
        <mat-select [(ngModel)]="connection.webfleetId">
            <mat-option *ngFor="let vehicle of vehicles | async" [value]="vehicle.id" [disabled]="vehicle.isUsed">
                {{ vehicle.identnumber }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<button [disabled]="connection.accountId === -1" (click)="updateWebfleetVehicleList()" mat-raised-button color="primary"
        matTooltip="Aktualisieren der Fahrzeugliste des Ausgewählten Accounts. Dies nimmt einige Sekunden in Anspruch.">
    <mat-icon fontSet="material-icons">sync</mat-icon>Fahrzeugliste aktualisieren
</button>
