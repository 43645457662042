<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-vin">Name</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="baseUrl">
        <th mat-header-cell *matHeaderCellDef class="col-vin">Basis-URL</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.baseUrl}}
        </td>
    </ng-container>

    <ng-container matColumnDef="cpi">
        <th mat-header-cell *matHeaderCellDef class="col-vin">CPI</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.cpi}}
        </td>
    </ng-container>

    <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef class="col-vin">Benutzername</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.userName}}
        </td>
    </ng-container>

    <ng-container matColumnDef="pass">
        <th mat-header-cell *matHeaderCellDef class="col-vin">Passwort</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            <div *ngIf="getPw(element) === undefined">
                <mat-icon (click)="loadPw(element)" class="loadPwIcon">remove_red_eye</mat-icon>
            </div>
            <div *ngIf="getPw(element) === null">Laden...</div>
            <div *ngIf="getPw(element)">
                <mat-icon (click)="removePw(element)" class="loadPwIcon">remove_red_eye</mat-icon>
                {{getPw(element)}}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="ssl">
        <th mat-header-cell *matHeaderCellDef class="col-vin">SSL</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.ssl}}
        </td>
    </ng-container>

    <ng-container matColumnDef="defToken">
        <th mat-header-cell *matHeaderCellDef class="col-vin">Standard RFID-Token</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.defToken}}
        </td>
    </ng-container>

    <ng-container matColumnDef="evses">
        <th mat-header-cell *matHeaderCellDef> Ladepunkte</th>
        <td mat-cell *matCellDef="let element" class="col-connectedEvse">
            <ng-container *ngIf="element.connectedEvse.length > 0; else noEvseConnected">
                <mat-expansion-panel (opened)="expandableOpen(element.id)"
                                     (closed)="expandableClose(element.id)"
                                     [expanded]="isExpandableOpened(element.id)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>
                                {{getNumberOfEvseConnectedText(element)}}
                            </span>
                            <span *ngIf="getEvseAlertCount(element) > 0" [matTooltipClass]="'pre-tooltip'" [matTooltip]=" getUplinkNotificationText(element)" class="uplink-notification-span">
                                <mat-icon class="uplink-notification">info</mat-icon>
                            </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <ng-container *ngFor="let evse of element.connectedEvse">
                            <div>
                                <app-evse-link [evse]="evse" [class]="getEvseLinkClass(evse.id)"></app-evse-link>
                                <button (click)="disconnectConnection(evse.id)" mat-icon-button matTooltip="OCPP Verbindung trennen" class="evse-notification"><mat-icon>cloud_off</mat-icon></button>
                                <span *ngFor="let notification of activeNotifications; trackBy:trackById" class="evse-notification">
                                    <span *ngIf="notification.evse === evse.id">
                                        <app-notification [notification]="notification" [headerName]="evse.name"></app-notification>
                                    </span>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </ng-container>
            <ng-template #noEvseConnected>
                <div class="no-data-msg">
                    Keine
                </div>
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions">
            <button mat-icon-button
                    matTooltip="Tablle bearbeiten"
                    (click)="reorder()">
                <i class="fa-solid fa-edit iblack scale-0x8"></i>
            </button>
        </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!isDeletable(element)" (click)="delete(element)" mat-icon-button [matTooltip]="'Löschen' + getExtraDeleteTooltipText(element)"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
               [pageSizeOptions]="[10, 25, 50, 100, 250]" showFirstLastButtons>
</mat-paginator>
