import {Component, Input, OnInit} from '@angular/core';
import {ExecuteOcppUplinkAddOrUpdate, OcppUplink} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {AuthService} from '../../../../shared/guards/auth.service';
import {OcppUplinkService} from '../../service/ocpp-uplink.service';
import {DialogType} from '../../../../shared/components/help-box/dialogType';

@Component({
    selector: 'app-edit-ocpp-uplink-dialog',
    templateUrl: './edit-ocpp-uplink-dialog.component.html',
    styleUrls: ['./edit-ocpp-uplink-dialog.component.scss']
})
export class EditOcppUplinkDialogComponent implements OnInit, IEditForm<ExecuteOcppUplinkAddOrUpdate>{

    @Input()
    public data!: ExecuteOcppUplinkAddOrUpdate;
    @Input()
    public ocppUplinkId!: number;
    public isDev: boolean;
    public setCpi: boolean = false;
    public readonly DialogType = DialogType;

    private ocppUplinks: OcppUplink[] = [];
    constructor(
        private readonly authService: AuthService,
        private readonly ocppUplinkService: OcppUplinkService,

    ) {
        this.isDev = this.authService.isDeveloper();
    }

    async ngOnInit(): Promise<void> {
        this.setCpi = !!this.data.cpi;
        this.ocppUplinks = await this.ocppUplinkService.getAllPromise().then(list => list.list);
    }

    validate(): string[] {
        const ret: string[] = [];
        if(!this.data.baseUrl || this.data.baseUrl.trim().length === 0) {
            ret.push("Basis-URL ist leer<br/>")
        } else {
            this.data.baseUrl = this.data.baseUrl.trim();
        }

        if (!this.data.name || this.data.name.trim().length === 0) {
            ret.push("Name ist leer<br/>")
        } else {
            this.data.name = this.data.name.trim();
            if (this.ocppUplinkId === undefined || this.ocppUplinkId === -1) {
                const uplink = this.ocppUplinks.find(uplink => uplink.name.trim().toUpperCase() === this.data.name.toUpperCase());
                if (uplink) {
                    ret.push("Der gleiche Name existiert bereits<br/>")
                }
            } else {
                const uplink = this.ocppUplinks.find(uplink => uplink.name.trim().toUpperCase() === this.data.name.toUpperCase() && uplink.id !== this.ocppUplinkId);
                if (uplink) {
                    ret.push("Der gleiche Name existiert bereits<br/>")
                }
            }
        }
        return ret;
    }
}
