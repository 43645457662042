import {Component, OnInit} from '@angular/core';
import {VehicleTypeService} from '../../services/vehicle-type.service';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {VehicleTypeDetailsItem} from '@io-elon-common/frontend-api/lib/model/vehicleTypeDetailsItem';

@Component({
    selector: 'app-vehicle-types-tree',
    templateUrl: './vehicle-types-table.component.html',
    styleUrl: './vehicle-types-table.component.scss'
})
export class VehicleTypesTableComponent implements OnInit {

    public columnDefinitions = [
        {def: 'manufacturer', hide: false},
        {def: 'modelName', hide: false},
        {def: 'vehicleClass', hide: false},
        {def: 'maxDcPower', hide: false},
        {def: 'minDcPower', hide: false},
        {def: 'maxAcAmpsI1', hide: false},
        {def: 'maxAcAmpsI2', hide: false},
        {def: 'maxAcAmpsI3', hide: false},
        {def: 'minAcAmpsI1', hide: false},
        {def: 'minAcAmpsI2', hide: false},
        {def: 'minAcAmpsI3', hide: false},
        {def: 'capacityBruttoNew', hide: false},
        {def: 'capacityNettoNew', hide: false},
        {def: 'minSocU', hide: false},
        {def: 'maxSocU', hide: false},
        {def: 'bestSocU', hide: false},
        {def: 'fullRange', hide: false},
        {def: 'imageId', hide: false},
        {def: 'socMap', hide: true},
    ];

    public dataSource = new MatTableDataSource<VehicleTypeDetailsItem>()

    public constructor(private readonly vehicleTypeService: VehicleTypeService) {
    }

    public getDisplayedColumns(): string[] {
        const columns = ['id'];
        this.columnDefinitions.filter(value => !value.hide).map(value => value.def).forEach(value => {
            columns.push(value);
        });
        return columns;
    }

    public async ngOnInit(): Promise<void> {
        const vehicleTypeDetails = (await this.vehicleTypeService.getVehicleTypesDetails()).list;
        this.dataSource = new MatTableDataSource(vehicleTypeDetails);
    }

    public sortData(sort: Sort) {
        if (!sort.active || sort.direction === '') {
            return;
        }
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'id':
                    return this.numberCompare(a.id, b.id, isAsc);
                case 'manufacturer':
                    return this.stringCompare(a.manufacturer, b.manufacturer, isAsc);
                case 'modelName':
                    return this.stringCompare(a.modelName, b.modelName, isAsc);
                case 'vehicleClass':
                    return this.stringCompare(a.vehicleClass, b.vehicleClass, isAsc);
                case 'maxDcPower':
                    return this.numberCompare(a.maxDcPower, b.maxDcPower, isAsc);
                case 'minDcPower':
                    return this.numberCompare(a.maxDcPower, b.maxDcPower, isAsc);
                case 'maxAcAmpsI1':
                    return this.numberCompare(a.maxAcAmpsI1, b.maxAcAmpsI1, isAsc);
                case 'maxAcAmpsI2':
                    return this.numberCompare(a.maxAcAmpsI2, b.maxAcAmpsI2, isAsc);
                case 'maxAcAmpsI3':
                    return this.numberCompare(a.maxAcAmpsI3, b.maxAcAmpsI3, isAsc);
                case 'minAcAmpsI1':
                    return this.numberCompare(a.minAcAmpsI1, b.minAcAmpsI1, isAsc);
                case 'minAcAmpsI2':
                    return this.numberCompare(a.minAcAmpsI2, b.minAcAmpsI2, isAsc);
                case 'minAcAmpsI3':
                    return this.numberCompare(a.minAcAmpsI3, b.minAcAmpsI3, isAsc);
                case 'capacityBruttoNew':
                    return this.numberCompare(a.capacityBruttoNew, b.capacityBruttoNew, isAsc);
                case 'capacityNettoNew':
                    return this.numberCompare(a.capacityNettoNew, b.capacityNettoNew, isAsc);
                case 'minSocU':
                    return this.numberCompare(a.minSocU, b.minSocU, isAsc);
                case 'maxSocU':
                    return this.numberCompare(a.maxSocU, b.maxSocU, isAsc);
                case 'fullRange':
                    return this.numberCompare(a.fullRange, b.fullRange, isAsc);
                case 'imageId':
                    return this.numberCompare(a.imageId, b.imageId, isAsc);
                default:
                    return 0;
            }
        });
    }

    public toggleColumn(name: string) {
        const column = this.columnDefinitions.find(value => value.def === name);
        if (column) {
            column.hide = !column?.hide;
        }
    }

    public isColumnVisible(name: string) {
        const column = this.columnDefinitions.find(value => value.def === name);
        return !column?.hide;
    }

    private numberCompare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean): number {
        if (!a) {
            return isAsc? -1 : 1;
        }
        if (!b) {
            return isAsc? 1 : -1;
        }
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private stringCompare(a: string | undefined, b: string | undefined, isAsc: boolean): number {
        if (!a) {
            return isAsc? -1 : 1;
        }
        if (!b) {
            return isAsc? 1 : -1;
        }
        return isAsc ? a.localeCompare(b) : b.localeCompare(a);
    }
}
