<div class="center" style="position: relative">
    <h2 class="center">Ereignisse</h2>
    <mat-select [(ngModel)]="level" (valueChange)="refresh()" style="position: absolute; top: 0; left: 0; width: 100px;">
        <mat-option *ngIf="isDev" [value]="500">Debuggen</mat-option>
        <mat-option [value]="400">Info</mat-option>
        <mat-option [value]="300">Warnung</mat-option>
        <mat-option [value]="200">Fehler</mat-option>
    </mat-select>
    <div style="position: absolute; right: 0; top: 0; margin: 0; padding: 0">
        <button mat-button  (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field appearance="fill" style="position:absolute;width:1px;visibility:hidden;">
            <mat-date-range-input [formGroup]="range" [rangePicker]="dateRangePicker" [max]="today">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-date-range-picker #dateRangePicker (closed)="refresh()"></mat-date-range-picker>
        </mat-form-field>
        <button mat-button (click)="dateRangePicker.open()" ><mat-icon>today</mat-icon></button>
    </div>
</div>

<table *ngIf="!loading; else loadingTemplate" mat-table [dataSource]="dataSource" class="full-width-table" matSort
       [trackBy]="trackById" (matSortChange)="setSort($event)"
       [matSortActive]="sort.active" [matSortDirection]="sort.direction" matSortDisableClear
>

    <!-- ID -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-icon">ID</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            {{element.id}}
        </td>
    </ng-container>

    <!-- Key -->
    <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-icon">Key</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            {{element.key}}
        </td>
    </ng-container>

    <!-- Zeitpunkt -->
    <ng-container matColumnDef="tst">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-name">Zeit</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            <span matTooltipPosition="right" [matTooltip]="element.tst | date:'mediumTime'">{{element.tst | date}} - {{element.tst | date:'shortTime'}}</span>
        </td>
    </ng-container>

    <!-- Message -->
    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-type">Meldung</th>
        <td mat-cell *matCellDef="let element" class="col-type">
            {{elementIdFormatter.format(element.message)}}
        </td>
    </ng-container>

    <!-- Level -->
    <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-vehicle">Level</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            <ng-container [ngSwitch]="element.level">
                <ng-container *ngSwitchCase="100">Fatal</ng-container>
                <ng-container *ngSwitchCase="200">Fehler</ng-container>
                <ng-container *ngSwitchCase="300">Warnung</ng-container>
                <ng-container *ngSwitchCase="400">Info</ng-container>
                <ng-container *ngSwitchCase="500">Debug</ng-container>
                <ng-container *ngSwitchDefault>{{element.level}}</ng-container>
            </ng-container>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="$event.stopPropagation(); $event ? selectRow(row) : null" [ngClass]="{selected: selectedIndex === row.id}">
    </tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
               [pageSizeOptions]="[10, 25, 50, 100, 250]" showFirstLastButtons>
</mat-paginator>

<ng-template #loadingTemplate>
    <div style="position: relative; height: 300px">
        <mat-spinner class="center-spinner"></mat-spinner>
    </div>
</ng-template>
