<div [formGroup]="formGroup" class="main-div d-flex flex-column gap-3">
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name des Standorts</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="formGroup.get('name')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Name der Flotte</mat-label>
            <input matInput formControlName="fleetName">
            <mat-error *ngIf="formGroup.get('fleetName')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Korrekturfaktor für Reichweitenschätzung</mat-label>
            <mat-select formControlName="rangeFactorSelect" (selectionChange)="changeRangeFactorSelection($event)">
                <mat-option *ngFor="let defaultRangeFactor of rangeFactors; trackBy: trackByValue" [value]="defaultRangeFactor.value">{{defaultRangeFactor.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Korrekturfaktor für Reichweitenschätzung</mat-label>
            <input type="number" matInput formControlName="rangeFactorCtrl" min="0" step="0.01">
            <mat-error *ngIf="formGroup.get('rangeFactorCtrl')?.errors?.required as error">{{error}}</mat-error>
            <mat-error *ngIf="formGroup.get('rangeFactorCtrl')?.errors?.invalid as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Standartwert für Batteriegesundheit</mat-label>
            <input type="number" matInput formControlName="defaultSohCtrl" min="0" step="1" max="100">
            <mat-error *ngIf="formGroup.get('defaultSohCtrl')?.errors?.required as error">{{error}}</mat-error>
            <mat-hint>Falls kein Wert für die Batteriegesundheit zur Verfügung steht, wird dieser Wert verwendet</mat-hint>
        </mat-form-field>
    </div>
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Breitengrad [lat] (°)</mat-label>
            <input matInput type="number" formControlName="lat">
            <mat-error *ngIf="formGroup.get('lat')?.errors?.invalid as error">{{error}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>L&auml;ngengrad [lng] (°)</mat-label>
            <input matInput type="number" formControlName="lon">
            <mat-error *ngIf="formGroup.get('lon')?.errors?.invalid as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Geofence-Radius(meter)</mat-label>
        <input matInput type="number" formControlName="geoFenceRadius">
    </mat-form-field>
    <mat-divider></mat-divider>
    <mat-checkbox formControlName="dynamicElectricityCostsEnabled">dynamischer Strompreis</mat-checkbox>
    <div *ngIf="!formGroup.get('dynamicElectricityCostsEnabled')?.value" class="reserveSpace d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Strompreis (ct/kWh)</mat-label>
            <input matInput type="number" formControlName="energyPrice">
            <mat-error *ngIf="formGroup.get('energyPrice')?.errors?.energyPriceInvalid as error">{{error}}</mat-error>
        </mat-form-field>
        <div class="w-50"></div>
    </div>
    <div *ngIf="formGroup.get('dynamicElectricityCostsEnabled')?.value" class="reserveSpace d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Konzessionsabgabe (ct/kWh)</mat-label>
            <input matInput type="number" formControlName="konzessionsabgabe">
            <mat-error *ngIf="formGroup?.errors?.konzessionsabgabeInvalid as error">{{error}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Netzentgelt (ct/kWh)</mat-label>
            <input matInput type="number" formControlName="netzentgelt">
            <mat-error *ngIf="formGroup?.errors?.netzentgeltInvalid as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Einspeisevergütung (ct/kWh)</mat-label>
        <input matInput type="number" min="0.0" formControlName="energyExportPrice">
    </mat-form-field>
    <div class="w-50"></div>
    <mat-checkbox matTooltip="achdem durch das Klicken in unserer Oberfläche ein Ladevorgang vom Benutzer gestoppt wurde, kann eine Fortsetzung unterbunden werden. In den meisten Fällen ist es jedoch sinnvoll das Fortsetzen eines Ladevorganges zu erlauben." formControlName="allowContinue">Manuell gestoppte Ladevorgänge können Fortgesetzt werden (empfohlen)</mat-checkbox>
    <mat-divider class="contain-div"></mat-divider>
    <!--<div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="contain-div">
        //TODO ist beim Angular Update kaputt gegangen
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="contain-div">
            <mat-form-field appearance="fill">
                <mat-label>Icon</mat-label>
                <ngx-mat-file-input formControlName="icon" placeholder="Datei wählen" accept="image/png, image/jpeg"></ngx-mat-file-input>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="icon">
            <img *ngIf="isImageAvailable()" [src]="data.icon" alt="Aktuell ausgewähltes Bild">
        </div>
    </div>
    <mat-divider fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"></mat-divider>
    -->
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name des Hauptanschlusses</mat-label>
            <input matInput formControlName="psName">
            <mat-error *ngIf="formGroup.get('psName')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Maximaler Strom des Hauptanschlusses (A)</mat-label>
            <input matInput type="number" formControlName="psMaxAmps">
            <mat-error *ngIf="formGroup.get('psMaxAmps')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Grid Connection Id (OpenADR)</mat-label>
            <input matInput formControlName="psGridConnectionId">
            <mat-hint class="mat-small">Leer lassen, wenn nicht bekannt.</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Peak Intervall</mat-label>
            <mat-select formControlName="psPeakCalculationIntervall">
                <mat-option value="YEARS">Jahr <mat-hint class="mat-small">(Zurücksetzen am 01.01. jeden Jahres)</mat-hint></mat-option>
                <mat-option value="MONTHS">Monat <mat-hint class="mat-small">(Zurücksetzen am 01. jeden Monats)</mat-hint></mat-option>
                <mat-option value="DAYS">Tag <mat-hint class="mat-small">(Zurücksetzen an jedem Tag 0:00 Uhr)</mat-hint></mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('psPeakCalculationIntervall')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>

