import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ExecuteRfidAddOrUpdate, Rfid, RfidList} from '@io-elon-common/frontend-api';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {
    IEditForm,
    TDialogOptions
} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditRfidDialogComponent} from '../dialogs/edit-rfid-dialog/edit-rfid-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {RfidUploadDialogComponent} from '../dialogs/rfid-upload-dialog/rfid-upload-dialog.component';
import {POLL_INTERVALS} from "../../../app.module";

export interface RfidUploadData {
    rfid: ExecuteRfidAddOrUpdate;
    error: string | undefined;
    status: UploadStatus;
    userRequired: boolean;
    vehicleRequired: boolean;
}

export enum UploadStatus {
    DISCARDED = "Nicht Ausgewählt",
    PENDING = "Ausstehend",
    CREATING = "Erstellen",
    SUCCESS = "Erfolgreich",
    FAILED = "Fehlgeschlagen",
}

@Injectable({
    providedIn: 'root'
})
export class RfidService extends DialogHandler<Rfid, ExecuteRfidAddOrUpdate, ExecuteRfidAddOrUpdate, {}, RfidList, void, void>{

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "Rfid", toastr, dialog, dialogService, POLL_INTERVALS.rfid);
    }

    protected getEditConfig(rfid: Rfid): TDialogOptions<ExecuteRfidAddOrUpdate, EditRfidDialogComponent> {
        return {
            headline: "Rfid bearbeiten",
            component: EditRfidDialogComponent,
            executeCallback: editResult => this.update(rfid.id, editResult),
            editElement: {
                name: rfid.name,
                token: rfid.token,
                vehicleId: rfid.vehicle?.id,
                userId: rfid.user?.id,
                invoiceReceiver: rfid.invoiceReceiver,
                active: rfid.active || false
            }
        };
    }

    protected getNewConfig(newDialogConfigArgs: void): TDialogOptions<ExecuteRfidAddOrUpdate, EditRfidDialogComponent> {
        return {
            headline: "Rfid hinzufügen",
            component: EditRfidDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "meine Rfid Karte",
                token: "",
                vehicleId: undefined,
                userId: undefined,
                invoiceReceiver: "",
                active: true
            }
        };
    }

    public async showRfidUploadDialog(rfids: RfidUploadData[]): Promise<void> {
        const config: TDialogOptions<RfidUploadData[], IEditForm<RfidUploadData[]>> = {
            editElement: rfids,
            executeCallback: async (rfids: RfidUploadData[]) => {
                rfids.forEach(rfid => {
                    if (rfid.status === UploadStatus.PENDING) {
                        rfid.status = UploadStatus.CREATING;
                        this.create(rfid.rfid)
                        .then(() => rfid.status = UploadStatus.SUCCESS)
                        .catch(reason => {
                            rfid.error = reason.error.msg;
                            rfid.status = UploadStatus.FAILED;
                        });
                    }
                });
            },
            headline: "RFID Hochladen",
            component: RfidUploadDialogComponent,
            keepAlive: true
        };

        await this.showDialog(config, "RFID Hochladen");
    }
}
