<div *ngIf="(accounts | async) as accounts; else loading">
    <div *ngIf="(vehicleLists | async) as vehicleLists; else loading">
        <mat-card appearance="outlined" id="evse-table-container" class="p-0">
            <app-tesla-account-table [accounts]="accounts.list" [vehicleLists]="vehicleLists"></app-tesla-account-table>
        </mat-card>

        <div class="buttons d-flex flex-row justify-content-end gap-3">
            <button [disabled]="!canAdd" mat-raised-button color="primary" (click)="createAccount()">Account Hinzufügen</button>
        </div>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
