import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {Basis, Fleet, SolarSystem} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {ToastrService} from 'ngx-toastr';
import {SolarSystemService} from '../../service/solar-system.service';

@Component({
  selector: 'app-solar-system-no-fleet-table',
  templateUrl: './solar-system-no-fleet-table.component.html',
  styleUrls: ['./solar-system-no-fleet-table.component.scss']
})
export class SolarSystemNoFleetTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input()
    public solarSystems!: SolarSystem[];

    @Output()
    public updateListEvent = new EventEmitter<void>();
    public displayedColumns: string[] = ['id', 'name', 'type', 'basis', 'actions'];
    public dataSource = new MatTableDataSource<SolarSystem>([]);
    public fleets: Fleet[] = [];
    public solarSystemBasisMap: {[key: number]: Basis} = {};

    public constructor(
        private readonly solarSystemService: SolarSystemService,
        private readonly fleetService: FleetService,
        private readonly toastr: ToastrService
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.dataSource = new MatTableDataSource(this.solarSystems);
        this.dataSource.paginator = this.paginator;
        this.fleets = await this.fleetService.getAllPromise().then(list => list.list);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.solarSystems;
    }

    public async save(solarSystem: SolarSystem): Promise<void> {
        const basis = this.solarSystemBasisMap[solarSystem.id];
        if (basis) {
            solarSystem.basis = basis;
            await this.solarSystemService.updateSolarSystemBasis(solarSystem);
            this.toastr.success("Der Standort des Solaranlage wird aktualisiert");
            this.updateListEvent.emit();
        }
    }
    public onBasisChange(basis: Basis, solarSystem: SolarSystem): void {
        this.solarSystemBasisMap[solarSystem.id] = basis;
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }
}
