import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditEvseDialogComponent} from '../dialogs/edit-evse-dialog/edit-evse-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {EaseeLoginDialogComponent} from '../dialogs/easee-login-dialog/easee-login-dialog.component';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {EvseActionsDialogComponent} from '../dialogs/evse-actions-dialog/evse-actions-dialog.component';
import {SystemService} from '../../../services/api-handlers/system.service';
import {EvseLogDialogComponent} from '../dialogs/evse-log-dialog/evse-log-dialog.component';
import {
    Basis,
    Evse,
    EvseAction,
    EvseActionResult,
    EvseHistory, EvseList,
    ExecuteEvseAddOrUpdate,
    PowerSupply,
    UnknownOcppClient
} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {ActorSpecificDataLine} from "@io-elon-common/frontend-api/lib/model/actorSpecificDataLine";
import {POLL_INTERVALS} from "../../../app.module";
import {
    EvseValidationHandlingDialogComponent
} from '../dialogs/evse-validation-handling-dialog/evse-validation-handling-dialog.component';

const PIXEL_PER_DOT = 10;

export type LoginData = {username: string, password: string}

export type ActorDetailsLoadingStatus = {
    actorId: number,
    actorPrettyName: string,
    actorClass: string,
    lines: Array<ActorSpecificDataLine & {pending: boolean, success: boolean}>
};

@Injectable({
    providedIn: 'root'
})
export class EvseService extends DialogHandler<Evse, ExecuteEvseAddOrUpdate, ExecuteEvseAddOrUpdate, {}, EvseList,  DialogParamsWrapperType, PowerSupply[]> {

    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService, private readonly systemService: SystemService) {
        super(apiService, "Evse", toastr, dialog, dialogService, POLL_INTERVALS.evse);
    }

    public triggerSequence(evseId: number): Promise<EvseActionResult> {
        return this.executeAction(evseId, {
            name: "triggerSequence",
            arguments: []
        });
    }

    public disableAuthTemp(evseId: number): Promise<EvseActionResult> {
        return this.executeAction(evseId, {
            name: "setAuth",
            arguments: [
                {
                    name: "active",
                    value: "false"
                }
            ]
        });
    }

    public getHistoryOfEvse(evseId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<EvseHistory> {
        start = typeof start === 'number' ? Math.ceil(start) : start.getTime();
        end = typeof end === 'number' ? Math.floor(end) : end.getTime();
        const zoom = Math.ceil((end - start) / 1000 / window.innerWidth * PIXEL_PER_DOT);

        return this.apiService.getEvseHistory(showAlerts, evseId, start, end, zoom, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    protected getEditConfig(evse: Evse, powerSupplies: PowerSupply[]): TDialogOptions<ExecuteEvseAddOrUpdate, EditEvseDialogComponent> {
        powerSupplies = powerSupplies.filter(ps=> ps.baseId === evse.basis.id);
        return {
            component: EditEvseDialogComponent,
            headline: "Ladepunkt bearbeiten",
            executeCallback: editResult => this.update(evse.id, editResult),
            editElement: {
                type: evse.typeKey,
                control: evse.control || false,
                guestCharging: evse.guestCharging || false,
                localId: evse.localId,
                maxI: evse.maxI,
                minGuestI: evse.guestMinAmps,
                monitor: evse.monitor || false,
                name: evse.name,
                smartCharging: evse.smartCharging || false,
                sequencesAllowed: evse.sequencesAllowed || false,
                basisId: evse.basis.id,
                actorKey: evse.actorKey,
                actorArgs: evse.actorArgs,
                phaseRotation: evse.phaseRotation,
                powerSupplyId: evse.powerSupplyId,
                authEnabled: evse.authEnabled,
                nonChargingCarsToMinAmps: evse.nonChargingCarsToMinAmps,
                slowChargeManagementEnabled: evse.slowChargeManagementEnabled,
                maintenance: evse.maintenanceEnabled,
                maintenanceReason: evse.maintenanceReason,
                canPauseCharging: evse.canPauseCharging
            },
            extraParams: {
                edit: true,
                possiblePowerSupplies: powerSupplies
            }
        };
    }

    protected getNewConfig(params: DialogParamsWrapperType): TDialogOptions<ExecuteEvseAddOrUpdate, EditEvseDialogComponent> {
        if(params == null || params.powerSupplies == null || params.powerSupplies.length === 0) {
            throw new Error("Es gibt keine Anschlüsse an die man die Ladepunkt anschließen kann")
        }
        return {
            component: EditEvseDialogComponent,
            headline: "Ladepunkt anlegen",
            executeCallback: editResult => this.create(editResult),
            editElement: {
                type: "",
                control: true,
                guestCharging: true,
                localId: "123",
                maxI: 32,
                minGuestI: 6,
                monitor: true,
                name: "",
                smartCharging: true,
                sequencesAllowed: true,
                basisId: params.basisId,
                actorArgs: [],
                actorKey: undefined,
                phaseRotation: 'NO_ROTATION',
                powerSupplyId: params.powerSupplies[0].id,
                authEnabled: false,
                nonChargingCarsToMinAmps: false,
                slowChargeManagementEnabled: true, //#3435 default to true
                maintenance: false,
                maintenanceReason: undefined,
                canPauseCharging: true
            },
            extraParams: {
                edit: false,
                possiblePowerSupplies: params.powerSupplies
            }
        };
    }

    public async showEaseeLogin(evse: Evse): Promise<void> {
        const config: TDialogOptions<LoginData, EaseeLoginDialogComponent> = {
            headline: "Token für '" + evse.name + "' erzeugen",
            component: EaseeLoginDialogComponent,
            executeCallback: async (elem: LoginData) => {
                let response: EvseActionResult;
                try {
                    response = await this.executeAction(evse.id, {
                        name: "easeeLogin",
                        arguments: [
                            {
                                name: "user",
                                value: elem.username,
                            }, {
                                name: "password",
                                value: elem.password,
                            }
                        ]
                    });
                } catch (e: any) {
                    this.toastr.error(e?.error?.msg);
                    throw e;
                }
                if(response.success) {
                    return;
                } else {
                    console.error(response.result);
                    this.toastr.error("Login Fehlgeschlagen");
                    throw new Error("Login Failed");
                }
            },
            editElement: {
                username: "",
                password: ""
            }
        };
        await this.showDialog(config, "Token wird erzeugt");
    }

    public stopCharging(evseId: number): Promise<EvseActionResult> {
        return this.executeAction(evseId, {
            name: "stopSession",
            arguments: []
        });
    }

    public continueCharging(evseId: number): Promise<EvseActionResult> {
        return this.executeAction(evseId, {
            name: "continueSession",
            arguments: []
        });
    }

    public getLiveLogs(evseId: number): WebSocket {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const customerId = ApiHandler.customerId ? ("&customerId=" + ApiHandler.customerId) : ""
        const secure = document.location.protocol.endsWith("s:");
        const url = (secure ? "wss" : "ws") + "://" + document.location.hostname+ ":" + document.location.port + "/api/evse/" + evseId + "/logsLive?timezone=" + timezone + customerId;
        return new WebSocket(url);
    }

    public async showCopyDialog(evse: Evse, powerSupplies: PowerSupply[]): Promise<void> {
        const nameRegexResult = /\d+$/.exec(evse.name);
        const nameNum = nameRegexResult === null ? 1 : (parseInt(nameRegexResult[0], 10) + 1);
        let newNameNum = '';
        let newName;
        if (nameRegexResult) {
            if (nameRegexResult![0]) {
                const numberOfZeros = nameRegexResult![0].length - nameNum.toString().length ;
                let leadingZeros = '';
                for (let i = 0; i < numberOfZeros; i++) {
                    leadingZeros += '0';
                }
                newNameNum = leadingZeros+nameNum
            }
            const name = evse.name.substr(0, evse.name.length - nameRegexResult[0].length);
            newName = name + newNameNum;
        } else {
            newName = evse.name + "2";
        }

        const config: TDialogOptions<ExecuteEvseAddOrUpdate, EditEvseDialogComponent> = {
            component: EditEvseDialogComponent,
            headline: "Ladepunkt kopieren",
            executeCallback: editResult => this.create(editResult),
            editElement: {
                type: evse.typeKey,
                control: evse.control || false,
                guestCharging: evse.guestCharging || false,
                localId: evse.localId,
                maxI: evse.maxI,
                monitor: evse.monitor || false,
                name: newName,
                smartCharging: evse.smartCharging || false,
                sequencesAllowed: evse.sequencesAllowed || false,
                basisId: evse.basis.id,
                actorKey: evse.actorKey,
                actorArgs: evse.actorArgs,
                phaseRotation: evse.phaseRotation,
                powerSupplyId: evse.powerSupplyId,
                authEnabled: evse.authEnabled,
                nonChargingCarsToMinAmps: evse.nonChargingCarsToMinAmps,
                slowChargeManagementEnabled: evse.slowChargeManagementEnabled,
                maintenance: evse.maintenanceEnabled,
                maintenanceReason: evse.maintenanceReason,
                canPauseCharging: evse.canPauseCharging
            },
            extraParams: {
                edit: true,
                possiblePowerSupplies: powerSupplies
            }
        };
        await this.showDialog(config, "Ladepunkt angelegt");
    }

    public async showNewDialogFromUnknownCpi(client: UnknownOcppClient, basis: Basis) {
        const config = this.getNewConfig(new DialogParamsWrapperType(basis.id, basis.powerSupplies))
        let cpi: string;
        if(client.fleetId) {
            cpi = client.fleetId + "/" + client.cpi;
        } else {
            cpi = client.cpi;
        }
        config.editElement.actorArgs = [
            { key: "chargePointIdentity", value: cpi},
            { key: "connectorId", value: "1"},
            { key: "ocppUplinkAccount", value: "null"},
            { key: "statusPollIntervallSeconds", value: ""},
            { key: "meterPollIntervallSeconds", value: ""},
        ];
        config.editElement.actorKey = "de.iodynamics.elon.backend.service.ocpp.orm.OcppActor";
        config.editElement.type = "de.iodynamics.elon.backend.evses.ocpp.GenericOcppEvse";

        await this.showDialog(config, "Ladesäule angelegt");
    }

    public showActionDialog(evse: Evse) {
        this.dialog.open(EvseActionsDialogComponent, {
            data: {
                headline: "Kommando senden",
                evseId: evse.id,
                data: this.systemService.getActorActions(evse)
            },
            maxHeight: '90vh'
        });
    }

    private async supportsSlowDetailsLoading(evse: Evse): Promise<boolean> {
        return this.supportsActions("getSpecificFields", evse);
    }

    public supportsActions(name: string, evse: Evse): boolean {
        return this.systemService.getActorActions(evse).some(a => a.name === name);
    }

    public getActorDetails(evse: Evse, showAlerts = true): BehaviorSubject<ActorDetailsLoadingStatus | undefined> {
        const subj = new BehaviorSubject<ActorDetailsLoadingStatus | undefined>(undefined);

        this.supportsSlowDetailsLoading(evse)
            .then(async supportSlowLoading => {

                if (supportSlowLoading) {
                    try {
                        const result = await this.apiService.getActorSpecificData(false, evse.id, undefined, undefined, ApiHandler.customerId).toPromise();
                        subj.next({
                            ...result,
                            lines: result.lines.map(l => {
                                return {
                                    ...l,
                                    pending: false,
                                    success: true
                                }
                            })
                        });
                        subj.complete();
                    } catch (e) {
                        this.toastr.warning("Das Laden der Daten ist fehlgeschlagen. Versuch langsames Laden");

                        const allFields = await this.apiService.getActorSpecificDataFields(false, evse.id, undefined, undefined, ApiHandler.customerId).toPromise()
                        const result: ActorDetailsLoadingStatus = {
                            ...allFields,
                            lines: allFields.lines.map(l => {
                                return {
                                    ...l,
                                    pending: true,
                                    success: false
                                }
                            })
                        };
                        subj.next(result);

                        for(let i = 0; i < result.lines.length; i++) {
                            const lineData = await this.getActorSingleSpecificDataField(evse.id, result.lines[i].key, showAlerts)
                            result.lines[i].pending = false;
                            if (lineData.success) {
                                result.lines[i].value = lineData.value;
                                result.lines[i].success = true;
                            } else {
                                result.lines[i].success = false;
                            }
                            subj.next(result);
                        }
                        subj.complete();
                    }
                } else {
                    try {
                        const result = await this.apiService.getActorSpecificData(false, evse.id, undefined, undefined, ApiHandler.customerId).toPromise();
                        subj.next({
                            ...result,
                            lines: result.lines.map(l => {
                                return {
                                    ...l,
                                    pending: false,
                                    success: true
                                }
                            })
                        });
                        subj.complete();
                    } catch (e) {
                        console.error(e);
                        subj.error(e);
                    }
                }

            })
            .catch(e => {
                console.error(e);
                subj.error(e);
            });

        return subj;
    }

    public setActorSpecificDataField(evseId: number, key: string, value: string): Promise<EvseActionResult> {
        return this.executeAction(evseId, {
            name: "setSpecificData",
            arguments: [
                {
                    name: "key",
                    value: key
                }, {
                    name: "value",
                    value: value
                }
            ]
        });
    }

    public getActorSingleSpecificDataField(evseId: number, key: string, showAlerts = true): Promise<EvseActionResult> {
        return this.executeAction(evseId, {
            name: "getSingleSpecificData",
            arguments: [
                {
                    name: "key",
                    value: key
                }
            ]
        }, showAlerts);
    }

    public async executeAction(evseId: number, evseAction: EvseAction, showAlerts = true): Promise<EvseActionResult> {
        return this.apiService.executeAction(showAlerts, evseId, undefined, undefined, ApiHandler.customerId, evseAction).toPromise().then(r => {
            if (r.rebootRequired) {
                this.dialogService.showInfoDialog("Neustart erforderlich", "Die Ladestation meldet, das zum Übernehmen der Änderungen ein manueller Neustart erforderlich ist.");
            }
            return r
        });
    }

    public async downloadLog(evseId: number) {
        const customerId = ApiHandler.customerId ? ("&customerId=" + ApiHandler.customerId) : ""
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        window.open("api/evse/" + evseId + "/fullLog?timezone=" + tz + customerId);
    }

    public showLog(evse: Evse, webSocket: WebSocket) {
        this.dialog.open(EvseLogDialogComponent, {
            data: {
                headline: "Protokoll",
                id: evse.id,
                name: evse.name,
                downloadFn: () => this.downloadLog(evse.id),
                websocket: webSocket
            },
            maxHeight: '90vh'
        });
    }

    public isLogSupported(evse: Evse) {
        return this.supportsActions("GetLog", evse);
    }

    public showValidationDialog(evse: Evse) {
        this.dialog.open(EvseValidationHandlingDialogComponent, {
            data: {
                evseId: evse.id,
                validations: evse.liveData.validationResult
            },
            maxHeight: '90vh'
        });
    }
}

export class DialogParamsWrapperType {
  basisId : number;
  powerSupplies: PowerSupply[];

  constructor(
      basisId : number,
      powerSupplies: PowerSupply[]
  ) {
    this.basisId = basisId;
    this.powerSupplies = powerSupplies;
  }
}
