import {Injectable} from '@angular/core';
import {
    ExecuteReservationAddOrUpdate,
    ExecuteReservationDelete, Fleet,
    Reservation,
    ReservationInstance, ReservationList,
    TimeZoneName,
    VehicleTeaser
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditReservationDialogComponent} from '../dialogs/edit-reservation-dialog/edit-reservation-dialog.component';
import {AuthService} from '../../../shared/guards/auth.service';
import * as moment from 'moment';
import {DialogService} from '../../../services/dialog.service';
import {POLL_INTERVALS} from "../../../app.module";

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = 7 * DAY;

export type ReservationEditParams = {
    vehicles: VehicleTeaser[],
    mode: ExecuteReservationAddOrUpdate.EditModeEnum,
    instance: ReservationInstance
};

@Injectable({
    providedIn: 'root'
})
// tslint:disable-next-line:max-line-length
export class ReservationService extends DialogHandler<Reservation, ExecuteReservationAddOrUpdate, ExecuteReservationAddOrUpdate, ExecuteReservationDelete, ReservationList, {vehicle: VehicleTeaser, vehicles: VehicleTeaser[], fleet: Fleet}, ReservationEditParams> {
    private static guessDepartureTime(): number {
        const now = Date.now();
        if ((now % DAY) > 13 * HOUR) {
            // Wenn es nach 13:00 Uhr ist, soll 8:00 am nächsten Tag vorgeschlagen werden
            return now - (now % DAY) + DAY + 8 * HOUR;
        }

        // Ansonsten wird eine auf ganze Stunden abgerundete Uhrzeit in 3-4 Stunden vorgeschlagen
        return now - (now % HOUR) + 5 * HOUR;
    }

    private static guessReturnTime(): number {
        return ReservationService.guessDepartureTime() + HOUR; // 1h
    }

    constructor(
        apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService,
        private readonly authService: AuthService
    ) {
        super(apiService, 'Reservation', toastr, dialog, dialogService, POLL_INTERVALS.reservation);
    }

    // tslint:disable-next-line:max-line-length
    protected getEditConfig(reservation: Reservation, data: {vehicles: VehicleTeaser[], mode: ExecuteReservationAddOrUpdate.EditModeEnum, instance: ReservationInstance}): TDialogOptions<ExecuteReservationAddOrUpdate, EditReservationDialogComponent> {
        const now = moment();

        const editElement = {
            asap: reservation.asap,
            distance: reservation.distance,
            departureTime: data.instance?.start + data.instance?.timeBuffer ?? reservation.departureTime,
            returnTime: data.instance?.end ?? reservation.returnTime,
            vehicleId: reservation.vehicle.id,
            driverId: reservation.driver?.id || undefined,
            useDuration: reservation.useDuration,
            dayOptions: reservation.dayOptions,
            endDate:  undefined,
            freq:  reservation.freq,
            repeatCount: Math.floor((reservation.lastRepeatEnd - reservation.departureTime) / WEEK),
            editMode: data.mode,
            originalInstanceStart: data.instance.start,
            timeZone : reservation.timeZone
        };

        switch (data.mode) {
            case 'ALL':
                break;
            case 'SINGLE':
                editElement.freq = undefined;
            // tslint:disable-next-line:no-switch-case-fall-through
            case 'FUTURE':
                const departureTime = editElement.departureTime % DAY + data.instance.start - (data.instance.start % DAY);
                const returnTime = editElement.returnTime % DAY + data.instance.end - (data.instance.end % DAY);
                editElement.departureTime = departureTime;
                editElement.returnTime = returnTime;
                break;
            default:
                throw new Error("Invalid reservation edit mode: " + data.mode);
        }

        return {
            headline: "Reservierung bearbeiten",
            component: EditReservationDialogComponent,
            executeCallback: async editResult => this.update(reservation.id, editResult),
            editElement,
            extraParams: {
                vehicles: data.vehicles,
                min: now,
                mode: data.mode,
                overcharge: data.instance.overcharge,
                timeBuffer: data.instance.timeBuffer
            }
        };
    }

    // tslint:disable-next-line:max-line-length
    protected getNewConfig(data: { vehicle: VehicleTeaser; vehicles: VehicleTeaser[], fleet: Fleet}): TDialogOptions<ExecuteReservationAddOrUpdate, EditReservationDialogComponent> {
        const now = moment();
        let day: ExecuteReservationAddOrUpdate.DayOptionsEnum = 'MONDAY';
        switch (new Date().getDay()) { // Day of week
            case 0:
                day = 'SUNDAY';
                break;
            case 1:
                day = 'MONDAY';
                break;
            case 2:
                day = 'TUESDAY';
                break;
            case 3:
                day = 'WEDNESDAY';
                break;
            case 4:
                day = 'THURSDAY';
                break;
            case 5:
                day = 'FRIDAY';
                break;
            case 6:
                day = 'SATURDAY';
                break;
        }

        return {
            headline: "Reservierung anlegen",
            component: EditReservationDialogComponent,
            executeCallback: editResult => {
                if(!editResult.repeatCount) {
                    editResult.repeatCount = 520;
                }
                return this.create(editResult)
            },
            editElement: {
                asap: false,
                distance: 50,
                departureTime: ReservationService.guessDepartureTime(),
                returnTime: ReservationService.guessReturnTime(),
                vehicleId: data.vehicle.id,
                driverId: this.authService.user?.id || -1,
                useDuration: false,
                endDate: undefined,
                freq:  undefined,
                repeatCount: undefined,
                dayOptions: [day],
                editMode: "ALL",
                originalInstanceStart: undefined,
                timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneName
            },
            extraParams: {
                vehicles: data.vehicles,
                min: now,
                mode: 'ALL',
                timeBuffer: data.fleet.reservationTimeBuffer * 1000, // convert seconds into ms
                overcharge: data.fleet.reservationOvercharge
            }
        };
    }
}
