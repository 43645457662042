<div mat-dialog-content>
    <div class="d-flex flex-row justify-content-between">
        <h2>Preisgestaltung</h2>
        <app-help-box-icon #helpPrice></app-help-box-icon>
    </div>
    <app-help-box [helpIcon]="helpPrice" key="CHARGING_TABLE_DETAILS_PRICE">
        Die Preise werden aus den Ladegruppen ermittelt. Es gelten die Ladegruppen, die zum Zeitpunkt des Beginns des Ladevorgangs hinterlegt waren.
        Wenn Ladegruppen später geändert wurden, hat dass keinen Einfluss auf den Preis dieses Ladevorgangs. Es ist möglich,
        dass mehrere Preise auf diese Ladepunkt - Fahrzeug/RFID Kombination passen. In diesem Fall werden hier alle angezeigt
        und für die Berechnung des Preises wird die mit dem niedrigsten Gesamtpreis verwendet.
    </app-help-box>
    <table>
        <tr>
            <th>Name</th>
            <th>Energiepreis</th>
            <th>Preis pro Ladevorgang</th>
            <th>Preis pro Stunde</th>
            <th>Freiminuten</th>
        </tr>
        @for (po of data.priceOptions; track po.name) {
            <tr>
                <td>{{po.name}}</td>
                <td>{{formatPrice(po.pricePerEnergy, '€/kWh')}}</td>
                <td>{{formatPrice(po.pricePerSession, '€')}}</td>
                <td>{{formatPrice(po.pricePerHour, '€/h')}}</td>
                <td>
                    @if(po.initialFreeMinutes) {
                        {{po.initialFreeMinutes * 1000 * 60 | duration}}
                    } @else {
                        ---
                    }
                </td>
            </tr>
        }
    </table>
</div>

<div mat-dialog-actions>
    <button mat-button  color="primary" (click)="dialogRef.close()">Schließen</button>
</div>
