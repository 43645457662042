<div *ngIf="(vehicles | async) as vehicles; else loading">
    <div *ngIf="(users | async) as users; else loading" class="d-flex flex-column gap-3 justify-content-start">
        <mat-form-field appearance="fill">
            <mat-label>Fahrzeug</mat-label>
            <mat-select [(value)]="data.vehicleId">
                <mat-option *ngFor="let v of vehicles.list" [value]="v.id">{{v | vehicleName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="d-flex flex-row gap-3">
            <mat-form-field appearance="fill" class="flex-grow-1">
                <mat-label>Ausgegeben an</mat-label>
                <mat-select [(value)]="data.currentLocationUserId">
                    <mat-option [value]="null">Fahrer ohne Account</mat-option>
                    <mat-option *ngFor="let u of users.list" [value]="u.id">{{u | fullName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-grow-1">
                <mat-label>Standort</mat-label>
                <input matInput [(ngModel)]="data.currentLocation">
            </mat-form-field>
        </div>
        <mat-checkbox [(ngModel)]="givenOut">Schlüssel herausgegeben</mat-checkbox>
        <app-date-time-form *ngIf="givenOut" [(dateTime)]="data.giveOutTime" label="Herausgegeben"></app-date-time-form>
        <mat-checkbox *ngIf="givenOut" [(ngModel)]="hasReturnTime">Rückgabezeit angeben</mat-checkbox>
        <app-date-time-form *ngIf="hasReturnTime && givenOut" [(dateTime)]="data.expectedReturnTime" label="Erwartete Rückgabe"></app-date-time-form>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>
