<div class="d-flex flex-column justify-content-start align-items-stretch gap-3" style="min-width: 400px">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>

        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Strategie</mat-label>
            <mat-select  [(ngModel)]="data.strategy" (ngModelChange)="strategySelected($event)">
                <mat-option *ngFor="let strategy of strategies" [value]="strategy.key">{{strategy.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="mat-small mat-hint" [innerHTML]="getAlgoDesc()">
    </div>

    <div class="d-flex flex-row justify-content-start align-items-stretch gap-3">
        <mat-form-field class="flex-grow-1" appearance="fill" *ngIf="(algoConfigs | async) as algoConfigs; else loading">
            <mat-label>Fallback-Konfiguration</mat-label>
            <mat-select  [(ngModel)]="data.fallbackConfigId">
                <mat-option [value]="-1">--Alter Algorithmus--</mat-option>
                <mat-option *ngFor="let algoConfig of algoConfigs.list" [value]="algoConfig.id">{{algoConfig.name}}</mat-option>
            </mat-select>
        </mat-form-field>
         <div class="w-50 flex-grow-0">
        </div>
    </div>

    <!-- ein ngIf ist nicht möglich, da dieser canvas dann nicht mehr richtig gezeichnet wird nachträglich -->
    <div class="canvas-container" [style]="{'display': (data.strategy === 'v2_soft_int') ? '': 'none'}">
        <canvas id="chartCanvas" width="400" height="400"></canvas>
        <canvas id="chartCanvasMi" width="400" height="400"></canvas>
    </div>

    <ng-container *ngIf="(visibleAlgoParams.length > 0)">
        <div *ngFor="let algoParam of visibleAlgoParams" [style]="{visibility: (visibleAlgoParams.length > 0) ? '' : 'hidden', width: '100%'}">
            <div *ngIf="algoParam.type === 'DOUBLE'">
                <mat-form-field appearance="fill">
                    <button matSuffix (click)="data.params[algoParam.key]=algoParam.initial; updateCharts()" [matTooltip]="'Zurücksetzen auf: ' + algoParam.initial" [disabled]="algoParam.initial == data.params[algoParam.key]"><mat-icon>undo</mat-icon></button>
                    <mat-label>{{algoParam.description}}</mat-label>
                    <input matInput [(ngModel)]="data.params[algoParam.key]" [disabled]="!isDev" (ngModelChange)="updateCharts()">
                    <mat-hint>Min: {{algoParam.min}}, Max: {{algoParam.max}}</mat-hint>
                </mat-form-field>
                <mat-slider
                    thumbLabel
                    [step]="step(algoParam)"
                    [min]="algoParam.min"
                    [max]="algoParam.max"
                    color="primary"
                    aria-label="units"><input matSliderThumb [(ngModel)]="data.params[algoParam.key]" (ngModelChange)="updateCharts()"/></mat-slider>
            </div>
            <div *ngIf="algoParam.type === 'BOOL'">
                <mat-checkbox
                    [ngModel]="data.params[algoParam.key] === 'true'"
                    (ngModelChange)="data.params[algoParam.key] = $event + ''; updateCharts()">
                    {{algoParam.description}}
                </mat-checkbox>
            </div>
            <div class="mat-small mat-hint">{{algoParam.hint}}</div>
        </div>

    </ng-container>
    <mat-form-field appearance="fill" *ngIf="data.strategy === 'v2_soft_int' && (data.url !== 'default' || isDev)">
        <mat-label>Algo URL</mat-label>
        <input matInput [(ngModel)]="data.url" [disabled]="!isDev">
        <mat-hint>Hier kann der Server für die Berechnung umgestellt werden. Dieses Feld ist nur Sichtbar wenn der Wert durch IO-Dynamics geändert wurde.</mat-hint>
    </mat-form-field>

    <ng-template #loading>Bitte warten</ng-template>
</div>
