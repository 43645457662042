import {Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {TemplatePortal} from "@angular/cdk/portal";

@Directive({
  selector: '[htmlTooltip]',
  standalone: true
})
export class CustomTooltipDirective implements OnDestroy {
    // Accept a template reference
    @Input('htmlTooltip') tooltipTemplate!: TemplateRef<any>;

    // Accept additional data to pass to the template
    @Input() tooltipContext: any;

    private overlayRef!: OverlayRef;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef
    ) {}

    @HostListener('mouseenter')
    show() {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position()
                    .flexibleConnectedTo(this.elementRef)
                    .withPositions([
                        {
                            originX: 'center',
                            originY: 'bottom',
                            overlayX: 'center',
                            overlayY: 'top',
                            offsetY: 8
                        }
                    ]),
                scrollStrategy: this.overlay.scrollStrategies.reposition()
            });
        }
        // Create the template portal with the context (arguments)
        const tooltipPortal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef, this.tooltipContext);
        this.overlayRef.attach(tooltipPortal);
    }

    @HostListener('mouseleave')
    hide() {
        this.overlayRef?.detach();
    }

    ngOnDestroy() {
        this.overlayRef?.dispose();
    }
}
