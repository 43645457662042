<div class="d-flex flex-column gap-3">

    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput type="text" [(ngModel)]="data.name">
        <mat-hint>Name</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>SQL</mat-label>
        <input matInput type="text" [(ngModel)]="data.sql">
    </mat-form-field>


    <mat-form-field appearance="fill">
        <mat-label>Intervall</mat-label>
        <input matInput type="number" [(ngModel)]="data.period">
        <span matTextSuffix>ms</span>
    </mat-form-field>
</div>
<ng-template #loading>Loading...</ng-template>
