import {Component, OnInit} from '@angular/core';
import {EvseService} from "../../evse/service/evse.service";
import {VehicleService} from "../../vehicle/service/vehicle.service";
import {LivedataService} from "../../../services/api-handlers/livedata.service";
import {
    Evse,
    LiveDataResponse, LiveDataResponseField,
    LiveDataUpdateRequest,
    LiveDataUpdateRequestField,
    Vehicle
} from "@io-elon-common/frontend-api";
import {SystemService} from "../../../services/api-handlers/system.service";
import {SystemInfoDeviceKeyDetails} from "@io-elon-common/frontend-api/lib/model/systemInfoDeviceKeyDetails";
import {num} from "../../../shared/helper/util-functions";
import {ToastrService} from "ngx-toastr";
import {DialogService} from "../../../services/dialog.service";
import {localStorageGet, localStorageSave} from "../../../shared/helper/typed-local-storage";
import {MatDialog} from '@angular/material/dialog';
import {LiveDataDateTimeDialog, DateTimeDialogData} from './live-data-dialog/live-data-date-time-dialog.component';

@Component({
    selector: 'app-live-data',
    templateUrl: './live-data.component.html',
    styleUrls: ['./live-data.component.scss']
})
export class LiveDataComponent implements OnInit {

    public allEvses: Evse[] = [];
    public selectedEvses: Evse[] = [];
    public allVehicles: Vehicle[] = [];
    public selectedVehicles: Vehicle[] = [];

    public liveData?: LiveDataResponse;

    public evseKeysAll: SystemInfoDeviceKeyDetails[] = [];
    public vehicleKeysAll: SystemInfoDeviceKeyDetails[] = [];
    public evseKeys: SystemInfoDeviceKeyDetails[] = [];
    public vehicleKeys: SystemInfoDeviceKeyDetails[] = [];

    public evseData: {[key: string]: { [key: string]: {tst: number, val: string, source: LiveDataResponseField.SourceEnum} }} = {}
    public vehicleData: {[key: string]: { [key: string]: {tst: number, val: string, source: string} }} = {}

    public loading = true;

    public autoUpdate = false;
    public lastResponseTime = 0;
    public autoUpdateSpeed = 1000;

    public editVehicle?: string;
    public editEvse?: string;
    private autoUpdateTimeout?: any;
    public tst: number = Date.now().valueOf();


    public constructor(
        private readonly evseService: EvseService,
        private readonly vehicleService: VehicleService,
        private readonly liveDataService: LivedataService,
        private readonly systemService: SystemService,
        private readonly toastrService: ToastrService,
        private readonly dialogService: DialogService,
        private readonly dialog: MatDialog
    ) {
        systemService.getSystemInfo().then(i => {
            this.evseKeysAll = i.evseKeys;
            this.vehicleKeysAll = i.vehicleKeys;
        });
    }

    public async ngOnInit(): Promise<void> {
        const evseP = this.evseService.getAllPromise();
        const vehicleP = this.vehicleService.getAllPromise();

        this.allEvses = (await evseP).list.sort((a, b) =>  a.id - b.id);
        this.allVehicles = (await vehicleP).list.sort((a, b) =>  a.id - b.id);
        this.loading = false;
    }

    public formatSelection(list: Array<{name?: string}>) {
        switch (list.length) {
            case 0:
                return "Keine"
            case 1:
                return list[0].name;
            default:
                return list.length + ' Ausgewählt';
        }
    }

    public selectAllEvses() {
        this.selectedEvses = [...this.allEvses];
    }

    public selectAllEvseKeys() {
        this.evseKeys = [...this.evseKeysAll];
    }

    public selectAllVehicles() {
        this.selectedVehicles = [...this.allVehicles];
    }

    public selectAllVehicleKeys() {
        this.vehicleKeys = [...this.vehicleKeysAll];
    }



    public formatDelay(val: number) {
        return (val / 1000) + "s";
    }

    public getEvseName(id: string) {
        return this.allEvses.filter(e => e.id === num(id))[0]?.name || ("EVSE: id")
    }

    public getVehicleName(id: string) {
        return this.allVehicles.filter(e => e.id === num(id))[0]?.name || ("Veh: id")
    }

    public async update(): Promise<void> {
        if(this.loading) {
            this.updateTimer();
            return;
        }
        this.tst = Date.now().valueOf();
        this.editVehicle = '';
        this.editEvse = '';
        this.loading = true;
        try {
            this.liveData = await this.liveDataService.getLiveData(this.selectedEvses.map(e => e.id), this.selectedVehicles.map(v => v.id))
            this.lastResponseTime = Date.now();
            this.evseData = {};
            this.vehicleData = {};
            if(this.liveData.data !== undefined) {
                for(const d of this.liveData.data) {
                    let obj;
                    if(d.evseId !== undefined) {
                        if(this.evseData[d.evseId] === undefined){
                            this.evseData[d.evseId] = {};
                        }
                        obj = this.evseData[d.evseId]
                    } else if(d.vehicleId !== undefined) {
                        if(this.vehicleData[d.vehicleId] === undefined){
                            this.vehicleData[d.vehicleId] = {};
                        }
                        obj = this.vehicleData[d.vehicleId]
                    } else {
                        console.error("No EVSE or VehicleId");
                        continue;
                    }
                    obj[d.key] = {tst: d.tst, val: d.value, source: d.source};
                }
                for(const e of Object.values(this.evseData)) {
                    for(const k of this.evseKeysAll) {
                        if(!e[k.name]) {
                            e[k.name] = {tst: 0, val: "", source: "Other"};
                        }
                    }
                }
                for(const e of Object.values(this.vehicleData)) {
                    for(const k of this.vehicleKeysAll) {
                        if(!e[k.name]) {
                            e[k.name] = {tst: 0, val: "", source: "Other"};
                        }
                    }
                }
            }

            this.updateTimer();
        } finally {
            this.loading = false;
        }
    }

    public updateTimer() {
        clearTimeout(this.autoUpdateTimeout)
        if(this.autoUpdate) {
            this.autoUpdateTimeout = setTimeout(() => this.update(), this.autoUpdateSpeed);
        }
    }

    public async save(method: LiveDataUpdateRequest.MethodEnum) {
        const data: LiveDataUpdateRequestField[] = [];

        if(!this.editVehicle && !this.editEvse) {
            this.toastrService.error("Kein Fahrzeug / Keine Ladepunkt ausgewählt")
            return;
        }
        if(this.editVehicle) {
            for(const k of this.vehicleKeys) {
                data.push({
                    key: k.name,
                    value: this.vehicleData[this.editVehicle][k.name].val
                });
            }
        } else if(this.editEvse) {
            for(const k of this.evseKeys) {
                data.push({
                    key: k.name,
                    value: this.evseData[this.editEvse][k.name].val
                });
            }
        }

        if(data.length > 5) {
            const confirm = await this.dialogService.showConfirmDialog(undefined, "Es sind " + data.length + " Felder ausgewählt. Es werden nur die Felder gesetzt, die auch sichtbar sind. Um weniger Felder zu senden diese bitte abwählen. Sollen wirklich alle " + data.length + " Felder gesendet werden?")
            if(!confirm) {
                this.toastrService.warning("Information", "Speichern wurde abgebrochen")
                return;
            }
        }

        await this.liveDataService.setLiveData(
            this.editEvse ? num(this.editEvse) : undefined,
            this.editVehicle ? num(this.editVehicle) : undefined,
            method,
            data,
            this.tst
        );
        this.toastrService.success("Gespeichert")
    }

    mem(idx: 1 | 2 | 3) {
        const evse = JSON.stringify(this.selectedEvses.map(e => e.id));
        const evseKeys = JSON.stringify(this.evseKeys.map(k => k.name));
        const veh = JSON.stringify(this.selectedVehicles.map(v => v.id));
        const vehKeys = JSON.stringify(this.vehicleKeys.map(k => k.name));

        switch (idx) {
            case 1:
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_1", evse);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_1", evseKeys);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_1", veh);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_1", vehKeys);
                break;
            case 2:
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_2", evse);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_2", evseKeys);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_2", veh);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_2", vehKeys);
                break;
            case 3:
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_3", evse);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_3", evseKeys);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_3", veh);
                localStorageSave("LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_3", vehKeys);
                break;
            default:
                this.toastrService.warning("Failed to save");
                return;
        }
        this.toastrService.success("Saved")
    }

    load(idx: 1 | 2 | 3) {
        let evse: number[];
        let evseKeys: string[];
        let veh: number[];
        let vehKey: string[];
        switch (idx) {
            case 1:
                evse = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_1", "[]"));
                evseKeys = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_1", "[]"));
                veh = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_1", "[]"));
                vehKey = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_1", "[]"));
                break;
            case 2:
                evse = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_2", "[]"));
                evseKeys = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_2", "[]"));
                veh = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_2", "[]"));
                vehKey = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_2", "[]"));
                break;
            case 3:
                evse = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_EVSE_IDS_3", "[]"));
                evseKeys = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_EVSE_KEYS_3", "[]"));
                veh = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_VEHICLE_IDS_3", "[]"));
                vehKey = JSON.parse(localStorageGet("LIVE_DATA_DEBUG_SELECTION_VEHICLE_KEYS_3", "[]"));
                break;
            default:
                this.toastrService.warning("Failed to load");
                return;
        }
        this.selectedEvses = evse.map(id => this.allEvses.filter(e => e.id === id)[0]).filter(e => !!e);
        this.evseKeys = evseKeys.map(name => this.evseKeysAll.filter(k => k.name === name)[0]).filter(k => !!k);
        this.selectedVehicles = veh.map(id => this.allVehicles.filter(v => v.id === id)[0]).filter(v => !!v);
        this.vehicleKeys = vehKey.map(name => this.vehicleKeysAll.filter(k => k.name === name)[0]).filter(k => !!k);
        this.toastrService.success("Loaded")
    }

    async openTimePicker(tst: number) {
        const dialogData: DateTimeDialogData = {
            tst
        };
        const dialogRef = this.dialog.open(LiveDataDateTimeDialog, {
            data: dialogData
        });
        const newTst = (await dialogRef.afterClosed().toPromise());
        this.tst = newTst ?? this.tst;
    }
}
