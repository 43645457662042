import {Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {DelayedExecutor} from '../../../../shared/helper/DelayedExecutor';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ZoomEvent} from '../../../../shared/helper/zoom-helper.directive';
import {EvseHealthDataUtils} from '../../../../shared/helper/evse-health-data-utils';
import {EvseHealthService} from '../../service/evseHealth.service';
import {Evse, HealthStatus} from '@io-elon-common/frontend-api';
import {HealthStatusHistoryEntry} from '@io-elon-common/frontend-api/lib/model/models';
import {EvseHealthHistoryRequest} from '@io-elon-common/frontend-api/lib/model/evseHealthHistoryRequest';

const DAY = 24 * 3600 * 1000;

type HeaderLabel = {
    style: string,
    line1: string,
    line2: string
};


@Component({
    selector: 'app-evse-health-diagramm',
    templateUrl: './evse-health-diagramm.component.html',
    styleUrl: './evse-health-diagramm.component.scss'
})

export class EvseHealthDiagrammComponent implements OnInit, OnChanges, OnDestroy {
    @Input() startIn!: Moment;
    @Input() endIn!: Moment;
    @Input() evse!: Evse[];
    @Input() selectedEvse!: Evse;

    @ViewChild(MatPaginator, {static: true}) public paginator!: MatPaginator;
    public reloadExecutor = new DelayedExecutor(300);
    public dataSource = new MatTableDataSource<Evse>([]);
    public healthStatusEntries!: Array<BehaviorSubject<HealthStatusHistoryEntry[] | undefined>>; // Sparse Array, index is vehicleId
    public isOpen?: HealthStatusHistoryEntry;
    public displayedColumns: string[] = [
        'icon',
        'name',
        'graph'];

    private start = moment();
    private end = moment();
    private sates: HealthStatus[] = [HealthStatus.Broken, HealthStatus.OnRepair, HealthStatus.Maintenance];
    private healthStatusSubscription!: Array<Subscription>;

    constructor(
        private readonly evseHealthService: EvseHealthService,
        public readonly evseHealthDataUtils: EvseHealthDataUtils
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.start = this.startIn.clone().subtract(2, 'hours').startOf('hour');
        this.end = this.endIn.clone().add(2, 'hours').startOf('hour');

        this.dataSource = new MatTableDataSource<Evse>(this.evse);
        this.dataSource.paginator = this.paginator;

        this.healthStatusEntries = [];
        this.healthStatusSubscription = [];
        this.updateHealthStatus();
    }

    public ngOnChanges(changes?: SimpleChanges) {
        this.start = this.startIn.clone().subtract(2, 'hours').startOf('hour');
        this.end = this.endIn.clone().add(2, 'hours').startOf('hour');

        this.updateHealthStatus();

        this.dataSource.data = this.evse;
    }

    public ngOnDestroy(): void {
        this.healthStatusSubscription.forEach(s => s.unsubscribe());
    }

    public get refreshActive(): boolean {
        return this.healthStatusEntries.some(bs => bs.getValue() === undefined);
    }

    public getEvseHealthStatusEntries(evse: Evse): HealthStatusHistoryEntry[] {
        return this.healthStatusEntries[evse.id]?.getValue()?.filter(e => {
            const start = this.start.valueOf();
            const end = this.end.valueOf();
            const endHealthEntry = e.tstEnd ?? this.end.isBefore(moment.now()) ? this.end.valueOf() : moment.now().valueOf();
            const startHealthEntry = e.tstStart;

            return endHealthEntry > start && startHealthEntry < end;
        }).sort((e1, e2) => e2.tstStart - e1.tstStart) || []; // Leeres Array wenn noch keine Reservations geladen wurden
    }

    public getDayLinePositions(): Array<string> {
        return this.getGridPositions().map(t => `left: ${this.getX(t)}%;`);
    }

    public getWeekends(): Array<string> {
        const result: string[] = [];
        const day = this.start.clone().startOf('day');

        while (day.isBefore(this.end)) {
            if (day.isoWeekday() > 5) {
                const t = day.valueOf();
                result.push(`left: ${this.getX(t)}%; right: ${100 - this.getX(t + DAY)}%;`);
            }
            day.add(1, 'days');
        }

        return result;
    }

    public getNow(): string {
        let t = Date.now();
        t -= t % 10000;
        const x = this.getX(t);
        if (x <= 0 || x >= 100) {
            return 'visibility: hidden;';
        }
        return `left: ${x}%; right: ${100 - x - 1}%;`;
    }

    public getHighlight(evse: Evse): { visible: boolean, style: string } {
        if (evse !== this.selectedEvse) {
            return {
                visible: false,
                style: ''
            };
        }
        return {
            visible: true,
            style: `left: ${this.getX(this.startIn)}%; right: ${100 - this.getX(this.endIn)}%;`
        };
    }

    public getX(time: number | Moment): number {
        const start = this.start.valueOf();

        if (typeof time !== 'number') {
            time = time.valueOf();
        }
        return this.getXRelative(time - start);
    }

    public getXRelative(time: number) {
        const start = this.start.valueOf();
        const end = this.end.valueOf();
        const fullDuration = end - start;

        return Math.min(100, Math.max(0, time / fullDuration * 100));
    }

    public getHeaderTimes(): Array<HeaderLabel> {
        const offset = (DAY + new Date().getTimezoneOffset() * 60 * 1000) % DAY;
        let firstX = 100;
        const result: HeaderLabel[] = this.getGridPositions().map(t => {
            const x = this.getX(t);
            const showDate = x < 93 && t % (1000 * 60 * 60 * 24) === offset;
            const showTime = x < 95;
            if (showDate) {
                firstX = Math.min(x, firstX);
            }
            return {
                style: `left: ${x}%;`,
                line1: showDate ? moment(t).locale('DE-de').format('dd DD.MM.') : '',
                line2: showTime ? moment(t).format('HH:mm') : ''
            };
        });

        if (firstX > 10) {
            // Initial Date
            result.push({
                style: `left: ${this.getX(this.start.valueOf())}%;`,
                line1: moment(this.start).locale('DE-de').format('dd DD.MM.'),
                line2: ''
            });
        }
        return result;
    }



    public getTooltip(entry: HealthStatusHistoryEntry): string {
        return this.formatEvseHealthEntryLine1(entry);
    }

    public calcStyle(entry: HealthStatusHistoryEntry): string {
        let leftPerc = this.getX(entry.tstStart);
        let rightPerc = this.getX(entry.tstEnd ?? moment.now());

        let border = '';
        if (leftPerc < 0) {
            leftPerc = 0;
            border += 'border-left: dashed black 3px;';
        }

        if (rightPerc > 100) {
            rightPerc = 100;
            border += 'border-right: dashed black 3px;';
        }

        const hidden = rightPerc - leftPerc < 10 ? 'font-size:0' : '';
        rightPerc = 100 - rightPerc;
        return `left: ${leftPerc}%; right: ${rightPerc}%; ${border} ${hidden}`;
    }

    public formatEvseHealthEntryLine1(entry: HealthStatusHistoryEntry): string {
        const start = moment(entry.tstStart);
        const end = moment(entry.tstEnd);
        const startTime = start.format('HH:mm');
        const endTime = end.format('HH:mm');
        const startDate = start.locale('DE-de').format('DD.MM');
        const endDate = end.locale('DE-de').format('DD.MM');

        if (startDate !== endDate) {
            return `am ${startDate}  ${startTime} - ${endDate} ${endTime}`;
        } else {
            return `am ${startDate}  ${startTime} - ${endTime}`;
        }
    }

    public onDragX(dx: number) {
        const time = this.start.valueOf() - this.end.valueOf();
        const size = document.getElementById('graphHeader')?.clientWidth;

        if (!size) {
            return;
        }

        const dt = dx / size * time;

        this.start.add(dt, 'milliseconds');
        this.end.add(dt, 'milliseconds');
        this.updateHealthStatus();
    }

    @HostListener('dblclick')
    public dblClick(): void {
        this.start = this.startIn.clone().subtract(2, 'hours').startOf('hour');
        this.end = this.endIn.clone().add(2, 'hours').startOf('hour');
    }

    public onZoom(event: ZoomEvent): void {
        const header = document.getElementById('graphHeader');
        const left = header?.getBoundingClientRect().left as number;
        const right = left + (header?.clientWidth as number);

        const percentageMouse = (event.clientX - left) / (right - left);
        const s = this.start.valueOf();
        const e = this.end.valueOf();
        const w = e - s;
        const newE = e + w * 0.1 * (1 - percentageMouse) * event.direction;
        const newS = s - w * 0.1 * percentageMouse * event.direction;
        this.start = moment(newS);
        this.end = moment(newE);
        this.updateHealthStatus();
    }

    public getHealthIcon(evse: Evse): string {
        return this.evseHealthDataUtils.getEvseHealthIcon(evse);
    }

    public getCssClass(entry: HealthStatusHistoryEntry) {
        switch (entry.healthStatus) {
            case 'BROKEN':
                return 'broken';
            case 'ON_REPAIR':
                return 'on-repair';
            case 'MAINTENANCE':
                return 'maintenance';
            default:
                return '';
        }
    }

    private updateHealthStatus() {
        const requestBody: EvseHealthHistoryRequest = {
            list: this.sates
        };
        this.reloadExecutor.execute(() => {
            this.healthStatusSubscription.forEach(s => s.unsubscribe());
            this.evse.forEach(e => {
                const newSubject = this.evseHealthService.getEvseHealthStatusFromEvseForState(
                    e.id,
                    this.start.valueOf(),
                    this.end.valueOf(),
                    requestBody
                );
                const subscription = newSubject.subscribe(val => {
                    if (!val) {
                        return;
                    }
                    this.healthStatusEntries[e.id] = newSubject;
                });
                this.healthStatusSubscription.push(subscription);
            });
        });
    }

    private getGridDistance(): number {
        const deltaMinutes = this.end.diff(this.start, 'minutes');

        // Werte mit Geogebra einfach aus einer Linearen regression für ein paar Punkte ermittelt, 0.079+10 ist also irgendwie Zufall ^^
        let regression = deltaMinutes * 0.079 + 10;
        regression = regression - regression % 60;

        // Raster ist kleiner als 1 Tag, dann auf 0,5, 1, 2, 3, 4, 6, 12 oder 24 Stunden Runden, damit jeder Tag bei 0 anfangen kann
        if (regression < 60 * 24) {
            if (regression < 30) { // 0,5 Stunden
                regression = 30;
            } else if (regression < 60) { // 1 Stunde
                regression = 60;
            } else if (regression < 120) { // 2 Stunden
                regression = 120;
            } else if (regression < 3 * 60) {
                regression = 3 * 60;
            } else if (regression < 4 * 60) {
                regression = 4 * 60;
            } else if (regression < 6 * 60) {
                regression = 6 * 60;
            } else if (regression < 12 * 60) {
                regression = 12 * 60;
            } else {
                regression = 24 * 60;
            }
        } else {
            regression = regression - regression % 24 * 60; // auf ganze Tage runden
        }
        return regression;
    }

    private getGridPositions(): number[] {
        const gridPositions: number[] = [];
        const start = this.start.valueOf();
        const startMinutes = start / 1000 / 60;
        const deltaMinutes = this.end.diff(this.start, 'minutes');
        const gridDistance = this.getGridDistance();
        const gridOffset = startMinutes % gridDistance;
        let startGrid = gridDistance - gridOffset;
        const inDayOffset = startGrid % (60 * 24);
        const distBack = startGrid - inDayOffset;
        startGrid = startGrid - (distBack % gridDistance) + new Date().getTimezoneOffset();

        for (let x = startGrid; x < deltaMinutes; x += gridDistance) {
            // Ohne runden kommen hier manchmal seltsame Double-Genauigkeitsfehler rein
            gridPositions.push(Math.round(x * 1000 * 60 + start));
        }
        return gridPositions;
    }
}
