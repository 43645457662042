import { Injectable } from '@angular/core';
import {DialogHandler} from "../../../../services/api-handlers/dialog-handler";
import {
    DatabaseCheck, DatabaseCheckList,
    ExecuteDatabaseCheckAddOrUpdate,
} from "@io-elon-common/frontend-api";
import {POLL_INTERVALS} from "../../../../app.module";
import {ApiService} from "../../../../services/api-handlers/api.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../../services/dialog.service";
import {IEditForm, TDialogOptions} from "../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {EditDatabaseCheckComponent} from "../dialogs/edit-database-check/edit-database-check.component";
import {DatabaseExceptionLogComponent} from "../dialogs/database-exception-log/database-exception-log.component";

@Injectable({
  providedIn: 'root'
})
export class DatabaseCheckService extends DialogHandler<DatabaseCheck, ExecuteDatabaseCheckAddOrUpdate, ExecuteDatabaseCheckAddOrUpdate, {}, DatabaseCheckList, void, void> {

  constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
      super(apiService, "DatabaseCheck", toastr, dialog, dialogService, POLL_INTERVALS.database_check);
  }

    protected getEditConfig(element: DatabaseCheck): TDialogOptions<ExecuteDatabaseCheckAddOrUpdate, IEditForm<ExecuteDatabaseCheckAddOrUpdate>> | Promise<TDialogOptions<ExecuteDatabaseCheckAddOrUpdate, IEditForm<ExecuteDatabaseCheckAddOrUpdate>>> {
        return {
            headline: "Datenbank Check bearbeiten",
            component: EditDatabaseCheckComponent,
            executeCallback: editResult => this.updateWithoutCustomerId(element.id, editResult),
            editElement: {
                name: element.name,
                sql: element.sql,
                period: element.period!
            }
        }
    }

    protected getNewConfig(): TDialogOptions<ExecuteDatabaseCheckAddOrUpdate, IEditForm<ExecuteDatabaseCheckAddOrUpdate>> | Promise<TDialogOptions<ExecuteDatabaseCheckAddOrUpdate, IEditForm<ExecuteDatabaseCheckAddOrUpdate>>> {
        return {
            headline: "Datenbank Check hinzufügen",
            component: EditDatabaseCheckComponent,
            executeCallback: editResult => this.createWithoutCustomerId(editResult),
            editElement: {
                name: "Neuer Datenbank Check",
                sql: "SELECT 1",
                period: 60000,
            }
        };
    }

    delete(id: number, data: {}, showAlerts: boolean = true): Promise<void> {
        return super.deleteWithoutCustomerId(id, data, showAlerts);
    }

    protected executeGet(id: number, showAlerts: boolean = true): Promise<DatabaseCheck> {
        return super.executeGetWithoutCustomerId(id, showAlerts);
    }

    protected executeGetAll(showAlerts: boolean = true): Promise<{ list: DatabaseCheck[] }> {
        return super.executeGetAllWithoutCustomerId(showAlerts);
    }

    public async downloadLog() {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        window.open("api/databaseCheck/fulllogs?timezone=" + tz);
    }

    public showLog() {
        this.dialog.open(DatabaseExceptionLogComponent, {
            data: {
                headline: "Protokoll",
            },
            maxHeight: '90vh'
        });
    }

    public getLiveLogs(): WebSocket {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const secure = document.location.protocol.endsWith("s:");
        const url = (secure ? "wss" : "ws") + "://" + document.location.hostname+ ":" + document.location.port + "/api/databaseCheck/livelogs?timezone=" + timezone;
        return new WebSocket(url);
    }
}
