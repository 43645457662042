import {Component, OnDestroy, OnInit} from '@angular/core';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {Subscription} from 'rxjs';
import {
    ExecuteReservationAddOrUpdate,
    ExecuteReservationDelete, Fleet,
    ReservationInstance,
    VehicleList
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../../services/api-handlers/api.service';
import * as moment from 'moment';
import {ReservationService} from 'src/app/modules/reservations/service/reservation.service';
import {ApiHandler} from "../../../../services/api-handlers/api-handler";

@Component({
    selector: 'app-reservations-list-view',
    templateUrl: './reservations-list-view.component.html',
    styleUrls: ['./reservations-list-view.component.scss']
})
export class ReservationsListViewComponent implements OnInit, OnDestroy {
    public vehicleList!: Promise<VehicleList>;
    private fleetIdSubscription?: Subscription;
    private fleet!: Promise<Fleet>

    public start = moment().subtract(0.5, 'days').startOf('hour');
    public end = moment().add(1.5, 'days').startOf('hour');
    public error = '';

    constructor(
        private readonly fleetService: FleetService,
        private readonly apiService: ApiService,
        private readonly reservationService: ReservationService
    ) {

    }

    ngOnInit(): void {
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(fid => {
            if (fid) {
                this.vehicleList = this.apiService.getVehicles(true, fid, undefined, undefined, ApiHandler.customerId).toPromise();
                this.fleet = this.fleetService.getPromise(fid);
            } else {
                this.error = "Es wurde keine Flotte ausgewählt."
            }
        });
    }

    ngOnDestroy(): void {
        if (this.fleetIdSubscription) {
            this.fleetIdSubscription.unsubscribe();
        }
    }

    public async handleEdit(data: {res: ReservationInstance, mode: ExecuteReservationAddOrUpdate.EditModeEnum}): Promise<void> {
        await this.reservationService.showEditDialog(data.res.reservation, {
            vehicles: (await this.vehicleList).list,
            mode: data.mode,
            instance: data.res
        });
    }

    public async handleDelete(data: { res: ReservationInstance, mode: ExecuteReservationDelete.DeleteModeEnum }): Promise<void> {
        await this.reservationService.showDeleteDialog(data.res.reservation, {deleteMode: data.mode, instanceStartTime: data.res.start});
    }


    public async handleNewReservation(): Promise<void> {
        await this.reservationService.showNewDialog({
            vehicle: (await this.vehicleList).list[0],
            vehicles: (await this.vehicleList).list,
            fleet: (await this.fleet)
        });
    }
}
