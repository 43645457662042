import {Component, Input, OnInit} from '@angular/core';
import {PowerSupply} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {PowerSupplyService} from '../../../../modules/basis/service/power-supply.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-power-supply-link',
  standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        RouterLink
    ],
  templateUrl: './power-supply-link.component.html',
  styleUrl: './power-supply-link.component.scss'
})
export class PowerSupplyLinkComponent implements OnInit{
    @Input()
    public powerSupply: PowerSupply | undefined;

    @Input()
    public powerSupplyId: number | undefined;

    @Input()
    public showAlerts = true;

    public powerSupplyToDisplay: BehaviorSubject<PowerSupply | undefined> = new BehaviorSubject<PowerSupply | undefined>(undefined);

    constructor(
        private readonly powerSupplyService: PowerSupplyService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.updatePowerSupply();

        if (this.powerSupply === undefined && typeof this.powerSupplyId === "number") {
            this.powerSupply = await this.powerSupplyService.getPromise(this.powerSupplyId, this.showAlerts);
        }
    }

    private updatePowerSupply() {
        if (this.powerSupply) {
            this.powerSupplyToDisplay.next(this.powerSupply);
        } else if (typeof this.powerSupplyId === "number") {
            this.powerSupplyToDisplay = this.powerSupplyService.get(this.powerSupplyId, this.showAlerts) as unknown as BehaviorSubject<PowerSupply | undefined>;
        } else {
            this.powerSupplyToDisplay.next(undefined)
        }
    }
}
