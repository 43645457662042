<div *ngIf="canViewNotes">
    <div class="center" style="position: relative">
        <h2 class="center">Notizen</h2>
        <div style="position: absolute; right: 0; top: 0; margin: 0; padding: 0">
            <button *ngIf="canCreateNote" matTooltip="Notiz hinzufügen" mat-button (click)="addNote()">
                <mat-icon class="download-icon" matTooltip="Notiz Hinzufügen">add</mat-icon>
            </button>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" [trackBy]="trackById" matSort>
        <ng-container matColumnDef="tst">
            <th mat-header-cell *matHeaderCellDef class="col-created" mat-sort-header>Erstellt</th>
            <td mat-cell *matCellDef="let element" class="col-created">
                <span [matTooltip]="getToolTip(element)">
                    @if (element.tstEdited !== undefined) {
                        {{ element.tstEdited | date }} {{ element.tstEdited | date:"shortTime" }}
                    } @else {
                        {{ element.tstCreated | date }} {{ element.tstCreated | date:"shortTime" }}
                    }
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef class="col-note" mat-sort-header>Note</th>
            <td mat-cell *matCellDef="let element" class="col-note">
                {{ element.note }}
            </td>
        </ng-container>
        <ng-container matColumnDef="edited_by.name">
            <th mat-header-cell *matHeaderCellDef class="col-user" mat-sort-header>User</th>
            <td mat-cell *matCellDef="let element" class="col-user">

                <span *ngIf="element.user != undefined">{{ element.user.name }}</span>
                <span *ngIf="element.user == undefined">Autorisierter Benutzer</span>

            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="col-actions"></th>
            <td mat-cell *matCellDef="let element" class="col-actions">
                <span *ngIf="element.canEdit">
                    <button mat-icon-button matTooltip="Bearbeiten" (click)="editNote(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </span>
                <span *ngIf="element.canDelete">
                    <button mat-icon-button matTooltip="Löschen" (click)="deleteNote(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
</div>
<ng-template #loadingTemplate>
    <div style="position: relative; height: 300px">
        <mat-spinner class="center-spinner"></mat-spinner>
    </div>
</ng-template>
