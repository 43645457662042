import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {
    EvseList,
    ExecutePermissionGroupAddOrUpdate,
    FleetList,
    PermissionGroupList,
    PermissionImpl,
    PermissionType,
    ReservationList,
    UserList,
    VehicleList
} from '@io-elon-common/frontend-api';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {EvseService} from '../../../evse/service/evse.service';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {UserService} from '../../../users/service/user.service';
import {ReservationService} from '../../../reservations/service/reservation.service';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import { PermissionGroupService } from 'src/app/modules/permissions/service/permission-group.service';

@Component({
    selector: 'app-edit-permission-group-dialog',
    templateUrl: './edit-permission-group-dialog.component.html',
    styleUrls: ['./edit-permission-group-dialog.component.scss']
})
export class EditPermissionGroupDialogComponent implements OnInit, IEditForm<ExecutePermissionGroupAddOrUpdate> {
    @Input() public edit!: boolean;
    @Input() public data!: ExecutePermissionGroupAddOrUpdate;

    public allUsers!: BehaviorSubject<UserList | undefined>;
    public allVehicles!: BehaviorSubject<VehicleList | undefined>;
    public allEvses!: BehaviorSubject<EvseList | undefined>;
    public allFleets!: BehaviorSubject<FleetList | undefined>;
    public allReservations!: BehaviorSubject<ReservationList | undefined>;
    public allPermissionTypes!: Promise<PermissionType[]>;
    public allGroups!: BehaviorSubject<PermissionGroupList | undefined>;

    public allPossibleImpls!: Array<{name: PermissionImpl, displayName: string}>

    constructor(
        private systemService: SystemService,
        private evseService: EvseService,
        private vehicleService: VehicleService,
        private userService: UserService,
        private reservationService: ReservationService,
        private fleetService: FleetService,
        private permissionGroupService: PermissionGroupService
    ) {
    }

    ngOnInit(): void {
        this.allPermissionTypes = this.systemService.getSystemInfo().then(si => si.permissions.filter(p => p.visible));
        this.allUsers = this.userService.getAll();
        this.allVehicles = this.vehicleService.getAll();
        this.allEvses = this.evseService.getAll();
        this.allFleets = this.fleetService.getAll();
        this.allReservations = this.reservationService.getAll();
        this.allGroups = this.permissionGroupService.getAll();

        this.typeChanged();
    }

    public async typeChanged() {

    }

    validate(): string[] {
        return [];
    }
}
