import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {Basis} from '@io-elon-common/frontend-api';
import {BasisService} from '../../service/basis.service';
import {AbstractHistoryGraph, BackgroundLegend} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {PowerSupplyService} from '../../service/power-supply.service';
import Annotation = dygraphs.Annotation;


declare const smoothPlotter: any;

interface IElectricityCostsGraphData{
    data: Array<Array<Date | number | null>>;
    events: Annotation[];
}

@Component({
    selector: 'app-electricity-costs-plan',
    templateUrl: './electricity-costs-plan.component.html',
    styleUrls: ['./electricity-costs-plan.component.scss']
})
export class ElectricityCostsPlanComponent extends AbstractHistoryGraph<IElectricityCostsGraphData> implements OnInit  {

    @Input()
    public basis!: Basis;

    public autoReload = false;
    public autoReloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private readonly element: ElementRef,
        private readonly powerSupplyService: PowerSupplyService,
        private basisService: BasisService
    ) {
        super();
    }

    public async ngOnInit() {
        await this.init(this.element);
    }

    getBackgroundLegend(): BackgroundLegend[] {
        return [];
    }

    protected async getConfig(): Promise<dygraphs.Options> {
        return {
            axes: {
                y: {
                    axisLabelFormatter: (w: number | Date) => {
                        return '<span>' + ((w as number)/10).toFixed(1) + ' ct/kWh</span>';
                    }
                }
            },
            labels: ['x',
                'Kosten (Cent/kWh)',
            ],
            colors: [
                '#4EB2DC',
            ],
            series: {
                'Kosten (Cent/kWh)': {
                    axis: 'y1',
                    plotter: smoothPlotter,
                    strokePattern: [1, 1],
                    drawPoints: false,
                },
                Events: {drawPoints: false, strokeWidth: 0}
            },
        };
    }

    protected async getMaxY2(): Promise<number> {
        return Promise.resolve(0);
    }

    protected async loadData(start: number, end: number): Promise<IElectricityCostsGraphData> {
        const x = await this.basisService.getDynamicElectricityCostsListByTime(this.basis.id,
            start, end);
        const array2D : Array<Array<number|Date>> = new Array<Array<number>>();
        x.forEach((entry, index) => {
            array2D.push([new Date(entry.start), entry.marketPrice]);
            }
        );
        array2D.sort((a, b) => {
            let compA = a[0];
            let compB = b[0];
            if (!(typeof a[0] === 'number')) {
                compA = a[0].getTime();
            }
            if (!(typeof b[0] === 'number')) {
                compB = b[0].getTime();
            }
            // @ts-ignore
            return compA - compB;
        });
        return {
            data: array2D,
            events: []
        };
    }
}
