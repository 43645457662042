import {Injectable} from '@angular/core';
import {DialogHandler} from '../../../../services/api-handlers/dialog-handler';
import {ExecuteGatewayRegisterAddOrUpdate, GatewayRegister, GatewayRegisterList} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../../services/dialog.service';
import {POLL_INTERVALS} from '../../../../app.module';
import {TDialogOptions} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditGatewayRegisterComponent} from '../dialog/edit-gateway-register/edit-gateway-register.component';
import {CacheUpdater} from '../../../../services/api-handlers/cacheManager';
import {BehaviorSubject} from 'rxjs';
import {ApiHandler} from "../../../../services/api-handlers/api-handler";

@Injectable({
  providedIn: 'root'
})
export class GatewayRegisterService extends DialogHandler<GatewayRegister, ExecuteGatewayRegisterAddOrUpdate, ExecuteGatewayRegisterAddOrUpdate, {}, GatewayRegisterList, any, any> {
    private registerCache!: CacheUpdater<GatewayRegister[], number>

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "GatewayRegister", toastr, dialog, dialogService, POLL_INTERVALS.gatewayRegister);
        this.registerCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    protected getEditConfig(element: GatewayRegister, editDialogConfigArgs: any): TDialogOptions<ExecuteGatewayRegisterAddOrUpdate, EditGatewayRegisterComponent> {
        return {
            headline: "Gateway Register Bearbeiten",
            component: EditGatewayRegisterComponent,
            executeCallback: editResult => this.update(element.id, editResult),
            editElement: {
                access: element.access,
                registerType: element.registerType,
                gatewayId: element.gatewayId,
                length: element.length,
                deviceId: element.deviceId,
                field: element.field,
                encoding: element.encoding,
                order: element.order,
                deviceType: element.deviceType,
                modbusId: element.modbusId,
                startAddress: element.startAddress,
            }
        };
    }

    protected getNewConfig(newDialogConfigArgs: any): TDialogOptions<ExecuteGatewayRegisterAddOrUpdate, EditGatewayRegisterComponent> {
        return {
            headline: "Gateway Register hinzufügen",
            component: EditGatewayRegisterComponent,
            executeCallback : editResult => this.create(editResult),
            editElement: {
                access: 'RO',
                startAddress: -1,
                field: "",
                gatewayId: newDialogConfigArgs.gatewayId,
                length: -1,
                modbusId: -1,
                order: "",
                deviceType: 'Meter',
                encoding: "",
                registerType: "",
                deviceId: -1
            }
        }
    }

    public getRegistersOfGateway(id: number): BehaviorSubject<GatewayRegister[] | undefined> {
        return this.registerCache.getOrCreateGet(
                id,
            () => this.apiService.getGatewayRegistersByGateway(true, id, undefined, undefined, ApiHandler.customerId).toPromise().then(value => value.list)
        ).data;
    }
}
