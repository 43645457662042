import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UnknownOcppClient} from "@io-elon-common/frontend-api";
import {FleetService} from "../../../vehicle/service/fleet.service";

@Component({
    selector: 'app-details-unknown-cpi-dialog',
    templateUrl: './details-unknown-cpi-dialog.component.html',
    styleUrls: ['./details-unknown-cpi-dialog.component.scss']
})
export class DetailsUnknownCpiDialogComponent implements OnInit {

    public fleetName!: string;
    public MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: UnknownOcppClient,
        private readonly fleetService: FleetService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const fleets = await this.fleetService.getAllPromise()
        this.fleetName = fleets.list.find(f => f.id === this.dialogData.fleetId)?.name || "---"
    }

    public tryFormatJson(raw: string): string {
        try {
            return JSON.stringify(JSON.parse(raw), undefined, 2);
        } catch (e) {
            return raw;
        }
    }

    public isMinus1(num: number) {
        // The check dialogData.connectorIdCount ==! -1 in Template will break the compiler... ?.?
        return num === -1
    }
}
