import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Fleet, Meter} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {MeterService} from '../../../../modules/meter/service/meter.service';
import {FleetService} from '../../../../modules/vehicle/service/fleet.service';
import {RouterLink} from "@angular/router";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
    selector: 'app-meter-link',
    standalone: true,
    imports: [
        RouterLink,
        AsyncPipe,
        NgIf
    ],
    templateUrl: './meter-link.component.html',
    styleUrls: ['./meter-link.component.scss']
})
export class MeterLinkComponent implements OnInit, OnChanges {
    @Input()
    public meter: Meter | undefined;

    @Input()
    public meterId: number | undefined;

    @Input()
    public showAlerts = true;

    public meterToDisplay: BehaviorSubject<Meter | undefined> = new BehaviorSubject<Meter | undefined>(undefined);

    public fleetId: number | undefined;
    private fleets: Fleet[] = [];

    constructor(
        private readonly meterService: MeterService,
        private readonly fleetService: FleetService
    ) { }

    async ngOnInit(): Promise<void> {
        this.fleets = await this.fleetService.getAllPromise(this.showAlerts).then(list => list.list);
        this.updateMeter();

        if (this.meter === undefined && typeof this.meterId === "number") {
            this.meter = await this.meterService.getPromise(this.meterId, this.showAlerts);
        }

        this.fleets.forEach(fleet => {
            if (fleet.base.id === this.meter?.basis?.id) {
                this.fleetId = fleet.id;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateMeter();
    }

    private updateMeter() {
        if (this.meter) {
            this.meterToDisplay.next(this.meter);
        } else if (typeof this.meterId === "number") {
            this.meterToDisplay = this.meterService.get(this.meterId, this.showAlerts) as unknown as BehaviorSubject<Meter | undefined>;
        } else {
            this.meterToDisplay.next(undefined)
        }
    }
}
