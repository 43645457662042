import {Injectable} from '@angular/core';
import {EvseService} from '../../modules/evse/service/evse.service';
import {CustomerService} from '../../services/api-handlers/customer.service';
import {BasisService} from '../../modules/basis/service/basis.service';
import {FleetService} from '../../modules/vehicle/service/fleet.service';
import {MeterService} from '../../modules/meter/service/meter.service';
import {PowerSupplyService} from '../../modules/basis/service/power-supply.service';
import {RfidService} from '../../modules/rfid/service/rfid.service';
import {UserService} from '../../modules/users/service/user.service';
import {VehicleService} from '../../modules/vehicle/service/vehicle.service';
import {isNumeric} from 'rxjs/internal-compatibility';
import {num} from '../helper/util-functions';

@Injectable({
    providedIn: 'root'
})

export class ElementIdFormatter {
    private formatter: ((text: string, list?: any[]) => string)[] = []

    public constructor(
        protected readonly evseService: EvseService,
        protected readonly customerService: CustomerService,
        protected readonly basisService: BasisService,
        protected readonly fleetService: FleetService,
        protected readonly meterService: MeterService,
        protected readonly powerSupplyService: PowerSupplyService,
        protected readonly rfidService: RfidService,
        protected readonly userService: UserService,
        protected readonly vehicleService: VehicleService
    ) {
        this.createFunctions()
    }

    public format(text: string) {
        this.formatter.forEach(f => {
            text = f(text);
        })
        return text
    }

    private async createFunctions() {
        const basis = (await this.basisService.getAllPromise()).list;
        const customers = (await this.customerService.getAllPromise()).list;
        const evses = (await this.evseService.getAllPromise()).list;
        const fleets = (await this.fleetService.getAllPromise()).list;
        const meters = (await this.meterService.getAllPromise()).list;
        const powerSupplies = (await this.powerSupplyService.getAllPromise()).list;
        const rfids = (await this.rfidService.getAllPromise()).list;
        const users = (await this.userService.getAllPromise()).list;
        const vehicles = (await this.vehicleService.getAllPromise()).list;

        this.formatter.push((text: string, list = basis): string => {
            const matches = [...text.matchAll(/\[BasisId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                let name: undefined;
                matches.forEach(m => {
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[BasisId[' + m + ']]', name || 'Standort (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = customers): string => {
            const matches = [...text.matchAll(/\[CustomerId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                let name: undefined;
                matches.forEach(m => {
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[CustomerId[' + m + ']]', name || 'Kunde (' + m + ')')
                })
            }
            return text
        });
        this.formatter.push((text: string, list = evses): string => {
            const matches = [...text.matchAll(/\[EvseId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                matches.forEach(m => {
                    let name: undefined;
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[EvseId[' + m + ']]', name || 'Ladepunkt (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = fleets): string => {
            const matches = [...text.matchAll(/\[FleetId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                let name: undefined;
                matches.forEach(m => {
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[FleetId[' + m + ']]', name || 'Flotte (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = meters): string => {
            const matches = [...text.matchAll(/\[MeterId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                matches.forEach(m => {
                    let name: undefined;
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[MeterId[' + m + ']]', name || 'Meter (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = powerSupplies): string => {
            const matches = [...text.matchAll(/\[PowerSupplyId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                matches.forEach(m => {
                    let name: undefined;
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[PowerSupplyId[' + m + ']]', name || 'PowerSupply (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = rfids): string => {
            const matches = [...text.matchAll(/\[RfidCardId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                matches.forEach(m => {
                    let name: undefined;
                    if (isNumeric(m)) {
                        const rfid = list.find(e => e.id === num(m));
                        name = rfid?.name ?? rfid?.token
                    }
                    text = text.replace('[RfidCardId[' + m + ']]', name || 'RfidCard (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = users): string => {
            const matches = [...text.matchAll(/\[UserId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                matches.forEach(m => {
                    let name: string | undefined;
                    if (isNumeric(m)) {
                        const user = list.find(e => e.id === num(m));
                        name = user ? user.firstname + " " + user.lastname : undefined
                    }
                    text = text.replace('[UserId[' + m + ']]', name || 'Benutzer (' + m + ')')
                })
            }
            return text
        })
        this.formatter.push((text: string, list = vehicles): string => {
            const matches = [...text.matchAll(/\[VehicleId\[(.*?)]]/g)].map(match => match[1]);
            if (matches.length > 0) {
                matches.forEach(m => {
                    let name: undefined;
                    if (isNumeric(m)) {
                        name = list.find(e => e.id === num(m))?.name
                    }
                    text = text.replace('[VehicleId[' + m + ']]', name || 'Fahrzeug (' + m + ')')
                })
            }
            return text
        })
    }
}
