import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {AccountingChargingSession} from "@io-elon-common/frontend-api";
import {DurationPipe} from "../../../../helper/duration.pipe";
import {HelpBoxComponentDialog} from "../../../help-box/help-box-dialog/help-box-component-dialog.component";
import {HelpBoxIconComponent} from "../../../help-box/help-box-icon/help-box-icon.component";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-details-dialog-accounting',
  standalone: true,
    imports: [
        DurationPipe,
        HelpBoxComponentDialog,
        HelpBoxIconComponent,
        MatButton,
        MatDialogActions,
        MatDialogContent
    ],
  templateUrl: './details-dialog-accounting.component.html',
  styleUrl: './details-dialog-accounting.component.scss'
})
export class DetailsDialogAccountingComponent {


    constructor(
        public readonly dialogRef: MatDialogRef<DetailsDialogAccountingComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: AccountingChargingSession
    ) { }

    public formatPrice(price: number, unit: string) {
        if (unit.includes('€')) {
            return price.toFixed(Math.max(2, Math.min(4, (price.toString().split('.')[1]?.length || 0)))) + " " + unit;
        } else {
            return price + " " + unit;
        }
    }
}
