<mat-icon class="sun-icon">home</mat-icon>
<div class="all">
    <div class="info">
        <span class="name">{{load.name}}</span><div *ngIf="isMeterBased();then meterIcon"></div>
        <mat-hint matTooltip="Aktuelle Gebäudelast/Maximal erwarteter Peak für die nächsten 24 Stunden">{{(getPwr().sum() / 1000).toFixed(1)}}kW / {{(getMaxPwr() / 1000).toFixed(1)}}kW</mat-hint>
        <app-electricity-bar [ampsMode]="ampsMode" [electricity]="getElectricity()" [maxAmps]="getMaxAmpsPerPhase()"></app-electricity-bar>
    </div>
    <div class="load-buttons">
        <button [disabled]="!load.canEdit" mat-icon-button (click)="handleEditLoad(load)"><mat-icon>edit</mat-icon></button>
        <button [disabled]="!load.canDelete" mat-icon-button (click)="handleDeleteLoad(load)"><mat-icon>delete</mat-icon></button>
    </div>
</div>
<ng-template #meterIcon>
    <mat-icon class="sun-icon">monitor_heart</mat-icon>
</ng-template>
