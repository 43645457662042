<div class="table-frame p-2">
    <h2>OCPP-Abrechnungsbackend-Accounts</h2>
    <div *ngIf="(ocppUplinks | async) as ocppUplinks; else loading">
        <app-ocpp-uplinks-table [ocppUplinks]="ocppUplinks.list"></app-ocpp-uplinks-table>
    </div>
</div>
<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button mat-raised-button color="primary" (click)="handleNewOcppUplink()">Account hinzufügen</button>
</div>
<ng-template #loading>Loading...</ng-template>
