import {Component, Input, OnInit} from '@angular/core';
import {DialogType} from '../dialogType';
import {BehaviorSubject} from 'rxjs';
import {MatIcon} from "@angular/material/icon";

@Component({
    selector: 'app-help-box-icon',
    standalone: true,
    templateUrl: './help-box-icon.component.html',
    imports: [
        MatIcon
    ],
    styleUrl: './help-box-icon.component.scss'
})
export class HelpBoxIconComponent implements OnInit{
    @Input()
    public iconType: DialogType = DialogType.HELP;
    public icon!: string;

    public clicked: BehaviorSubject<undefined | string> = new BehaviorSubject<undefined | string>(undefined)

    public hidden = false;

    onClick() {
        this.clicked.next("clicked");
    }

    ngOnInit(): void {
        switch (this.iconType) {
            case DialogType.INFO:
                this.icon = "info"
                break;
            case DialogType.HELP:
                this.icon = "help"
                break;
            case DialogType.SUCCESS:
                this.icon = "check_circle";
                break;
            case DialogType.WARNING:
                this.icon = "alert alert-warning";
                break;
            default:
                this.icon = "info"
                break;
        }
    }
}
