<div class="table-frame">
    <h2>Datenbank Checks</h2>
    <p>Die hier Angelegten Checks werden periodisch auf der Datenbank ausgeführt. Das Feature ist dazu gedacht auch verteilte Datenbanken auf Funktionalität prüfen zu können.</p>
    <p>Die Statements müssen SELECT-Statements sein und genau eine Zeile zurückgeben. Beispiele: "SELECT 1" "SELECT COUNT(*) FROM vehicles"</p>
    <p>Es kann bis zu eine Minute dauern bis ein Check das erste mal ausgeführt würd oder Änderungen angewendet werden.</p>
    <p>Zusätzlich gibt es ein Exception Log, komplett unabhängig von den Checks. Dort landen alle Exceptions die beim Erzeugen eines Entity Managers aufgetreten sind.</p>
    <div *ngIf="(checks | async) as checks ; else loading">
        <h3>Total Fails: {{checks.totalFails}} / {{checks.totalAttempts}}</h3>
        <app-database-check-table [checks]="checks"></app-database-check-table>
    </div>
    <div class="buttons d-flex flex-row justify-content-end gap-3 align-items-stretch">
        <button mat-raised-button color="primary" (click)="handleLog()"><mat-icon>terminal</mat-icon> Exceptions Protokoll</button>
        <button mat-raised-button color="primary" (click)="handleNewCheck()"><mat-icon>add</mat-icon> Datenbank Check hinzufügen</button>
    </div>
    <ng-template #loading>Loading...</ng-template>
</div>
