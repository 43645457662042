import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PermissionGroup, PermissionGroupList} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {PermissionService} from '../../service/permission.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import { PermissionGroupService } from 'src/app/modules/permissions/service/permission-group.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {BehaviorSubject} from 'rxjs';


@Component({
  selector: 'app-permission-group-table',
  templateUrl: './permission-group-table.component.html',
  styleUrls: ['./permission-group-table.component.scss'],
  animations: [
    trigger('permissionsExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', padding:'0px'})),
      state('expanded', style({height: '*', padding:'5px'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),]
})
export class PermissionGroupTableComponent extends AbstractTableComponent implements OnInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() permissionGroups!: BehaviorSubject<PermissionGroupList | undefined>;

    displayedColumns: string[] = ['id', 'name', 'users', 'permissions', 'expand', 'actions'];

    dataSource = new MatTableDataSource<PermissionGroup>([]);
    selection = new SelectionModel<PermissionGroup>(true, []);
    private expandedPermissionId: number | undefined;

    constructor(
        private readonly permissionGroupService: PermissionGroupService,
        private readonly permissionService: PermissionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.permissionGroups = this.permissionGroupService.getAll();
    }

    public async edit(permissionGroup: PermissionGroup): Promise<void> {
        await this.permissionGroupService.showEditDialog(permissionGroup);
    }

    public async delete(permissionGroup: PermissionGroup): Promise<void> {
        await this.permissionGroupService.showDeleteDialog(permissionGroup, {});
    }

    public async add(permissionGroup: PermissionGroup): Promise<void> {
        await this.permissionService.showNewDialog(permissionGroup, {width:"30%", minWidth:"55em"});
    }

    public getPermissions(permissionGroup: PermissionGroup): string {
        if(permissionGroup.permissions.length > 3) {
            return permissionGroup.permissions.length + " Berechtigungen"
        }
        return permissionGroup.permissions.map(p => p.type.displayName).sort().join(", ");
    }

    public getUsers(permissionGroup: PermissionGroup): string {
        if(permissionGroup.users.list.length > 3) {
            return permissionGroup.users.list.length + " Benutzer"
        }
        return permissionGroup.users.list.map(u => u.name).sort().join(", ");
    }

    public setExpandedPermissionId(id: number) {
        if (this.expandedPermissionId === id) {
            this.expandedPermissionId = undefined;
        } else {
            this.expandedPermissionId = id;
        }
    }

    public isExpanded(id: number): boolean {
        return this.expandedPermissionId !== undefined && this.expandedPermissionId === id;
    }
}
