<div *ngIf="(loads | async) as loads; else loading">
    <div>
        <app-load-config-tile [load]="load" *ngFor="let load of loads.list"></app-load-config-tile>
    </div>
    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <button mat-raised-button color="primary" (click)="newLoad()">Last hinzufügen</button>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
