<form [formGroup]="chargingGroupForm">

    <div class="d-flex flex-column gap-3 align-items-start">

        <!-- Name Input Field -->
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name"/>
        </mat-form-field>

        <div class="d-flex flex-row gap-3">
            <!-- Price Per Energy Input Field -->
            <mat-form-field appearance="fill">
                <mat-label>Energiepreis</mat-label>
                <input matInput formControlName="pricePerEnergy" type="number"/>€/kWh
            </mat-form-field>

            <!-- Price Per Session Input Field -->
            <mat-form-field appearance="fill">
                <mat-label>Grundkosten je Ladevorgang</mat-label>
                <input matInput formControlName="pricePerSession" type="number"/>€
            </mat-form-field>
        </div>

        <div class="d-flex flex-row gap-3">
            <!-- Price Per Hour Input Field -->
            <mat-form-field appearance="fill" class="nowrap">
                <mat-label>Preis pro Stunde</mat-label>
                <input matInput formControlName="pricePerHour" type="number"/>€/Stunde
            </mat-form-field>

            <!-- Initial Free Minutes Input Field -->
            <mat-form-field appearance="fill"
                            matTooltip="Wenn der Preis pro Stunde erst verzögert berechnet werden soll, kann hier angegeben werden, wie viele Minuten frei sind, bevor der Preis berechnet wird. Dies gilt nur für den Stundenpreis. Die kWh werden immer von Anfang an abgerechnet.">
                <mat-label>Freiminuten</mat-label>
                <input matInput formControlName="initialFreeMinutes" type="number"/>Minuten
            </mat-form-field>
        </div>

        <hr>

        <div class="d-flex flex-column gap-3">

            <div class="d-flex flex-row gap-5">
                <h2>Ladepunkte</h2>
                <mat-checkbox
                    class="select-all-checkbox"
                    [checked]="allEvseSelected(evses)"
                    [indeterminate]="someEvseSelected(evses)"
                    (change)="onSelectAllEvsesChange($event, evses)"
                >
                    Alle
                </mat-checkbox>

            </div>
            @for (basisGroup of getEvseGropedByBasis(); track basisGroup.key) {
                <div>
                    <div>
                        <mat-checkbox
                            class="select-all-checkbox"
                            [checked]="allEvseSelected(basisGroup.values)"
                            [indeterminate]="someEvseSelected(basisGroup.values)"
                            (change)="onSelectAllEvsesChange($event, basisGroup.values)"
                        >
                            <strong>{{ basisGroup.key }}</strong>
                        </mat-checkbox>
                    </div>

                    <div class="d-flex flex-row flex-wrap gap-3">
                        @for (evse of basisGroup.values; track evse.id) {
                            <mat-checkbox
                                class="check-list-item"
                                [checked]="chargingGroupForm.controls.evses.value.includes(evse.id)"
                                (change)="onEvseCheckboxChange($event, evse.id)"
                            >
                                {{ evse.name }}
                            </mat-checkbox>

                        }
                    </div>
                </div>
            }
        </div>

        <hr>

        <div class="d-flex flex-row gap-5">
            <h2>RFID-Karten</h2>
            <mat-checkbox
                class="select-all-checkbox"
                [checked]="allRfidSelected(rfids)"
                [indeterminate]="someRfidSelected(rfids)"
                (change)="onSelectAllRfidsChange($event, rfids)"
            >
                Alle
            </mat-checkbox>
        </div>

        <!-- Include Guests Checkbox -->
        <mat-checkbox formControlName="includeGuests" matTooltip="Wenn diese Option ausgewählt wird, wird diese Ladegruppe auch auf Ladevorgänge angewendet, für die kein RFID Karte verwendet wurde. Ebenfalls wird diese Gruppe angewendet, wenn eine RFID Karte verwendet wird, die keiner anderen Gruppe direkt hinzugefügt wurde.">Gastfahrzeuge & Ladevorgänge ohne andere Ladegruppen</mat-checkbox>
        <div class="d-flex flex-row flex-wrap gap-3">
            @for (rfid of rfids; track rfid.id) {

                <mat-checkbox
                    class="check-list-item"
                    [matTooltip]="'Token:' + rfid.token"
                    [checked]="chargingGroupForm.controls.rfids.value.includes(rfid.id)"
                    (change)="onRfidCheckboxChange($event, rfid.id)"
                >
                        {{ rfid.name }}
                </mat-checkbox>
            }
        </div>
    </div>
</form>
