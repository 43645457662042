<table mat-table [dataSource]="dataSource" class="full-width-table" [trackBy]="trackBy">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
            <a [routerLink]="'/gateways/gateway/'+element.id" class="mat-row-link" [class.disabled]="!canViewRegisters"></a>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-name">Name</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-name">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="lastContact">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-name">Letzter Kontakt</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-name">
            <div *ngIf="element.lastContact > 0">{{element.lastContact | age}}</div>
            <div *ngIf="element.lastContact == 0">Bisher keine Kontakt</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="gatewayAddress">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Netz</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{element.address}}/{{element.mask}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button (click)="showLog(element)" mat-icon-button matTooltip="Protokoll anzeigen"><mat-icon class="iblack">terminal</mat-icon></button>
            <button *ngIf="element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button *ngIf="element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons="true">
</mat-paginator>

