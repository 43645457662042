import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LoadService} from "../../../loads/service/load.service";
import {ExecuteMeterAddOrUpdate, MeterList} from "@io-elon-common/frontend-api";
import {PowerSupplyService} from "../../../basis/service/power-supply.service";
import {MeterFormularHistoricValues} from "@io-elon-common/frontend-api/lib/model/models";
import {DialogType} from '../../../../shared/components/help-box/dialogType';
import {MeterService} from "../../service/meter.service";

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

@Component({
  selector: 'app-meter-formula-simulation',
  standalone: false,
  templateUrl: './meter-formula-simulation.component.html',
  styleUrl: './meter-formula-simulation.component.scss'
})
export class MeterFormulaSimulationComponent implements OnInit, OnDestroy{

    public id: number | null = null;
    public meters!: Promise<MeterList>;
    public data?: ExecuteMeterAddOrUpdate;
    public drawEditor = true;

    public start: number = Date.now() - DAY
    public end: number = Date.now()
    public loading = false;
    public result: MeterFormularHistoricValues | null = null;
    public readonly DialogType = DialogType;
    private subscription: any;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly loadService: LoadService,
        private readonly psService: PowerSupplyService,
        private readonly meterService: MeterService
    ) {
    }

    ngOnInit() {
        this.meters = this.meterService.getAllPromise();
        this.data = this.meterService.generateCreateObject(-1);
        this.data.type = "FormulaLoad"

        this.subscription = this.route.paramMap.subscribe(params => {
            this.id = parseInt(params.get('id') ?? '', 10); // Parse to number
            this.idUpdate();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe(); // Clean up subscription
        }
    }

    public idUpdate() {
        if(this.id && !isNaN(this.id)) {
            this.meterService.getPromise(this.id).then(meter => {
                this.data = this.meterService.generateEditObject(meter);
                this.drawEditor = false;
                setTimeout(() => this.drawEditor = true, 10)
            });
        }
    }

    public parseDate(dateStr: string) {
        return new Date(dateStr).getTime();
    }

    public get dateWarning(): string {
        const duration = this.end - this.start;
        if(duration <= DAY) {
            return ""
        }

        let msg = "Zeitraum ist:"
        const d = Math.floor(duration / DAY);
        const h = Math.floor((duration % DAY) / HOUR);
        const m = Math.floor((duration % HOUR) / MINUTE);

        if(d > 0) {
            msg += " " + d + "d"
        }
        if(h > 0) {
            msg += " " + h + "h"
        }
        if(m > 0) {
            msg += " " + m + "m"
        }
        msg += ". Zeiträume von mehr als einem Tag können erheblichen Einfluss auf die Backend Performance haben!"

        return msg;
    }

    public async simulate() {
        if(!this.data) {
            alert("Data is missing");
            return;
        }

        this.loading = true;
        this.result = null;
        try {
            this.result = await this.meterService.simulateFormularMeter(this.data, this.start, this.end);
        } finally {
            this.loading = false;
        }
    }
}
