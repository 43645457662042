import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {Evse, ExecuteChargingGroupAddOrUpdate, Rfid} from '@io-elon-common/frontend-api';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {groupBy} from '../../../../shared/helper/util-functions';

interface ChargingGroupFormControls {
    name: FormControl<string>;
    evses: FormControl<number[]>;
    rfids: FormControl<number[]>;
    includeGuests: FormControl<boolean>;
    pricePerEnergy: FormControl<number>;
    pricePerSession: FormControl<number>;
    pricePerHour: FormControl<number>;
    initialFreeMinutes: FormControl<number>;
}


@Component({
  selector: 'app-charging-group-edit-dialog',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule
    ],
  templateUrl: './charging-group-edit-dialog.component.html',
  styleUrl: './charging-group-edit-dialog.component.scss'
})
export class ChargingGroupEditDialogComponent implements OnChanges, OnInit, IEditForm<ExecuteChargingGroupAddOrUpdate>{

    constructor(private fb: FormBuilder) {
        this.chargingGroupForm = this.fb.group<ChargingGroupFormControls>({
            name: new FormControl<string>('',
                { nonNullable: true, validators: [Validators.required, Validators.minLength(1)] }
            ),
            evses: new FormControl<number[]>([],
                { nonNullable: true, validators: Validators.required }
            ),
            rfids: new FormControl<number[]>([],
                { nonNullable: true, validators: Validators.required }
            ),
            includeGuests: new FormControl<boolean>(false,
                { nonNullable: true }
            ),
            pricePerEnergy: new FormControl<number>(0,
                { nonNullable: true, validators: [Validators.required, Validators.min(0)] }
            ),
            pricePerSession: new FormControl<number>(0,
                { nonNullable: true, validators: [Validators.required, Validators.min(0)] }
            ),
            pricePerHour: new FormControl<number>(0,
                { nonNullable: true, validators: [Validators.required, Validators.min(0)] }
            ),
            initialFreeMinutes: new FormControl<number>(0,
                { nonNullable: true, validators: [Validators.required, Validators.min(0)] }
            )
        });

        // Subscribe to form value changes to update the data object
        this.chargingGroupForm.valueChanges.subscribe((formValues) => {
            if (this.data) {
                this.data.name = formValues.name!;
                this.data.evses = formValues.evses!;
                this.data.rfids = formValues.rfids!;
                this.data.includeGuests = formValues.includeGuests!;
                this.data.pricePerEnergy = formValues.pricePerEnergy!;
                this.data.pricePerSession = formValues.pricePerSession!;
                this.data.pricePerHour = formValues.pricePerHour!;
                this.data.initialFreeMinutes = formValues.initialFreeMinutes!;
            }
        });

    }
    @Input()
    public evses!: Evse[]
    @Input()
    public rfids!: Rfid[]
    @Input()
    public data!: ExecuteChargingGroupAddOrUpdate;
    @Input()
    public edit!: boolean;

    public chargingGroupForm: FormGroup<ChargingGroupFormControls>;

    public ngOnInit(): void {
        if(this.data) {
            this.chargingGroupForm.patchValue({
                name: this.data.name,
                evses: this.data.evses,
                rfids: this.data.rfids,
                includeGuests: this.data.includeGuests,
                pricePerEnergy: this.data.pricePerEnergy,
                pricePerSession: this.data.pricePerSession,
                pricePerHour: this.data.pricePerHour,
                initialFreeMinutes: this.data.initialFreeMinutes
            })
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && this.data) {
            this.chargingGroupForm.patchValue({
                name: this.data.name,
                evses: this.data.evses,
                rfids: this.data.rfids,
                includeGuests: this.data.includeGuests,
                pricePerEnergy: this.data.pricePerEnergy,
                pricePerSession: this.data.pricePerSession,
                pricePerHour: this.data.pricePerHour,
                initialFreeMinutes: this.data.initialFreeMinutes
            });
        }
    }


    validate(): string[] | Promise<string[]> {
        const result = [];

        this.chargingGroupForm.markAllAsTouched();
        this.chargingGroupForm.updateValueAndValidity();
        if (this.chargingGroupForm.controls.name.invalid) {
            result.push('Der Name ist erforderlich.');
        }

        if (this.chargingGroupForm.controls.evses.invalid) {
            result.push('Es muss mindestens ein Ladepunkt ausgewählt werden.');
        }

        if (this.chargingGroupForm.controls.rfids.invalid) {
            result.push('Es muss mindestens ein RFID ausgewählt werden.');
        }

        if (this.chargingGroupForm.controls.pricePerEnergy.invalid) {
            result.push('Der Preis pro Energieeinheit muss angegeben und größer oder gleich 0 sein.');
        }

        if (this.chargingGroupForm.controls.pricePerSession.invalid) {
            result.push('Der Preis pro Sitzung muss angegeben und größer oder gleich 0 sein.');
        }

        if (this.chargingGroupForm.controls.pricePerHour.invalid) {
            result.push('Der Preis pro Stunde muss angegeben und größer oder gleich 0 sein.');
        }

        if (this.chargingGroupForm.controls.initialFreeMinutes.invalid) {
            result.push('Die anfänglichen Freiminuten müssen angegeben und größer oder gleich 0 sein.');
        }
        return [];
    }

    public onEvseCheckboxChange(event: MatCheckboxChange, evseId: number): void {
        // Get the current list of selected evse ids
        const currentEvses: number[] = this.chargingGroupForm.controls.evses.value || [];

        if (event.checked) {
            // Add the evse id if it does not exist
            if (!currentEvses.includes(evseId)) {
                this.chargingGroupForm.controls.evses.setValue([...currentEvses, evseId]);
            }
        } else {
            // Remove the evse id if it's unchecked
            const updatedEvses = currentEvses.filter(id => id !== evseId);
            this.chargingGroupForm.controls.evses.setValue(updatedEvses);
        }
    }

    public onRfidCheckboxChange(event: MatCheckboxChange, rfidId: number): void {
        // Get the current list of selected RFID ids
        const currentRfids: number[] = this.chargingGroupForm.controls.rfids.value || [];

        if (event.checked) {
            // Add the RFID id if it does not exist
            if (!currentRfids.includes(rfidId)) {
                this.chargingGroupForm.controls.rfids.setValue([...currentRfids, rfidId]);
            }
        } else {
            // Remove the RFID id if it's unchecked
            const updatedRfids = currentRfids.filter(id => id !== rfidId);
            this.chargingGroupForm.controls.rfids.setValue(updatedRfids);
        }
    }

    public onSelectAllEvsesChange(event: MatCheckboxChange, evses: Evse[]): void {
        const evseIds = evses.map(evse => evse.id);
        if (event.checked) {
            // Add only the evse ids from the parameter that are not already selected
            const updatedEvses = Array.from(new Set([...this.chargingGroupForm.controls.evses.value, ...evseIds]));
            this.chargingGroupForm.controls.evses.setValue(updatedEvses);
        } else {
            // Remove only the evse ids from the parameter
            const updatedEvses = this.chargingGroupForm.controls.evses.value.filter(id => !evseIds.includes(id));
            this.chargingGroupForm.controls.evses.setValue(updatedEvses);
        }
    }


    public allRfidSelected(rfids: Rfid[]): boolean {
        return rfids.every(rfid => this.chargingGroupForm.controls.rfids.value.includes(rfid.id));
    }

    public someRfidSelected(rfids: Rfid[]): boolean {
        return rfids.some(rfid => this.chargingGroupForm.controls.rfids.value.includes(rfid.id)) && !rfids.every(rfid => this.chargingGroupForm.controls.rfids.value.includes(rfid.id));
    }

    public onSelectAllRfidsChange(event: MatCheckboxChange, rfids: Rfid[]): void {
        const rfidIds = rfids.map(rfid => rfid.id);
        if (event.checked) {
            // Add only the RFID ids from the parameter that are not already selected
            const updatedRfids = Array.from(new Set([...this.chargingGroupForm.controls.rfids.value, ...rfidIds]));
            this.chargingGroupForm.controls.rfids.setValue(updatedRfids);
        } else {
            // Remove only the RFID ids from the parameter
            const updatedRfids = this.chargingGroupForm.controls.rfids.value.filter(id => !rfidIds.includes(id));
            this.chargingGroupForm.controls.rfids.setValue(updatedRfids);
        }
    }

    public getEvseGropedByBasis(): { key: string, values: Evse[] }[] {
        return Object.entries(groupBy(this.evses, item => item.basis.name || '')).map(([key, values]) => ({
            key,
            values
        }));
    }

    public allEvseSelected(evses: Evse[]): boolean {
        return evses.every(evse => this.chargingGroupForm.controls.evses.value.includes(evse.id));
    }

    public someEvseSelected(evses: Evse[]): boolean {
        return evses.some(evse => this.chargingGroupForm.controls.evses.value.includes(evse.id)) && !evses.every(evse => this.chargingGroupForm.controls.evses.value.includes(evse.id))
    }
}
