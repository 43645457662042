import {Component} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {STecAccountList} from "@io-elon-common/frontend-api";
import {STecAccountService} from "../../service/s-tec-account.service";

@Component({
    selector: 'app-s-tec-accounts-list-view',
    templateUrl: './s-tec-accounts-list-view.component.html',
    styleUrls: ['./s-tec-accounts-list-view.component.scss']
})
export class STecAccountsListViewComponent {
    public accounts!: BehaviorSubject<STecAccountList | undefined>


    constructor(
        private readonly sTecService: STecAccountService
    ) {
        this.accounts = sTecService.getAll();
    }

    public create() {
        this.sTecService.showNewDialog();
    }
}
