import {Component, Input, OnInit} from '@angular/core';
import {LoadList, Meter, MeterFormularLiveValues} from "@io-elon-common/frontend-api";
import {BehaviorSubject} from "rxjs";
import {MeterService} from "../../../service/meter.service";
import {AsyncPipe, NgTemplateOutlet} from "@angular/common";
import {Formula, Operation, Term, TermType} from "../../../Formula";
import {EvseService} from "../../../../evse/service/evse.service";
import {LoadService} from "../../../../loads/service/load.service";
import {EngPipe} from "../../../../../shared/helper/eng.pipe";
import {EvseLinkComponent} from "../../../../../shared/components/links/evse-link/evse-link.component";
import {MeterLinkComponent} from "../../../../../shared/components/links/meter-link/meter-link.component";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-formula-display',
  standalone: true,
    imports: [
        AsyncPipe,
        NgTemplateOutlet,
        EngPipe,
        EvseLinkComponent,
        MeterLinkComponent,
        MatTooltip
    ],
  templateUrl: './formula-display.component.html',
  styleUrl: './formula-display.component.scss'
})
export class FormulaDisplayComponent implements OnInit {
    protected readonly TermType = TermType;
    protected readonly Operation = Operation;

    @Input() meter!: Meter;
    @Input() showAmps!: boolean;
    @Input() showPwr!: boolean;

    public liveData!: BehaviorSubject<MeterFormularLiveValues | undefined>;
    public formula!: Formula;

    public loads!: BehaviorSubject<LoadList | undefined>;

    constructor(
        private readonly meterService: MeterService,
        private readonly evseService: EvseService,
        private readonly loadService: LoadService,
        private readonly eng: EngPipe
    ) {

    }

    ngOnInit(): void {
        this.loads = this.loadService.getAll();

        this.liveData = this.meterService.getFormulaLiveData(this.meter.id)
        this.formula = Formula.parse(this.meter.formula!);
    }


    loadName(id: number) {
        return this.loads.value?.list.find(l => l.id === id)?.name || ("ID: '" + id + "'");
    }

    i1(term: Term, liveData: MeterFormularLiveValues): number {
        const asStr = term.toString();
        return (liveData.terms.find(t => Formula.parse((t as any).term).toString() === asStr) as any)?.i1
    }

    i2(term: Term, liveData: MeterFormularLiveValues): number {
        const asStr = term.toString();
        return (liveData.terms.find(t => Formula.parse((t as any).term).toString() === asStr) as any)?.i2
    }

    i3(term: Term, liveData: MeterFormularLiveValues): number {
        const asStr = term.toString();
        return (liveData.terms.find(t => Formula.parse((t as any).term).toString() === asStr) as any)?.i3
    }

    p1(term: Term, liveData: MeterFormularLiveValues): number {
        const asStr = term.toString();
        return (liveData.terms.find(t => Formula.parse((t as any).term).toString() === asStr) as any)?.p1
    }

    p2(term: Term, liveData: MeterFormularLiveValues): number {
        const asStr = term.toString();
        return (liveData.terms.find(t => Formula.parse((t as any).term).toString() === asStr) as any)?.p2
    }

    p3(term: Term, liveData: MeterFormularLiveValues): number {
        const asStr = term.toString();
        return (liveData.terms.find(t => Formula.parse((t as any).term).toString() === asStr) as any)?.p3
    }

    formatWindow(window: number, unit: string) {
        if(window === 0) {
            return "± 0" + unit;
        }
        if(window < 0) {
            return this.eng.transform(window, unit);
        }
        return "+ " + this.eng.transform(window, unit);
    }
}
