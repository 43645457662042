import {Component, Input, OnInit} from '@angular/core';
import {
    ExecuteVehicleAddOrUpdateWebfleetData,
    WebfleetAccountList,
    WebfleetVehicle
} from '@io-elon-common/frontend-api';
import {WebfleetAccountService} from '../../../../webfleet/service/webfleet-account.service';
import {BehaviorSubject} from 'rxjs';
import {MatSelectChange} from '@angular/material/select';
import {DialogType} from '../../../../../shared/components/help-box/dialogType';

@Component({
  selector: 'app-edit-webfleet',
  templateUrl: './edit-webfleet.component.html',
  styleUrl: './edit-webfleet.component.scss'
})
export class EditWebfleetComponent implements OnInit{
    @Input()
    public connection!: ExecuteVehicleAddOrUpdateWebfleetData

    public webfleetAccounts!: BehaviorSubject<WebfleetAccountList | undefined>
    public vehicles!: BehaviorSubject<WebfleetVehicle[] | undefined>;
    protected readonly DialogType = DialogType;

   async ngOnInit(): Promise<void> {
        this.webfleetAccounts = this.webFleetService.getAll();
        if(this.connection.webfleetId) {
            this.vehicles = this.webFleetService.getVehiclesOfAccount(this.connection.accountId);
        }
    }

    public async onAccountChanged(event: MatSelectChange) {
        this.vehicles = await this.webFleetService.getVehiclesOfAccount(event.value)
    }


    constructor(private readonly webFleetService: WebfleetAccountService) {
    }

    public async updateWebfleetVehicleList() {
       if(this.connection.accountId) {
           await this.webFleetService.updateVehicleList(this.connection.accountId)
       }
    }
}
