<h1>Algo Debugger</h1>
<mat-hint>Algo API Version: {{apiVersion}}</mat-hint>

<hr>
<div class="row mb-2">
    <div class="col-6">
        <mat-form-field appearance="fill" *ngIf="(configs | async) as configs; else loadingAlgos">
            <mat-label>Config</mat-label>
            <mat-select [(ngModel)]="config">
                <mat-option *ngFor="let c of configs.list" [value]="c.id">ID: {{c.id}} - {{c.name}}</mat-option>
            </mat-select>
        </mat-form-field>&nbsp;
        <button mat-raised-button (click)="editConfig()">edit</button>
        <mat-hint class="d-block">
            ConfigId muss in den Args eingetragen werden Dropdown ist nur zum Nachschlagen der ID. Bearbeiten wird die Config produktiv bearbeiten. Unbedingt hinterher wieder zurück stellen.
        </mat-hint>
    </div>
    <ng-template #loadingAlgos><div>loading configs...</div></ng-template>
    <div class="col-2 border-start position-relative">
        <button mat-raised-button color="accent" (click)="fileInput.click()" class="d-block m-auto align-bottom">
            Plan JSON hochladen
        </button>
        <!--suppress TypeScriptUnresolvedReference -->
        <input hidden (change)="upload($event.target.files)" #fileInput type="file" id="file">
    </div>
    <div class="col-4 border-start">
        <mat-form-field appearance="fill" *ngIf="(fleets | async) as fleets; else loadingFleets">
            <mat-label>Flotte</mat-label>
            <mat-select [(ngModel)]="fleet">
                <mat-option *ngFor="let f of fleets.list" [value]="f.id">{{f.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <ng-template #loadingFleets><div>loading fleets...</div></ng-template>
        <button mat-raised-button color="accent" (click)="loadFromPlan()" class="ml-2 d-block">Aus aktuellem Ladeplan befüllen</button>
    </div>
</div>
<hr>
<div class="w-100 position-relative">
    <button class="download" mat-icon-button (click)="download(args, 'args.json')"><i class="fa-solid fa-download"></i></button>
    <button class="copy" matTooltip="In Zwischenablage kopieren" mat-icon-button (click)="copy('args')"><i class="fa-solid fa-copy"></i></button>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Args</mat-label>
        <textarea matInput id="args" [(ngModel)]="args"></textarea>
    </mat-form-field>
</div>
<div class="w-100 position-relative">
    <button class="download" mat-icon-button (click)="download(data, 'data.json')"><i class="fa-solid fa-download"></i></button>
    <button class="copy" matTooltip="In Zwischenablage kopieren" mat-icon-button (click)="copy('data')"><i class="fa-solid fa-copy"></i></button>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Data</mat-label>
        <textarea matInput id="data" [(ngModel)]="data"></textarea>
    </mat-form-field>
</div>
<button mat-raised-button color="primary" (click)="sendRequest()">Berechnen</button>
<hr>
<app-charge-plan-new [plan]="plan" *ngIf="plan"></app-charge-plan-new>
<div class="w-100 position-relative">
    <button class="download" mat-icon-button (click)="download(planStr, 'plan.json')"><i class="fa-solid fa-download"></i></button>
    <button class="copy" matTooltip="In Zwischenablage kopieren" mat-icon-button (click)="copy('planStr')"><i class="fa-solid fa-copy"></i></button>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Plan</mat-label>
        <textarea matInput disabled id="planStr" [(ngModel)]="planStr"></textarea>
    </mat-form-field>
</div>
