import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ExecuteGatewayAddOrUpdate} from '@io-elon-common/frontend-api';
import {Subscription} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {isIPAddress, required} from '../../../../shared/components/form-control-validator/validator';

@Component({
    selector: 'app-edit-gateway-dialog',
    templateUrl: './edit-gateway-dialog.component.html',
    styleUrl: './edit-gateway-dialog.component.scss'
})
export class EditGatewayDialogComponent implements IEditForm<ExecuteGatewayAddOrUpdate>, OnDestroy, OnInit {
    @Input()
    public data!: ExecuteGatewayAddOrUpdate;
    public ctrlMask!: FormControl<number | undefined | null>;
    public ctrlAddress!: FormControl<string | undefined | null>;
    public ctrlName!: FormControl<string | undefined | null>;
    public ctrlMaskSubscription!: Subscription;
    public ctrlAddressSubscription!: Subscription;
    public ctrlNameSubscription!: Subscription;

    constructor() {
    }

    public validate(): string[] | Promise<string[]> {
        const ret: string[] = [];
        this.ctrlMask.markAsTouched();
        this.ctrlAddress.markAsTouched();
        this.ctrlName.markAsTouched();
        if(this.ctrlName.errors?.required) {
            ret.push(this.ctrlName.errors?.required);
        }
        if (this.ctrlAddress.errors?.required) {
            ret.push(this.ctrlAddress.errors?.required);
        }
        if (this.ctrlAddress.errors?.invalidAddress) {
            ret.push(this.ctrlAddress.errors?.invalidAddress);
        }
        if (this.ctrlMask.errors?.required) {
            ret.push(this.ctrlMask.errors?.required);
        }
        if (this.ctrlMask.errors?.min) {
            ret.push('Der Wert für die Netzmaske darf nicht kleiner sein als ' + this.ctrlMask.errors?.min.min);
        }
        if (this.ctrlMask.errors?.max) {
            ret.push('Der Wert für die Netzmaske darf nicht größer sein als ' + this.ctrlMask.errors?.max.max);
        }
        return ret;
    }

    public ngOnDestroy(): void {
        this.ctrlMaskSubscription.unsubscribe();
        this.ctrlAddressSubscription.unsubscribe();
        this.ctrlNameSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.ctrlAddress = new FormControl(this.data.address, [
            required('Die Adresse muss ausgefüllt sein'),
            isIPAddress('Die angegebene Adresse is ungültig')
        ]);
        this.ctrlMask = new FormControl(this.data.mask < 0 ? undefined : this.data.mask, [
            required('Die Netzmaske muss ausgefüllt sein'),
            Validators.min(0),
            Validators.max(32),
        ]);
        this.ctrlName = new FormControl(this.data.name === "" ? undefined : this.data.name, [
            required("Der Name muss ausgefüllt sein"),
        ])
        this.ctrlMaskSubscription = this.ctrlMask.valueChanges.subscribe(value => {
            if (value) {
                this.data.mask = value;
            }
        });
        this.ctrlAddressSubscription = this.ctrlAddress.valueChanges.subscribe(value => {
            if (value) {
                this.data.address = value;
            }
        });
        this.ctrlNameSubscription = this.ctrlName.valueChanges.subscribe(value => {
            if (value) {
                this.data.name = value;
            }
        })
    }
}
