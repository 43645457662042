<table mat-table [dataSource]="dataSource" class="full-width-table" [trackBy]="trackBy">

    <ng-container matColumnDef="device">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Gerät</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            <ng-container [ngSwitch]="element.deviceType">
                <div class="d-flex flex-column gap-2 justify-content-between align-items-start">
                    <div class="d-flex flex-row gap-2">
                        <div>{{ element.deviceType | formatGatewayDeviceType }}:</div>
                        <app-meter-link [meterId]="element.deviceId"
                                        *ngSwitchCase="GatewayDeviceType.Meter"></app-meter-link>
                        <app-evse-link [evseId]="element.deviceId"
                                       *ngSwitchCase="GatewayDeviceType.Evse"></app-evse-link>
                        <app-vehicle-link [vehicleId]="element.deviceId"
                                          *ngSwitchCase="GatewayDeviceType.Vehicle"></app-vehicle-link>
                        <app-power-supply-link [powerSupplyId]="element.deviceId"
                                               *ngSwitchCase="GatewayDeviceType.PowerSupply"></app-power-supply-link>
                    </div>
                    <span class="basis-info">{{getBasis(element.deviceId, element.deviceType)}}</span>
                </div>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="field">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Feld</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{getFieldName(element)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="modbusId">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Modbus Device ID</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{element.modbusId}}
        </td>
    </ng-container>

    <ng-container matColumnDef="register">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Start Adresse</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{element.startAddress}}
        </td>
    </ng-container>

    <ng-container matColumnDef="dataType">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Daten Typ</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{getDataType(element)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="access">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Zugriffs Berechtigungen</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{getAccess(element.access)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="liveValue">
        <th mat-header-cell *matHeaderCellDef class="col-gateway-address">Aktueller Wert</th>
        <td mat-cell *matCellDef="let element" class="col-gateway-address">
            {{element.liveValue}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons="true">
</mat-paginator>
