
<ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'ocppUplinkAccount'">
        @if(uplinkPossible) {
            <mat-form-field  class="half" appearance="fill" *ngIf="(getUplinks() | async) as ocppUplinks; else loading">
                    <mat-label>{{name}}</mat-label>
                    <mat-select [(ngModel)]="model" (selectionChange)="modelChange.emit(model)">
                        <mat-option [value]="'null'">-- Kein Uplink --</mat-option>
                        <mat-option *ngFor="let uplink of ocppUplinks?.list" [value]="uplink.id + ''">
                            {{getOcppUplinkDisplayName(uplink)}}
                        </mat-option>
                    </mat-select>
            </mat-form-field>
        } @else {
            <mat-form-field  class="half" appearance="fill">
                <mat-label>{{name}}</mat-label>
                <mat-select disabled="">
                    <mat-option>-- Kein Uplink --</mat-option>
                </mat-select>
            </mat-form-field>
        }
    </ng-container>

    <ng-container *ngSwitchCase="'chargePointIdentity'">
        <mat-form-field class="full" appearance="fill" [floatLabel]="'always'">
            <mat-label>{{name}}</mat-label>
            <span matTextPrefix style="border-right: 1px lightgray"><span matTooltip="OCPP Prefix">ws{{secure ? 's' : ''}}://{{host}}/ocppj/</span><span *ngIf="!withoutFleetId" matTooltip="ID der Flotte">{{fleetId | async}}/</span></span>
            <input matInput class="cpi" [type]="type" [(ngModel)]="cpiVal" (change)="modelChange.emit(model)" required>
            <span matSuffix><button *ngIf="canEnableOldPrefix || withoutFleetId" mat-icon-button (click)="withoutFleetId = !withoutFleetId; modelChange.emit(model)" matTooltip="Altes OCPP Prefix ohne Flotten-ID benutzen. Diese Option kann nach dem Update auf das neue Profix nur vom Support wieder umgestellt werden."><mat-icon>blur_{{withoutFleetId ? 'off' : 'on'}}</mat-icon></button></span>
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'statusPollIntervallSeconds'">
        <mat-form-field class="half interval-input" appearance="fill" *ngIf="setStatusPollIntervallSeconds">
            <mat-label>{{name}}</mat-label>
            <input matInput [type]="type" [(ngModel)]="model" (change)="modelChange.emit(model)" [required]="isRequired">
            <mat-hint>Leer lassen, um die Standardeinstellung des Systems zu verwenden.</mat-hint>
        </mat-form-field>
        <mat-checkbox *ngIf="!setStatusPollIntervallSeconds" [(ngModel)]="setStatusPollIntervallSeconds" class="interval-checkbox">Status Poll Interval überschreiben</mat-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'meterPollIntervallSeconds'">
        <mat-form-field class="half interval-input" appearance="fill" *ngIf="setMeterPollIntervallSeconds">
            <mat-label>{{name}}</mat-label>
            <input matInput [type]="type" [(ngModel)]="model" (change)="modelChange.emit(model)" [required]="isRequired">
            <mat-hint>Leer lassen, um die Standardeinstellung des Systems zu verwenden.</mat-hint>
        </mat-form-field>
        <mat-checkbox *ngIf="!setMeterPollIntervallSeconds" [(ngModel)]="setMeterPollIntervallSeconds" class="interval-checkbox">Meter Poll Intervall überschreiben</mat-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'connectorId'">
        <mat-form-field class="half" appearance="fill">
            <mat-label>{{name}}</mat-label>
            <input matInput type="number" [(ngModel)]="model" (change)="modelChange.emit(model)" [required]="isRequired" min="0">
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <mat-form-field class="half" appearance="fill">
            <mat-label>{{name}}</mat-label>
            <input matInput [type]="type" [(ngModel)]="model" (change)="modelChange.emit(model)" [required]="isRequired">
        </mat-form-field>
    </ng-container>

</ng-container>
<ng-template #loading> loading uplinks...</ng-template>
