import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {localStorageGet, localStorageSave} from '../../../helper/typed-local-storage';
import {DialogType} from '../dialogType';
import {HelpBoxIconComponent} from '../help-box-icon/help-box-icon.component';
import {Subscription} from 'rxjs';
import {MatIcon} from "@angular/material/icon";
import {NgIf} from "@angular/common";


@Component({
    standalone: true,
    selector: 'app-help-box',
    templateUrl: './help-box-component-dialog.component.html',
    imports: [
        MatIcon,
        NgIf
    ],
    styleUrls: ['./help-box-component-dialog.component.scss']
})
export class HelpBoxComponentDialog implements OnInit, OnDestroy {

    @Input()
    public closable = true;

    @Input()
    public key?: string;
    @Input()
    public helpIcon?: HelpBoxIconComponent;
    @Input()
    public dialogType: DialogType = DialogType.HELP;
    @Input()
    public closedByDefault = false

    public closed = false;
    public icon!: string;
    public cssClass!: string;
    public buttonTypeCssClass!: string;
    public clickedSubscription?: Subscription

    public ngOnInit(): void {
        if (this.helpIcon) {
            this.clickedSubscription = this.helpIcon.clicked.subscribe(value => {
                if (value) {
                    this.open();
                }
            });
        }
        if (this.key) {
            const closedTexts: string[] = JSON.parse(localStorageGet('CLOSED_HELP_TEXTS', '[]'));
            if (this.helpIcon) {
                if (closedTexts.indexOf(this.key) !== -1 || this.closedByDefault) {
                    this.helpIcon.hidden = false;
                    this.closed = true;
                } else {
                    this.helpIcon.hidden = true;
                }
            }
        }
        switch (this.dialogType) {
            case DialogType.INFO:
                this.icon = 'info';
                this.cssClass = 'alert alert-info';
                this.buttonTypeCssClass = 'info';
                break;
            case DialogType.HELP:
                this.icon = 'help';
                this.cssClass = 'alert alert-help';
                this.buttonTypeCssClass = 'help';
                break;
            case DialogType.SUCCESS:
                this.icon = 'check_circle';
                this.cssClass = 'alert alert-success';
                this.buttonTypeCssClass = 'success';
                break;
            case DialogType.WARNING:
                this.icon = 'warning';
                this.cssClass = 'alert alert-warning';
                this.buttonTypeCssClass = 'warning';
                break;
            default:
                this.icon = 'info';
                this.cssClass = 'alert alert-info';
                this.buttonTypeCssClass = 'info';
        }
    }

    ngOnDestroy(): void {
        this.clickedSubscription?.unsubscribe();
    }

    public open() {
        if(this.key) {
            const closedTexts: string[] = JSON.parse(localStorageGet("CLOSED_HELP_TEXTS", "[]"));
            const idx = closedTexts.indexOf(this.key);
            if(idx !== -1){
                closedTexts.splice(idx, 1);
                localStorageSave("CLOSED_HELP_TEXTS", JSON.stringify(closedTexts));
            }
        }
        this.closed = false;
        if(this.helpIcon) {
            this.helpIcon.hidden = true
        }
    }

    public close(){
        if(this.key) {
            const closedTexts: string[] = JSON.parse(localStorageGet("CLOSED_HELP_TEXTS", "[]"));
            if(closedTexts.indexOf(this.key) === -1){
                closedTexts.push(this.key);
                localStorageSave("CLOSED_HELP_TEXTS", JSON.stringify(closedTexts));
            }
        }
        this.closed = true;
        if(this.helpIcon) {
            this.helpIcon.hidden = false
        }
    }
}
