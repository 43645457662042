<div class="dialog-head">
    <h2 class="mat-h2 title-text">Konfiguration aller Ladepunkte prüfen</h2>
    <mat-icon class="close-icon" (click)="close()">close</mat-icon>
</div>
<div class="left-corner">
    <span class="d-flex align-items-center">
        Erfolgreich {{currentOperation}}: {{getSuccessfulCount()}}/{{selection.selected.length}} &nbsp;
    </span>
    <span class="d-flex align-items-center">
        <mat-icon *ngIf="isFinished && !inProgress">check</mat-icon>
        <ng-container *ngIf="inProgress" class="d-flex align-items-center">
            <i class="fas fa-circle-notch fa-spin"></i>&nbsp;
            <div *ngIf="isSetDefaultInProgress; else reValidate">
                Empfohlende Konfiguration wird übertragen
            </div>
            <ng-template #reValidate>
                Konfiguration wird erneut geprüft
            </ng-template>
        </ng-container>
    </span>
</div>

<section matSort class="select-all">
    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                  [checked]="isAllSelected()"
                  [indeterminate]="areSomeSelected() && !isAllSelected()"
                  [disabled]="inProgress">
    </mat-checkbox>
    Ladepunkte
</section>
<mat-dialog-content>
    <mat-accordion displayMode="flat" multi="true">
        <div class="evses-list">
            <mat-expansion-panel #evsePanel *ngFor="let element of displayedRows | async" [expanded]="element.shouldExpand">
                <mat-expansion-panel-header class="mat-row">
                    <div class="flex-grow-0">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(element.evse.id) : null"
                                      [checked]="selection.isSelected(element.evse.id)"
                                      [disabled]="inProgress">
                        </mat-checkbox>
                    </div>
                    <div class="flex-grow-1">
                        <b>{{element.evse.name}} </b>
                        <span *ngIf="isSetDefaultInProgress && element.statusResult.status != 'idle'">
                        (Erfolgreich übertragen: {{element.successfulSetDefaultCount}} / {{element.evse.liveData.validationResult.length}})
                    </span>
                    </div>
                    <div class="flex-grow-2 gap-1">
                        <ng-container [ngSwitch]="element.statusResult.status">
                            <ng-container *ngSwitchCase="'running'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>hourglass_empty</mat-icon>
                                    Wird ausgeführt&nbsp;
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'pending'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>av_timer</mat-icon>
                                    Ausstehend&nbsp;
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'partial_success'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>check</mat-icon>
                                    Teilweise Erfolgreich&nbsp;
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'success'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>check</mat-icon>
                                    Erfolgreich&nbsp;
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'failed'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>report_problem</mat-icon>
                                    Fehlgeschlagen&nbsp;
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'config_checking_success'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>check</mat-icon>
                                    Prüfung durchgeführt&nbsp;
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'config_checking_failed'">
                                <div class="d-flex align-items-center">
                                    <mat-icon>report_problem</mat-icon>
                                    Prüfung fehlgeschlagen&nbsp;
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-expansion-panel-header>

                @if (element.statusResult.status == 'config_checking_failed') {
                    <div><b>{{element.statusResult.displayText}}</b></div>
                }

                <div>
                    <span class="d-flex">Validierungsregeln: {{element.evse.liveData.validationResult.length}}</span>
                    <span class="d-flex">Korrekter Wert: {{getValidationResultTotalCount(element.evse.liveData.validationResult, 'VALID')}}</span>

                    @if (getValidationResultTotalCount(element.evse.liveData.validationResult, 'INVALID'); as count) {
                        <span class="d-flex align-items-center error-icon">
                        <mat-icon>info</mat-icon>
                        Falscher Wert: {{count}}
                    </span>
                    } @else {
                        <span class="d-flex">Falscher Wert: 0</span>
                    }

                    @if (getValidationResultTotalCount(element.evse.liveData.validationResult, 'VALIDATION_ERROR'); as count) {
                        <span class="d-flex align-items-center error-icon">
                        <mat-icon>info</mat-icon>
                        Prüfung nicht möglich: {{count}}
                    </span>
                    } @else {
                        <span class="d-flex">Prüfung nicht möglich: 0</span>
                    }

                    <span class="d-flex">Ignorierte Regeln: {{element.whiteList.length}}</span>
                </div>

                <div class="button-div">
                    <div class="right-corner">
                        <button mat-raised-button color="primary" (click)="handleValidation(element.evse)" [disabled]="element.evse.liveData.validationResult.length === 0">
                            Validierung Ergebnisse
                        </button>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</mat-dialog-content>
<mat-paginator #paginator
               [length]="totalRows | async"
               [pageIndex]="0"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
<mat-dialog-actions class="button-div">
    <div class="right-corner">
        <button [disabled]="inProgress || selection.isEmpty()" mat-raised-button color="primary" (click)="revalidateAll()">Konfiguration erneut prüfen</button>
        <button [disabled]="inProgress || selection.isEmpty()" mat-raised-button color="primary" (click)="setAllNotIgnore()" matTooltip="Setzt bei allen ausgewählten Station den Zustand Ignorieren für alle Regeln zurück.">Nicht mehr Ignorieren</button>
        <button [disabled]="inProgress || selection.isEmpty()" mat-raised-button color="primary" (click)="setDefaultToAll()">Empfohlene Konfiguration übertragen</button>
    </div>
</mat-dialog-actions>
