import {Injectable} from '@angular/core';
import {
    ExecuteTeslaAccountAddOrUpdate,
    ExecuteWebfleetAccountAddOrUpdate,
    WebfleetAccount, WebfleetAccountList,
    WebfleetDatasource,
    WebfleetLogin,
    WebfleetVehicle
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {POLL_INTERVALS} from '../../../app.module';
import {ApiHandler} from '../../../services/api-handlers/api-handler';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {
    EditTeslaAccountDialogComponent
} from '../../tesla/dialogs/edit-tesla-account-dialog/edit-tesla-account-dialog.component';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {
    EditWebfleetAccountDialogComponent
} from '../edit-webfleet-account-dialog/edit-webfleet-account-dialog.component';
import {
    LoginWebfleetAccountDialogComponent
} from '../login-webfleet-account-dialog/login-webfleet-account-dialog.component';
import {CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class WebfleetAccountService extends DialogHandler<WebfleetAccount, ExecuteWebfleetAccountAddOrUpdate, ExecuteWebfleetAccountAddOrUpdate, {}, WebfleetAccountList, void, void> {
    private webfleetVehicleCache!: CacheUpdater<WebfleetVehicle[], number>;

    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'WebfleetAccount', toastr, dialog, dialogService, POLL_INTERVALS.webfleetAccounts);
        this.webfleetVehicleCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    public async webfleetAccountLogin(webfleetAccount: WebfleetAccount, showAlerts = true): Promise<void> {
        const config: TDialogOptions<WebfleetLogin, LoginWebfleetAccountDialogComponent> = {
            headline: "Login-Daten für '" + webfleetAccount.name + "'",
            component: LoginWebfleetAccountDialogComponent,
            executeCallback: async (elem: WebfleetLogin) => {
                this.apiService.webfleetAccountLogin(showAlerts, webfleetAccount.id, undefined, undefined, ApiHandler.customerId, elem);
            },
            editElement: {
                accountname: "",
                username: "",
                password: ""
            }
        };
        await this.showDialog(config, "Erfolgreich gespeichert");
    }

    protected getEditConfig(webfleetAccount: WebfleetAccount): TDialogOptions<ExecuteTeslaAccountAddOrUpdate, EditTeslaAccountDialogComponent> {
        return {
            headline: "Webfleet Account bearbeiten",
            component: EditWebfleetAccountDialogComponent,
            executeCallback: editResult => this.update(webfleetAccount.id, editResult),
            editElement: {
                name: webfleetAccount.name
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteWebfleetAccountAddOrUpdate, EditWebfleetAccountDialogComponent> {
        return {
            headline: "Webfleet Account anlegen",
            component: EditWebfleetAccountDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "Webfleet Account"
            }
        };
    }

    public getVehiclesOfAccount(accountId: number): BehaviorSubject<WebfleetVehicle[] | undefined> {
        return this.webfleetVehicleCache.getOrCreateGet(
            accountId,
            () => this.apiService.getWebfleetAccountVehicleList(true, accountId).toPromise().then(list => list.list)
        ).data;
    }

    public getWebfleetDatasource(id: number): Promise<WebfleetDatasource> {
        return this.apiService.getWebfleetDatasource(true, id).toPromise();
    }

    public updateVehicleList(id: number): Promise<any> {
        return this.apiService.updateVehicleList(true, id).toPromise()
    }

    public synchronizeAccounts(): Promise<any> {
        return this.apiService.synchronizeAccounts(true).toPromise();
    }

}
