import {Injectable} from '@angular/core';
import {
    ExecuteMeterAddOrUpdate,
    Meter,
    MeterConnectionList,
    MeterFields,
    MeterFormularHistoricValues,
    MeterFormularLiveValues,
    MeterHistory, MeterList,
    MeterLiveDataList,
    PublicService,
    SmartMeterV1Data
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {IEditForm, TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {EditMeterDialogComponent} from '../dialogs/edit-meter-dialog/edit-meter-dialog.component';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {BehaviorSubject} from "rxjs";
import {CacheUpdater} from "../../../services/api-handlers/cacheManager";
import {
    SmartmeterDataV1DialogComponent
} from "../dialogs/smartmeter-data-v1-dialog/smartmeter-data-v1-dialog.component";
import {POLL_INTERVALS} from "../../../app.module";
import {
    EditFormulaMeterDialogComponent
} from "../dialogs/edit-formula-meter-dialog/edit-formula-meter-dialog.component";

const PIXEL_PER_DOT = 3;
@Injectable({
    providedIn: 'root'
})
export class MeterService extends DialogHandler<Meter, ExecuteMeterAddOrUpdate, ExecuteMeterAddOrUpdate, {}, MeterList, number, void> {
    private meterConnectionCache!: CacheUpdater<MeterConnectionList, number>;
    private meterLiveDataCache!: CacheUpdater<MeterLiveDataList, number>;
    private formularLiveDataCache!: CacheUpdater<MeterFormularLiveValues, number>
    private smartmeterV1Service: PublicService;

    constructor(
        apiService: ApiService,
        smartmeterV1Service: PublicService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService
    ) {
        super(apiService, 'Meter', toastr, dialog, dialogService, POLL_INTERVALS.meter);
        this.meterConnectionCache = this.createManagedCache(() => true);
        this.meterLiveDataCache = this.createManagedCache(() => true);
        this.formularLiveDataCache = this.createManagedCache((obj, id) => obj.id === id);
        this.smartmeterV1Service = smartmeterV1Service;
    }

    public async showCloneDialog(element: Meter): Promise<void> {
        const cloneConfig = this.getEditConfig(element);
        cloneConfig.headline = "Kopie anlegen";
        cloneConfig.editElement.name = "Kopie von " + cloneConfig.editElement.name;
        cloneConfig.executeCallback = editResult => this.create({
            ...editResult
        });
        await this.showDialog(cloneConfig, "Eintrag angelegt")
    }

    public async showApiDataV1Dialog(id: number) {
        let data: SmartMeterV1Data = {};
        data.u1 = 235;
        data.u2 = 235;
        data.u3 = 235;
        const config : TDialogOptions<SmartMeterV1Data, IEditForm<SmartMeterV1Data>> = {
            headline: "Smartmeter API Daten bereitstellen",
            component: SmartmeterDataV1DialogComponent,
            executeCallback: async (data: SmartMeterV1Data) => {
                await this.smartmeterV1Service.smartMeterV1Data(id,undefined, undefined, ApiHandler.customerId, data).toPromise();
            },
            editElement: data,
        };
        await this.showDialog(config, "Smartmeter Daten versendet");
    }


    protected getEditConfig(meter: Meter): TDialogOptions<ExecuteMeterAddOrUpdate, EditMeterDialogComponent | EditFormulaMeterDialogComponent> {
        const formula = meter.type === "FormulaMeter";
        return {
            headline: formula ? "Formel bearbeiten" : "Zähler bearbeiten",
            component: formula ? EditFormulaMeterDialogComponent : EditMeterDialogComponent,
            executeCallback: editResult => this.update(meter.id, editResult),
            editElement: this.generateEditObject(meter),
            extraParams: {
                meterId: meter.id
            }
        }
    }

    public generateEditObject(meter: Meter): ExecuteMeterAddOrUpdate {
        return {
            name: meter.name || "",
            type: meter.type,
            hostname: meter.hostname,
            interval: meter.interval,
            port: meter.port,
            modbusId: meter.modbusId || undefined,
            basisId: meter.basis?.id,
            maintenance: meter.maintenanceEnabled,
            maintenanceReason: meter.maintenanceReason,
            formula: meter.formula,
            formulaConfig: meter.formulaConfig
        };
    }

    protected getNewConfig(basisId: number): TDialogOptions<ExecuteMeterAddOrUpdate, EditMeterDialogComponent> {
        return {
            headline: "Zähler anlegen",
            component: EditMeterDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: this.generateCreateObject(basisId)
        }
    }


    public generateCreateObject(basisId: number) {
        return {
            name: "",
            type: "",
            hostname: "",
            interval: 10000,
            port: 502,
            modbusId: undefined,
            basisId: basisId,
            maintenance: false,
            maintenanceReason: undefined,
            formula: "",
            formulaConfig: undefined
        };
    }

    public async getHistory(meterId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<MeterHistory> {
        start = typeof start === 'number' ? Math.ceil(start) : start.getTime();
        end = typeof end === 'number' ? Math.floor(end) : end.getTime();
        let zoom = Math.ceil((end - start) / (1000 * window.innerWidth) * PIXEL_PER_DOT);
        if (zoom>=3000) zoom = Math.ceil(3000+100*(zoom/3000));

        return this.apiService.getMeterHistory(showAlerts, meterId, start, end, zoom, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public async getFields(meterId: number, showAlerts = true): Promise<MeterFields> {
        return this.apiService.getMeterFields(showAlerts, meterId, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getMeterConnections(showAlerts = true): BehaviorSubject<MeterConnectionList | undefined> {
        return this.meterConnectionCache.getOrCreateGet(0, () => this.apiService.meterConnectionList(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    public getMeterLiveData(showAlerts = true): BehaviorSubject<MeterLiveDataList | undefined> {
        return this.meterLiveDataCache.getOrCreateGet(0, () => this.apiService.getMeterLiveData(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    public updateMeterBasis(meter: Meter) {
        return this.update(meter.id, {
            hostname: meter.hostname,
            interval: meter.interval,
            modbusId: meter.modbusId,
            name: meter.name ? meter.name : "",
            port: meter.port,
            type: meter.type,
            basisId: meter.basis?.id,
            maintenance: meter.maintenanceEnabled,
            maintenanceReason: meter.maintenanceReason,
            formula: meter.formula,
            formulaConfig: meter.formulaConfig
        });
    }

    public async simulateFormularMeter(config: ExecuteMeterAddOrUpdate, start: number, end: number, showAlerts = true): Promise<MeterFormularHistoricValues> {
        return this.apiService.getMeterLoadHistory(showAlerts, start, end, undefined, undefined, ApiHandler.customerId, config).toPromise();
    }

    public getFormulaLiveData(loadId: number, showAlerts = true): BehaviorSubject<MeterFormularLiveValues | undefined> {
        return this.formularLiveDataCache.getOrCreateGet(loadId, () => this.apiService.getFormularMeterLiveValues(showAlerts, loadId,undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    async showNewFormulaDialog(basisId: number) {
        await this.showDialog({
            headline: "Formel anlegen",
            component: EditFormulaMeterDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                ...this.generateCreateObject(basisId),
                type: "FormulaMeter"
            }
        }, "Formel angelegt")

    }
}
