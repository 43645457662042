@if(show404) {
    <app-404 msg="Gateway nicht gefunden"></app-404>
} @else {
    <mat-icon svgIcon=""></mat-icon>
    <div *ngIf="(gateway | async) as gateway; else loading" class="d-flex flex-column align-items-stretch gap-3">
        <div class="d-flex flex-row justify-content-between">
            <h1>{{gateway.name}}</h1>
            <div>
                <h2 *ngIf="isDev">{{gateway.address}}/{{gateway.mask}}</h2>
                <app-help-box-icon #gatewayHelpIcon></app-help-box-icon>
            </div>
        </div>
        <app-help-box [helpIcon]="gatewayHelpIcon">
            <p>
                Hier können Sie die Modbus-Register konfigurieren.
            </p>
            <p>
                Sie können beliebige Werte Ihrer Geräte frei auf die Modbus Register legen. Hierzu müssen Sie im Dialog zuerst
                das Gerät und den Wert auswählen, anschließend können Sie die Modbus-Geräte-ID (unitId) sowie das Register (
                bei Werten mit mehr als 16bit das Start-Register) auswählen.
            </p>
            <p>
                Anschließend müssen Sie noch festlegen, wie die Daten im Modbus dargestellt werden sollen (Encoding/Datentyp).
                Es stehen je nach Art der Daten verschiedene Optionen zur Verfügung.
            </p>
            <p>
                Als Datentyp sollten Sie das auswählen, was in der Dokumentation des Modbus TCP Masters angegeben ist.
                In Modbus wird üblicherweise <i>BIG_ENDIAN</i> verwendet, daher ist diese Information in der Dokumentation
                vieler Geräte nicht enthalten.
            </p>
        </app-help-box>
        <app-gateway-register-list-view [gateway]="gateway"></app-gateway-register-list-view>
    </div>
}
<ng-template #loading>
    <mat-spinner></mat-spinner>
    Loading...
</ng-template>
