import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DatabaseCheckService} from "../../services/database-check.service";

@Component({
  selector: 'app-database-exception-log',
  templateUrl: './database-exception-log.component.html',
  styleUrl: './database-exception-log.component.scss'
})
export class DatabaseExceptionLogComponent implements OnInit {
    public connected: boolean = false;
    public socket?: WebSocket
    public log = "";
    public autoScroll = true;

    constructor(
        private dialogRef: MatDialogRef<void>,
        private databaseCheckService: DatabaseCheckService
    ){}

    ngOnInit(): void {
        this.connect();
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public async downloadLog() {
        await this.databaseCheckService.downloadLog();
    }

    ngOnDestroy(): void {
        if(this.connected) {
            this.closeConnection();
        }
    }
    private onMessage(msg: string) {
        this.log += msg;
        if(this.autoScroll) {
            this.scrollDown();
        }
    }
    private closeConnection() {
        this.socket?.close();
        this.connected = false;
    }

    private connect() {
        this.socket = this.databaseCheckService.getLiveLogs();
        this.socket.onmessage = msg => this.onMessage(msg.data + "\n");
        this.socket.onerror = err => this.onMessage("Fehler: " + err);
        this.socket.onclose = evt => this.onMessage("CLOSE --- CLOSE --- CLOSE");
        this.connected = true;
    }

    private scrollDown() {
        if (this.autoScroll) {
            setTimeout(() => {
                const element = document.getElementById("scroll-pre");
                if (element) {
                    element.scrollTop = element.scrollHeight - element.clientHeight;
                }
            });
        }
    }
}
