import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OpenAdrVenEntityList} from '@io-elon-common/frontend-api';
import {OpenAdrVenEntityService} from '../../service/open-adr-ven-entity.service';

@Component({
  selector: 'app-open-adr-ven-list-view',
  templateUrl: './open-adr-ven-list-view.component.html',
  styleUrls: ['./open-adr-ven-list-view.component.scss']
})
export class OpenAdrVenListViewComponent implements OnInit {

  public openAdrVenEntities!: BehaviorSubject<OpenAdrVenEntityList | undefined>;

  public constructor(
      private readonly openAdrVenEntityService: OpenAdrVenEntityService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
      this.openAdrVenEntities = this.openAdrVenEntityService.getAll();
  }

  public async newOpenAdrVenEntity() {
    await this.openAdrVenEntityService.showNewDialog();
  }
}
