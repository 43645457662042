import {Injectable} from '@angular/core';
import {DialogHandler} from "../../../services/api-handlers/dialog-handler";
import {
    ChargingGroup,
    ChargingGroupList,
    Evse,
    ExecuteChargingGroupAddOrUpdate,
    Rfid
} from "@io-elon-common/frontend-api";
import {
    ChargingGroupEditDialogComponent
} from "../dialogs/charging-group-edit-dialog/charging-group-edit-dialog.component";
import {ApiService} from "../../../services/api-handlers/api.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog.service";
import {POLL_INTERVALS} from "../../../app.module";
import {TDialogOptions} from "../../../shared/components/dialogs/edit-dialog/edit-dialog.component";

export interface IChargingGroupDialogArgs {
    evses: Evse[];
    rfids: Rfid[];
}

@Injectable({
  providedIn: 'root'
})
export class ChargingGroupService extends DialogHandler<ChargingGroup, ExecuteChargingGroupAddOrUpdate, ExecuteChargingGroupAddOrUpdate, {}, ChargingGroupList, IChargingGroupDialogArgs, IChargingGroupDialogArgs>{

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "ChargingGroup", toastr, dialog, dialogService, POLL_INTERVALS.chargingGroup);
    }

    protected getEditConfig(chargingGroup: ChargingGroup, args: IChargingGroupDialogArgs): TDialogOptions<ExecuteChargingGroupAddOrUpdate, ChargingGroupEditDialogComponent> {
        return {
            headline: "Ladegruppe bearbeiten",
            component: ChargingGroupEditDialogComponent,
            executeCallback: async editResult => {
                await this.update(chargingGroup.id, editResult);
                this.invalidate();
            },
            editElement: {
                name: chargingGroup.name,
                evses: chargingGroup.evses,
                rfids: chargingGroup.rfids,
                includeGuests: chargingGroup.includeGuests,
                pricePerEnergy: chargingGroup.pricePerEnergy,
                pricePerSession: chargingGroup.pricePerSession,
                pricePerHour: chargingGroup.pricePerHour,
                initialFreeMinutes: chargingGroup.initialFreeMinutes
            },
            extraParams: {
                evses: args.evses,
                rfids: args.rfids,
                edit: true
            }
        };
    }

    protected getNewConfig(args: IChargingGroupDialogArgs): TDialogOptions<ExecuteChargingGroupAddOrUpdate, ChargingGroupEditDialogComponent> {
        return {
            headline: "Ladegruppe hinzufügen",
            component: ChargingGroupEditDialogComponent,
            executeCallback: async editResult => {
                await this.create(editResult);
                this.invalidate();
            },
            editElement: {
                name: "",
                evses: [],
                rfids: [],
                includeGuests: false,
                pricePerEnergy: 0,
                pricePerSession: 0,
                pricePerHour: 0,
                initialFreeMinutes: 0
            },
            extraParams: {
                evses: args.evses,
                rfids: args.rfids,
                edit: true
            }
        };
    }
}
