import {Injectable} from '@angular/core';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ExecuteGatewayAddOrUpdate, Gateway, GatewayList} from '@io-elon-common/frontend-api';
import {POLL_INTERVALS} from '../../../app.module';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditGatewayDialogComponent} from '../dialogs/edit-gateway-dialog/edit-gateway-dialog.component';
import {EvseLogDialogComponent} from '../../evse/dialogs/evse-log-dialog/evse-log-dialog.component';
import {ApiHandler} from '../../../services/api-handlers/api-handler';

@Injectable({
  providedIn: 'root'
})
export class GatewayService extends DialogHandler<Gateway, ExecuteGatewayAddOrUpdate, ExecuteGatewayAddOrUpdate, {}, GatewayList, any, any> {

  constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
      super(apiService, "Gateway", toastr, dialog, dialogService, POLL_INTERVALS.gateway);
  }

    protected getEditConfig(element: Gateway, editDialogConfigArgs: any): TDialogOptions<ExecuteGatewayAddOrUpdate, EditGatewayDialogComponent> {
        return {
            headline: "Gateway Bearbeiten",
            component: EditGatewayDialogComponent,
            executeCallback: editResult => this.update(element.id, editResult),
            editElement: {
                address: element.address,
                mask: element.mask,
                name: element.name
            }
        };
    }

    protected getNewConfig(newDialogConfigArgs: any): TDialogOptions<ExecuteGatewayAddOrUpdate, EditGatewayDialogComponent> {
        return {
            headline: "Gateway hinzufügen",
            component: EditGatewayDialogComponent,
            executeCallback : editResult => this.create(editResult),
            editElement: {
                address: "",
                mask: 32,
                name: ""
            }
        }
    }

    public showLog(gateway: Gateway, webSocket: WebSocket) {
        this.dialog.open(EvseLogDialogComponent, {
            data: {
                headline: "Protokoll",
                id: gateway.id,
                name: gateway.name,
                downloadFn: () =>  this.downloadLog(gateway.id),
                websocket: webSocket
            },
            maxHeight: '90vh'
        });
    }

    public async downloadLog(gatewayId: number) {
        const customerId = ApiHandler.customerId ? ("&customerId=" + ApiHandler.customerId) : ""
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        window.open("api/gateway/" + gatewayId + "/fullLog?timezone=" + tz + customerId);
    }

    public getLiveLogs(gatewayId: number): WebSocket {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const customerId = ApiHandler.customerId ? ("&customerId=" + ApiHandler.customerId) : ""
        const secure = document.location.protocol.endsWith("s:");
        const url = (secure ? "wss" : "ws") + "://" + document.location.hostname+ ":" + document.location.port + "/api/gateway/" + gatewayId + "/logsLive?timezone=" + timezone + customerId;
        return new WebSocket(url);
    }
}
