<div *ngIf="(allConfigs | async) as allConfigs; else loading" style="width: 100%">
    <mat-card appearance="outlined" class="config" *ngFor="let config of allConfigs.list">
        <app-algo-v2-config-tile [config]="config"></app-algo-v2-config-tile>
    </mat-card>

    <div class="buttons d-flex justify-content-end flex-row gap-3">
        <button [disabled]="!canAdd" mat-raised-button color="primary" (click)="newConfig()">Neue Konfiguration</button>
    </div>
</div>



<ng-template #loading>
    Loading...
</ng-template>
