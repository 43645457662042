import {Injectable} from '@angular/core';
import {
    localStorageDelete,
    LocalStorageField,
    localStorageGet,
    localStorageSave
} from '../../helper/typed-local-storage';
import {AbstractTableComponent} from './AbstractTableComponent';
import {EvseFilterDialogComponent} from '../../../modules/evse/dialogs/evse-filter-dialog/evse-filter-dialog.component';
import {EvseFilterData} from '../../../modules/evse/components/evse-table/evse-table.component';
import {MatDialog} from '@angular/material/dialog';
import {
    TableReorderDialogComponent
} from '../../../modules/evse/dialogs/table-reorder-dialog/table-reorder-dialog.component';


export enum ReorderingDialogAction {
    NONE = 0,
    STORE = 1,
    RESET = 2,
}


export type ReorderingDialogConfig = {
    columnNames: Map<string, string>,
    invisible: Map<string, boolean>,
    columns: string[],
    defaultColumns: string[],
    action: ReorderingDialogAction
}

export type ReorderingConfig = {
    invisible: boolean[],
    order: number[]
}

@Injectable()
export abstract class AbstractReorderTableComponent extends AbstractTableComponent {
    public abstract readonly defaultColumns: string[];
    public abstract readonly columnNames: Map<string, string>;
    public displayedColumns: string[] = []
    abstract readonly tableIdentifier: keyof LocalStorageField;
    public config: ReorderingConfig|undefined = undefined;

    loadReorderConfig(): void {
        this.config = JSON.parse(localStorageGet(this.tableIdentifier, "{\"invisible\":[], \"order\":[]}"));
        if (this.config === undefined) {
            this.config = {invisible: [], order: []};
            this.displayedColumns = this.defaultColumns;
        } else {
            this.applyConfig()
        }
    }

    protected constructor(
        protected readonly dialog: MatDialog,
    ) {
        super();
    }

    private applyConfig(): void {
        if (this.config === undefined || this.config.order.length === 0) {
            this.displayedColumns = this.defaultColumns;
        } else {
            const columns: string[] = [];
            for (let i = 0; i < this.defaultColumns.length; i++) {
                const index = this.config.order[i];
                if (!this.config.invisible[index]) {
                    columns.push(this.defaultColumns[index]);
                }
            }
            this.displayedColumns = columns;
        }
    }

    public reorder(): void {
        let columns: string[];
        const invMap = new Map<string, boolean>();
        if (this.config === undefined || this.config?.order.length === 0) {
            columns = this.defaultColumns.map(col => col);
        } else {
            columns = this.config.order.map(i=>this.defaultColumns[i]);
            for (let i = 0; i < this.config?.invisible.length; i++) {
                invMap.set(this.defaultColumns[i], this.config.invisible[i]);
            }
        }

        this.dialog.open(
            TableReorderDialogComponent, {
                data: {columnNames: this.columnNames, invisible: invMap, columns, defaultColumns: this.defaultColumns, action: ReorderingDialogAction.NONE},
            })
        .afterClosed().subscribe((result: ReorderingDialogConfig)=> {
            if (result !== undefined) {
                switch (result.action) {
                    case ReorderingDialogAction.STORE:
                        if (this.config === undefined) {
                            this.config = {invisible: [], order: []};
                        }
                        this.config.order = result.columns.map(rcol => this.defaultColumns.findIndex(dcol=> rcol === dcol));
                        this.config.invisible = this.defaultColumns.map(dcol => result.invisible.get(dcol)??false);
                        this.applyConfig();
                        localStorageSave(this.tableIdentifier, JSON.stringify(this.config));
                        break
                    case ReorderingDialogAction.RESET:
                        this.config = undefined;
                        this.applyConfig()
                        localStorageDelete(this.tableIdentifier);
                        break
                    case ReorderingDialogAction.NONE:
                        break
                }

            }
        });
    }
}
