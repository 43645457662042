<div class="d-flex flex-row justify-content-between">
    <h1>Ladegruppen</h1>
    <app-help-box-icon #helpIcon></app-help-box-icon>
</div>
<app-help-box [helpIcon]="helpIcon" [key]="'CHARGING_GROUP_HELP_TOP'">
    Ladegruppen erlauben es, für verschiedene Nutzergruppen jeweils eigene Preise festzulegen. Dazu können Sie
    mehrere Ladegruppen erstellen, und jeweils festlegen, welche RFID-Karten und welche Ladepunkte zu dieser
    Gruppe gehören. In der Gruppe wird auch der Preis für die Nutzung des Ladepunktes festgelegt. Wenn dann an
    einem der ausgewählten Ladepunkte eine der RFID-Karten genutzt wird, gelten die Preise der jeweiligen Gruppe.
    <br>
    <br>
    Es ist möglich, mehrere Ladegruppen zu erstellen. Hierbei kann es gewollt oder ungewollt vorkommen, dass einige
    RFID/Ladepunkt Kombinationen in mehreren Gruppen vorkommen. Wenn das passiert, wird während des Ladevorgangs
    für alle Gruppen der Preis berechnet und am Ende der für den Nutzer niedrigste Preis angewendet.
    <br>
    <br>
    Ladegruppen werden ebenfalls für die Freigabe benutzt. Das bedeutet, wenn eine RFID Karte an einem Ladepunkt
    verwendet wird und für diese Kombination keine Ladegruppe hinterlegt ist, wird der Ladevorgang abgelehnt. Es ist
    also nicht möglich zu Laden.
    <br>
    <br>
    Wenn an einer OCPP-Ladestation ein nachgelagertes Backend verwendet wird (Uplink) dann wird die Freigabe durch
    dieses Backend gesteuert. Die Preise werden weiterhin aus den Ladegruppen berechnet. Es kann jedoch den Fall
    geben, dass keine Ladegruppe hinterlegt ist. In diesem Fall wird der Ladevorgang mit dem Preis aus dem Standort
    abgerechnet. Ein Preis pro Ladevorgang oder Zeit fällt dann nicht an.
</app-help-box>

@if (data | async; as data) {
    <app-charging-group-table [chargingGroups]="data.chargingGroups" [evses]="data.evses" [rfids]="data.rfids"></app-charging-group-table>
} @else {
    Laden...
}

<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button mat-raised-button color="primary" (click)="create()">Ladegruppe anlegen</button>
</div>
