import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from "../edit-dialog/edit-dialog.component";
import {CustomerList, ExecuteCustomerAdd} from "@io-elon-common/frontend-api";
import {BehaviorSubject} from "rxjs";
import {CustomerService} from "../../../../services/api-handlers/customer.service";
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-new-customer',
    templateUrl: './new-customer.component.html',
    styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit, IEditForm<ExecuteCustomerAdd> {
    public formGroup!: FormGroup<{
        icon: FormControl<string | null | any>
    }>;

    @Input()
    data!: ExecuteCustomerAdd;
    customers: BehaviorSubject<CustomerList | undefined>


    constructor(
        private readonly customerService: CustomerService
    ) {
        this.customers = customerService.getAll();
    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            icon: new FormControl(this.data.icon),
        });
        this.formGroup.valueChanges.subscribe(change => {
            const selectedFiles = change.icon?.files;
            if(selectedFiles && selectedFiles[0]){
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    this.data.icon = reader.result as string;
                }, false);
                reader.readAsDataURL(selectedFiles[0]);
            }
            this.data.icon = change.icon;
        })
    }

    validate(): string[] {
        return [];
    }
}
