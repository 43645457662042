import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EvseService} from '../../service/evse.service';

export interface EvseDialogData {
    id: number,
    name: string,
    downloadFn: () => Promise<void>,
    websocket: WebSocket
}
@Component({
  selector: 'app-evse-log-dialog',
  templateUrl: './evse-log-dialog.component.html',
  styleUrls: ['./evse-log-dialog.component.scss']
})

export class EvseLogDialogComponent implements OnInit, OnDestroy {
    public connected: boolean;
    public socket?: WebSocket
    public log = "";
    public autoScroll = true;


    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: EvseDialogData,
                private dialogRef: MatDialogRef<EvseDialogData>,
                private readonly evseService: EvseService) {
        this.connected = false;
    }

    ngOnInit(): void {
        this.connect();
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public async downloadLog() {
        if (this.dialogData.id !== undefined) {
            await this.dialogData.downloadFn();
        }
    }

    ngOnDestroy(): void {
        if(this.connected) {
            this.closeConnection();
        }
    }
    private onMessage(msg: string) {
        this.log += msg;
        if(this.autoScroll) {
            this.scrollDown();
        }
    }
    private closeConnection() {
        this.socket?.close();
        this.connected = false;
    }

    private connect() {
        this.socket = this.dialogData.websocket;
        this.socket.onmessage = msg => this.onMessage(msg.data + "\n");
        this.socket.onerror = err => this.onMessage("Fehler: " + err);
        this.socket.onclose = evt => this.onMessage("CLOSE --- CLOSE --- CLOSE");
        this.connected = true;
    }

    private scrollDown() {
        if (this.autoScroll) {
            setTimeout(() => {
                const element = document.getElementById("scroll-pre");
                if (element) {
                    element.scrollTop = element.scrollHeight - element.clientHeight;
                }
            });
        }
    }
}
