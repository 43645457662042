import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EvseHealthService} from '../../service/evseHealth.service';
import {EvseHealthDataUtils, HealthData, StatusData} from '../../../../shared/helper/evse-health-data-utils';
import {RowData} from '../../tables/evse-health-history-table/evse-health-history-table.component';
import {Subscription} from 'rxjs';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {AbstractFaultHistoryOverview, DateRange} from '../abstract-evse-fault-history';
import {Evse} from '@io-elon-common/frontend-api';

@Component({
  selector: 'app-evse-health-history-view',
  templateUrl: './evse-health-history-view.component.html',
  styleUrl: './evse-health-history-view.component.scss'
})

export class EvseHealthHistoryViewComponent extends AbstractFaultHistoryOverview implements OnInit, OnDestroy{
    @Input() evses!: Evse[];

    public dataRows!: RowData<Evse>[];
    public footerRow!: RowData<Evse>;
    public displayColumns: string [] = [
        'icon',
        'evseName',
        'numberOfFaults',
        'durationOfFault',
        'percentageOfAvailability',
        "pieChart"
    ];

    private fleetIdSubscription: Subscription | undefined;

    constructor (
        private readonly evseHealthService: EvseHealthService,
        private readonly evseHealthDataUtils: EvseHealthDataUtils,
        private readonly fleetService: FleetService
    ) {
        super();
    }
    async ngOnInit(): Promise<void> {
        await this.generateData(this.range);
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(async id => {
            if (id !== undefined && !isNaN(id)) {
                await this.generateData(this.range)
            }
        });
    }

    ngOnDestroy(): void {
        this.fleetIdSubscription?.unsubscribe()
    }

   public async generateData(dateRange: DateRange): Promise<void> {
        if(!dateRange?.start) {
            this.footerRow = this.clearData(this.footerRow);
            this.dataRows = [];
            return;
        }
        const result = await this.evseHealthService.getEvsesHealthHistoryData(dateRange.start.valueOf(), dateRange.end.valueOf());
        const evseIds = this.evses.map(evse => evse.id);
        const evseHealthHistory = result.list.filter(r => r.evseId && evseIds.includes(r.evseId));
        const resultData: HealthData<Evse>[] = [];
        this.dataRows = [];

        this.evses.forEach(evse => {
            const filteredData = evseHealthHistory.filter(entry => entry.evseId === evse.id);
            const emptyStatus: StatusData[] = this.evseHealthDataUtils.getEmptyStatus();
            let healthData = {obj: evse, data: emptyStatus, totalDuration: 0};
            filteredData.forEach(entry => {
                healthData = this.evseHealthDataUtils.updateHealthDataEntry<Evse>(entry, healthData, dateRange.end);
            });
            resultData.push(healthData);
        });

        const data = this.evseHealthDataUtils.getDataFooterRows<Evse>(resultData);
        this.dataRows = data.dataRows;
        this.footerRow = data.footerRow;
    }
}
