import {BehaviorSubject} from 'rxjs';
import {deepEqual} from '../../shared/helper/util-functions';
import {localStorageGet} from '../../shared/helper/typed-local-storage';

export type ApiErrors = {
    type: "NOT_FOUND" | "OTHER"
    exc: any
}

export class BehaviorSubjectWithErrorChannel<T, E> extends BehaviorSubject<T> {
    _errorChannel: BehaviorSubject<E | undefined> = new BehaviorSubject<E | undefined>(undefined);

    public get errorChannel(): BehaviorSubject<E | undefined> {
        return this._errorChannel;
    }
}

interface CacheEntry<T, Key> {
    id: Key;
    data: BehaviorSubjectWithErrorChannel<T | undefined, ApiErrors>;
    time: number;
    updateCallback: () => Promise<T>;
    updateRunning: boolean;
}

export class CacheUpdater<T, FindParam> {
    private cache: CacheEntry<T, FindParam>[] = [];
    private interval: any;

    public constructor(
        private readonly searchCallback: (obj: CacheEntry<T, FindParam>, arg: FindParam) => boolean,
        private readonly pollInterval: number
    ) {
        this.interval = setInterval(async () => {
            if (CacheManager.POLL_ENABLED) {
                this.update();
            }
        }, this.pollInterval);
    }

    public stop() {
        clearInterval(this.interval);
    }

    public addEntry(entry: CacheEntry<T, FindParam>): void {
        this.cache.push(entry);
    }

    public getOrCreateGet(id: FindParam, updateCallback: () => Promise<T>): CacheEntry<T, FindParam> {
        const ce = this.getEntry(id);
        if (ce) {
            return ce;
        }
        const entry = {
            id,
            data: new BehaviorSubjectWithErrorChannel<T | undefined, ApiErrors>(undefined),
            updateCallback,
            time: Date.now(),
            updateRunning: false
        };
        this.addEntry(entry);
        // noinspection JSIgnoredPromiseFromCall
        this.updateSingle(entry);
        return entry;
    }

    public getEntry(findParam: FindParam): CacheEntry<T, FindParam> | undefined {
        return this.cache.find(e => this.searchCallback(e, findParam));
    }

    public async updateSingle(ce: CacheEntry<T, FindParam>): Promise<void> {
        try {
            if (ce.updateRunning) {
                return;
            }
            ce.updateRunning = true;
            const newVal = await ce.updateCallback();
            const oldVal = ce.data.getValue();
            if (!deepEqual(newVal, oldVal)) {
                ce.data.next(newVal);
            }
            ce.updateRunning = false;
        } catch (err: any) {
            ce.updateRunning = false;
            ce.data.next(undefined);
            ce.data.errorChannel.next({type: err.status === 404 ? "NOT_FOUND" : "OTHER", exc: err})
            console.log(err);
        }
    }

    public async update(): Promise<void> {
        const toRemove: CacheEntry<T, FindParam>[] = [];
        for (const ce of this.cache) {
            if (ce.data.observers.length === 0) {
                toRemove.push(ce);
                continue;
            }
            await this.updateSingle(ce);
        }
        if (toRemove.length > 0) {
            this.cache = this.cache.filter(ce => toRemove.indexOf(ce) === -1);
        }
    }
}


export class CacheManager {
    public static POLL_ENABLED = localStorageGet('POLL_ENABLED', "true") === "true";
    private managedCaches: CacheUpdater<any, any>[] = [];

    public constructor(private readonly pollInterval: number) {
    }

    public clearCache() {
        for(const c of this.managedCaches) {
            c.stop();
        }
        this.managedCaches = [];
    }

    public invalidate() {
        for(const c of this.managedCaches) {
            c.update();
        }
    }

    protected createManagedCache<Type, FindParam>(
        searchCallback: (obj: CacheEntry<Type, FindParam>, arg: FindParam) => boolean,
        pollInterval: number = this.pollInterval
    ): CacheUpdater<Type, FindParam> {
        const cu = new CacheUpdater<Type, FindParam>(searchCallback, pollInterval);
        this.managedCaches.push(cu);
        return cu;
    }
}
