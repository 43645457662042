import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ExecuteFreeToMoveDataSourceAddOrUpdate, VehicleList} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {FreeToMoveService} from '../../service/free-to-move.service';

const HOUR = 1000 * 3600;
const DAY = HOUR * 24;
const MONTH = DAY * 30;

@Component({
    selector: 'app-edit-free-to-move-dialog',
    templateUrl: './edit-free-to-move-dialog.component.html',
    styleUrls: ['./edit-free-to-move-dialog.component.scss']
})
export class EditFreeToMoveDialogComponent implements IEditForm<ExecuteFreeToMoveDataSourceAddOrUpdate>, OnInit {

    @Input()
    public data!: ExecuteFreeToMoveDataSourceAddOrUpdate;
    public vehicles: BehaviorSubject<VehicleList | undefined>;
    public vins: Array<{vin: string, endDate: number | null}> = [];
    public loadingVins = false;
    public now = Date.now();

    constructor(
        private readonly freeToMoveService: FreeToMoveService,
        private readonly vehicleService: VehicleService
    ) {
        this.vehicles = this.vehicleService.getAll();
    }

    ngOnInit(): void {
        this.vins.push({
            vin: this.data.vin,
            endDate: null
        });
    }


    validate(): string[] {
        const ret: string[] = [];
        if(!this.data.vin) {
            ret.push("Es muss eine VIN ausgewählt werden.")
        }
        if(!this.data.mail) {
            ret.push("Es muss eine E-Mail eingegeben werden.")
        }
        return ret;
    }

    async loadVins() {
        try{
            this.loadingVins = true;
            this.vins = await this.freeToMoveService.getVinList(this.data.mail, this.data.password);
        } finally {
            this.loadingVins = false;
        }
    }

    public prettyString(date: number | null): string {
        if(date == null) {
            return "";
        }

        const now = Date.now();
        if(now < date) {
            const remaining = date - now;
            if(remaining < DAY) {
                return "Weniger als 1 Tage verbleibend";
            }
            if(remaining < MONTH) {
                return "ca. " + (remaining / DAY).toFixed(0) + " Tage verbleibend";
            }
            return "ca. " + (remaining / MONTH).toFixed(0) + " Monate verbleibend";
        }
        return "Abgelaufen"
    }
}
