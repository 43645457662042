import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ChargingGroup, Evse, Rfid} from "@io-elon-common/frontend-api";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatSortModule, Sort} from "@angular/material/sort";
import {AbstractTableComponent} from "../../../../shared/components/tables/AbstractTableComponent";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {ChargingGroupService} from "../../service/charging-group.service";
import {DurationPipe} from "../../../../shared/helper/duration.pipe";
import {CustomTooltipDirective} from "../../../../shared/helper/custom-tooltip.directive";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-charging-group-table',
  standalone: true,
    imports: [
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        DurationPipe,
        CustomTooltipDirective,
    ],
  templateUrl: './charging-group-table.component.html',
  styleUrl: './charging-group-table.component.scss'
})
export class ChargingGroupTableComponent extends AbstractTableComponent implements OnChanges{
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input()
    public evses!: Evse[]
    @Input()
    public rfids!: Rfid[]
    @Input()
    public chargingGroups!: ChargingGroup[];

    public displayedColumns: string[] = ['id', 'name', 'evses', 'rfids', "guests",'pricePerEnergy', 'pricePerSession', 'pricePerHour', 'initialFreeMinutes', 'actions'];
    public dataSource = new MatTableDataSource<ChargingGroup>([]);
    public sort: Sort = {active: 'id', direction: 'asc'};

    public constructor(
        private readonly chargingGroupService: ChargingGroupService
    ) {
        super();
    }

    public trackBy(idx: number, item: ChargingGroup) {
        return item.id;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.chargingGroups;
        this.sortData();
    }

    public setSort(sort: Sort) {
        this.sort = sort;
        this.sortData();
    }

    public sortData() {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
            const isAsc = this.sort.direction === 'asc';
            switch (this.sort.active) {
                case 'id':
                    return compare(a.id, b.id, isAsc);
                case 'name':
                    return compare(a.name?.toLowerCase(), b.name?.toLowerCase(), isAsc);
                case 'pricePerEnergy':
                    return compare(a.pricePerEnergy, b.pricePerEnergy, isAsc);
                case 'pricePerSession':
                    return compare(a.pricePerSession, b.pricePerSession, isAsc);
                case 'pricePerHour':
                    return compare(a.pricePerHour, b.pricePerHour, isAsc);
                case 'initialFreeMinutes':
                    return compare(a.initialFreeMinutes, b.initialFreeMinutes, isAsc);
                default:
                    return 0;
            }
        });

        function compare<T>(a: T, b: T, isAsc: boolean): number {
            return (a === null || a === undefined ? -1 : b === null || b === undefined ? 1 : a > b ? 1 : a < b ? -1 : 0) * (isAsc ? 1 : -1);
        }
    }

    public async edit(chargingGroup: ChargingGroup) {
        await this.chargingGroupService.showEditDialog(chargingGroup, {
            evses: this.evses,
            rfids: this.rfids,
        });
    }
    public async delete(chargingGroup: ChargingGroup) {
        await this.chargingGroupService.showDeleteDialog(chargingGroup, {});
    }

    public formatPrice(price: number, unit: string) {
        if (unit.includes('€')) {
            return price.toFixed(Math.max(2, (price.toString().split('.')[1]?.length || 0))) + " " + unit;
        } else {
            return price + " " + unit;
        }
    }

    public formatEvses(evses: number[]) {
        if (evses.length === this.evses.length) {
            return "Alle Ladepunkte";
        } else if (evses.length <= 3) {
            return evses.map(id => this.evses.find(evse => evse.id === id)?.name).filter(name => name).join(', ');
        } else {
            const firstEvseName = this.evses.find(evse => evse.id === evses[0])?.name;
            const othersCount = evses.length - 1;
            return `${firstEvseName} +${othersCount} weitere`;
        }
    }

    public formatRfids(rfids: number[]) {
        if (rfids.length === this.rfids.length) {
            return "Alle RFID-Karten";
        } else if (rfids.length <= 3) {
            return rfids.map(id => this.rfids.find(rfid => rfid.id === id)?.name).filter(name => name).join(', ');
        } else {
            const firstRfidName = this.rfids.find(rfid => rfid.id === rfids[0])?.name;
            const othersCount = rfids.length - 1;
            return `${firstRfidName} +${othersCount} weitere`;
        }
    }

    rfidsToNames(rfids: number[]): Rfid[] {
        const rfidList = rfids.map(id => this.rfids.find(rfid => rfid.id === id)).filter(name => name) as Rfid[] || [];
        rfidList.sort((a, b) => a.name.localeCompare(b.name));
        return rfidList;
    }
}
