<mat-dialog-content>
    <div class="dialog-head">
        <h2 class="mat-h2">Protokoll</h2>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    </div>
    <div class="d-flex row">
        <div class="d-flex justify-content-between gap-3">
            <div>
                <h4>{{dialogData.name}}</h4>
            </div>
            <mat-checkbox [(ngModel)]="autoScroll">Automatisch scrollen</mat-checkbox>
        </div>
        <pre id="scroll-pre" class="w-100">{{ log }}</pre>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex flex-row justify-content-end">
    <button mat-raised-button color="primary" type="submit" (click)="downloadLog()" style="margin-right: 15px">
        Protokoll Herunterladen
    </button>
</mat-dialog-actions>
