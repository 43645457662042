import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MeterService} from '../../service/meter.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {Router} from '@angular/router';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import {DatePipe} from '@angular/common';
import {AgePipe} from '../../../../shared/helper/age.pipe';
import {Meter, NotificationEntry, SystemInfoMeterType} from '@io-elon-common/frontend-api';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {NotificationService} from "../../../../services/api-handlers/notification.service";
import {Subscription} from "rxjs";
import {AbstractReorderTableComponent} from '../../../../shared/components/tables/AbstractReorderTableComponent';
import {LocalStorageField} from '../../../../shared/helper/typed-local-storage';
import {MatDialog} from '@angular/material/dialog';

export const OUTDATED_TIME = 1000 * 60;
@Component({
  selector: 'app-meter-table',
  templateUrl: './meter-table.component.html',
  styleUrls: ['./meter-table.component.scss']
})
export class MeterTableComponent extends AbstractReorderTableComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input()
    public meters!: Meter[];
    public isDev: boolean;

    public canAdd = false;
    public defaultColumns: string[] = ['icon', 'id', 'name', 'type', 'hostname', 'port','modbusId', 'interval', 'actions'];

    tableIdentifier: keyof LocalStorageField = "METER_TABLE_REORDER_CONFIG";
    public columnNames: Map<string, string> = new Map([
        ['icon', 'Icon'],
        ['id', 'ID'],
        ['name', 'Name'],
        ['type', 'Typ'],
        ['hostname', 'Hostname'],
        ['port', 'Port'],
        ['modbusId', 'Modbus Id'],
        ['interval', 'Abfrage-Intervall'],
    ]);
    public dataSource = new MatTableDataSource<Meter>([]);
    public activeNotifications: NotificationEntry[] = [];
    public mutedNotifications: NotificationEntry[] = [];
    private meterTypes: Array<SystemInfoMeterType> = [];
    private notificationSubscription?: Subscription;

    public constructor(
        private readonly meterService: MeterService,
        private readonly authService: AuthService,
        private router: Router,
        private readonly agePipe: AgePipe,
        private readonly datePipe: DatePipe,
        private readonly systemService: SystemService,
        private readonly notificationService: NotificationService,
        protected readonly dialog: MatDialog
    ) {
        super(dialog);
        this.isDev = this.authService.isDeveloper();
    }

    public async ngOnInit(): Promise<void> {
        this.loadReorderConfig();
        this.meterTypes = (await this.systemService.getSystemInfo()).supportedMeters;
        this.canAdd = this.authService.hasGlobalPermission("ADD_METER");
        this.dataSource = new MatTableDataSource(this.meters);
        this.dataSource.paginator = this.paginator;
        this.notificationSubscription = this.notificationService.getActiveNotifications().subscribe(x => {
            if (x == undefined)
                return;
            this.activeNotifications = x.activeNotifications.sort((n1, n2) => n1.id - n2.id);
            this.mutedNotifications = x.mutedNotifications.sort((n1, n2) => n1.id - n2.id);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.meters;
    }

    ngOnDestroy(): void {
        this.notificationSubscription?.unsubscribe();
    }

    public async edit(meter: Meter): Promise<void> {
        await this.meterService.showEditDialog(meter);
    }
    public async clone(meter: Meter): Promise<void> {
        await this.meterService.showCloneDialog(meter);
    }

    public async delete(meter: Meter): Promise<void> {
        await this.meterService.showDeleteDialog(meter, {});
    }

    public navigate(meter: Meter){
        this.router.navigate(['/meters/meter', meter.id]); // we can send product object as route param
    }

    public isOutdated(meter: Meter): boolean {
        return meter.lastMsg < Date.now() - OUTDATED_TIME;
    }

    public getAge(meter: Meter): string {
        return this.agePipe.transform(meter.lastMsg);
    }

    public getNotificationTooltipText(meter: Meter): string {
        let lastContact = this.datePipe.transform(new Date(meter.lastMsg), 'dd.MM.yyyy, HH:mm:ss');
        if (meter.lastMsg === 0 || lastContact === null) {
            lastContact = "Unbekannt";
        }
        return "Zuletzt: " + lastContact;
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }

    public getMeterName(type: string): string {
        return this.meterTypes.find(t => t.key === type)?.name ?? "Unbekannt";
    }

    getType(element: Meter): SystemInfoMeterType.TypeEnum {
        return this.systemService.getSystemInfoSync()?.supportedMeters.find(t => t.key === element.type)!.type!;
    }

    public getHealthIcon(meter: Meter): string {
        switch (meter.healthState) {
            case "WORKING": return "circle-working";
            case "ATTENTION": return "circle-attention";
            case "BROKEN": return "circle-broken";
            case "MAINTENANCE": return "circle-maintenance";
            case "ON_REPAIR": return "circle-repair";
            default: return "symbol-circle-unknown";
        }
    }
}
