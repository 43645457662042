import { Pipe, PipeTransform } from '@angular/core';
import {GatewayDeviceType} from '@io-elon-common/frontend-api';

@Pipe({
  name: 'formatGatewayDeviceType',
  standalone: true
})
export class FormatGatewayDeviceTypePipe implements PipeTransform {

  transform(value: GatewayDeviceType): string {
   switch (value) {
       case 'EVSE': return 'Ladepunkt';
       case 'Vehicle': return 'Fahrzeug';
       case 'Meter': return 'Zähler';
       case 'PowerSupply': return 'Anschluss'
   }
  }

}
