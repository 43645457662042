import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {ChargingGroup, ChargingGroupList, Evse, EvseList, Rfid, RfidList} from "@io-elon-common/frontend-api";
import {EvseService} from "../../../evse/service/evse.service";
import {RfidService} from "../../../rfid/service/rfid.service";
import {ChargingGroupService} from "../../service/charging-group.service";
import {
    HelpBoxComponentDialog
} from "../../../../shared/components/help-box/help-box-dialog/help-box-component-dialog.component";
import {HelpBoxIconComponent} from "../../../../shared/components/help-box/help-box-icon/help-box-icon.component";
import {ChargingGroupTableComponent} from "../../components/charging-group-table/charging-group-table.component";
import {map} from "rxjs/operators";
import {AsyncPipe} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
    selector: 'app-charging-group-view',
    standalone: true,
    imports: [
        HelpBoxComponentDialog,
        HelpBoxIconComponent,
        ChargingGroupTableComponent,
        AsyncPipe,
        MatButton
    ],
    templateUrl: './charging-group-view.component.html',
    styleUrl: './charging-group-view.component.scss'
})
export class ChargingGroupViewComponent implements OnInit {
    public evses!: BehaviorSubject<EvseList | undefined>;
    public rfids!: BehaviorSubject<RfidList | undefined>;
    public chargingGroups!: BehaviorSubject<ChargingGroupList | undefined>;

    public data!: Observable<{ evses: Evse[], rfids: Rfid[], chargingGroups: ChargingGroup[] }>

    public constructor(
        private readonly evseService: EvseService,
        private readonly rfidService: RfidService,
        private readonly chargingGroupService: ChargingGroupService
    ) {
    }

    ngOnInit(): void {
        this.evses = this.evseService.getAll();
        this.rfids = this.rfidService.getAll();
        this.chargingGroups = this.chargingGroupService.getAll();

        this.data = combineLatest([this.evses, this.rfids, this.chargingGroups])
            .pipe(map(([evseList, rfidList, chargingGroupList]) => {
                return {
                    evses: evseList?.list ?? [],
                    rfids: rfidList?.list ?? [],
                    chargingGroups: chargingGroupList?.list ?? []
                }
            }));
    }

    public async create() {
        await this.chargingGroupService.showNewDialog({
            evses: this.evses.value?.list ?? [],
            rfids: this.rfids.value?.list ?? [],
        }, {width: '80vw'})
    }
}
