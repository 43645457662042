import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {MeterService} from '../../service/meter.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {Subscription} from 'rxjs';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {Meter, MeterList} from '@io-elon-common/frontend-api';

@Component({
    selector: 'app-meter-list-view',
    templateUrl: './meter-list-view.component.html',
    styleUrls: ['./meter-list-view.component.scss']
})
export class MeterListViewComponent implements OnInit, OnDestroy {

    public allMeters!: BehaviorSubject<MeterList | undefined>
    public currentBasisMeters: Meter[] = [];
    public canAdd = false;
    public noBasisMeter: Meter[] = [];
    private selectedFleetSubscription!: Subscription;
    private meterSubscription!: Subscription;
    private basisId!: number;
    public constructor(
        private meterService: MeterService,
        private authService: AuthService,
        private readonly fleetService: FleetService
    ) {
    }

    ngOnDestroy(): void {
        this.meterSubscription.unsubscribe();
        this.selectedFleetSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.allMeters = this.meterService.getAll();
        this.meterSubscription = this.allMeters.subscribe(() => this.updateMeterList());
        this.selectedFleetSubscription = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetService.getPromise(id).then(fleet => {
                    this.basisId = fleet.base.id;
                    this.canAdd = this.authService.hasGlobalPermission("ADD_METER") || this.authService.hasFleetPermission("ADD_ENERGY_SYSTEMS_OF_BASIS", fleet.id);
                    this.updateMeterList();
                });
            }
        });
    }

    public async newMeter(): Promise<void> {
        await this.meterService.showNewDialog(this.basisId);
    }

    public async newFormulaMeter() {
        await this.meterService.showNewFormulaDialog(this.basisId);
    }

    public updateMeterList(): void {
        this.currentBasisMeters = [];
        this.noBasisMeter = [];
        this.allMeters.getValue()?.list.forEach(meter => {
            if (meter.basis !== undefined && meter.basis.id === this.basisId) {
                this.currentBasisMeters.push(meter);
            }
            if (meter.basis === undefined) {
                this.noBasisMeter.push(meter);
            }
        });
    }
}
