import {Component, Input} from '@angular/core';
import {DynamicLoadType, Load, PowerSupply} from '@io-elon-common/frontend-api';
import { LoadService } from 'src/app/modules/loads/service/load.service';
import {LoadUtilsService} from "../../../../../shared/helper/load-utils.service";
import {PhaseValues, SinglePhaseElectricity, ThreePhaseElectricity} from "../../../../../shared/helper/electricity";

@Component({
    selector: 'app-load-tree-view-node',
    templateUrl: './load-tree-view-node.component.html',
    styleUrls: ['./load-tree-view-node.component.scss']
})
export class LoadTreeViewNodeComponent {

    @Input()
    public load!: Load;

    @Input()
    powerSupplies!: PowerSupply[];

    @Input()
    public ampsMode: boolean = false;

    public constructor(
        private readonly loadService: LoadService,
        private readonly loadUtils: LoadUtilsService,
    ) {
    }

    public isMeterBased(): boolean {
        return this.load.type === DynamicLoadType.MeterBasedDynamicLoad && this.load.meterId!==undefined;
    }

    public getPwr(): PhaseValues {
        return this.loadUtils.getCurrentPwr(this.load);
    }

    public getElectricity(): ThreePhaseElectricity {
        const pwr = this.getPwr();
        return new ThreePhaseElectricity(
            SinglePhaseElectricity.fromVoltsAndWatts(235, pwr.l1),
            SinglePhaseElectricity.fromVoltsAndWatts(235, pwr.l2),
            SinglePhaseElectricity.fromVoltsAndWatts(235, pwr.l3)
        )
    }

    public getMaxPwr(): number {
        return this.loadUtils.getMaxPwr(this.load);
    }

    public getMaxAmpsPerPhase(): PhaseValues {
        return PhaseValues.threePhased(this.loadUtils.getMaxAmpsPerPhase(this.load).max());
    }

    public async handleEditLoad(load: Load): Promise<void> {
        await this.loadService.showEditDialog(load, this.powerSupplies);
    }

    public async handleDeleteLoad(load: Load): Promise<void> {
        await this.loadService.showDeleteDialog(load, {});
    }
}
